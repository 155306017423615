import GlobalStyles from './global-styles'

const theme = {
  'border-radius': '5px',
  colors: {
    primary: '#855AAF',
    secondary: '#78A8C1',
    'brand-light': '#F9F6FB',
    base: '#FFFFFF',
    danger: {
      base: '#FF5E5E',
      light: '#FFE9E9',
    },
    success: {
      base: '#47C479',
      light: '#E2FFEC',
    },
    warning: {
      base: '#FFCC00',
      light: '#FFFBC5',
    },
    gray: {
      10: '#F9F9F9'
    },
    disabled: '#d4d4d4',
    gradient:
      'linear-gradient(0deg, rgba(134,82,173,1) 0%, rgba(121,156,190,1) 100%)',
    'fiat-card-gradient':
      'linear-gradient(210deg,rgba(120, 168, 193, 1) 30%,rgba(148, 0, 154, 1) 100%)',
    'crypto-card-gradient':
      'linear-gradient(34deg, rgba(120,168,193,1) 30%, rgba(148,0,154,1) 100%);',
    'payment-card-gradient':
      'linear-gradient(149deg, rgba(255,150,255,1) 0%, rgba(181,32,151,1) 100%);',
    'primary-text': '#222222',
    'secondary-text': '#646464',
    'base-200': '#f9f9f9',
    shadow: '#00000029',
  },
  'font-family': `
  'Caros Text',-apple-system,BlinkMacSystemFont,"Helvetica Neue",
  "Segoe UI","Oxygen","Ubuntu","Cantarell","Open Sans",sans-serif
  `,
}

export { theme, GlobalStyles }
