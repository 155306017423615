import { default as React, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container from '../styles';
import { TextInput, Button } from '../../../armoury';
import { isEmail, formValidator } from '../../../helpers';
import { handleRegistration } from '../../../store/actions';

const Register = () => {
  const router = useHistory();
  const dispatch = useDispatch();

  const [step, setStep] = useState(2);
  const [isProcessing, setIsProcessing] = useState(false);

  const mounted = React.useRef(null);

  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);

  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);

  const changeStep = stepper => {
    const base = '/sign-up/?step=';

    if (stepper === -1) {
      setStep(c => (step === 1 ? c : --c));
    } else {
      setStep(c => (step === 4 ? c : ++c));
    }

    router.push(`${base}${step}/`);
  };

  const handleEmail = ({ target: { value } }) => {
    setEmail(value);
    setEmailError(!isEmail(value));
  };

  const handlePassword = ({ target: { value } }) => {
    setPassword(value);
    setPasswordError(password.length < 6);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (
      formValidator(
        document.forms['signUp--form'].getElementsByTagName('input'),
      )
    ) {
      setIsProcessing(true);
      try {
        const response = await dispatch(
          handleRegistration({ email, password }),
        );

        if (response?.success) {
          setIsProcessing(false);
          router.push('/complete-setup/');
        }
      } finally {
        if (mounted) {
          setIsProcessing(false);
        }
      }
    }
  };

  return (
    <Container>
      <main className={'fx-auth-container'}>
        <div className={'fx-auth-container__image-container'}>
          <div
            role={'button'}
            onClick={() => changeStep(-1)}
            className={'chevron-container'}
          >
            <FontAwesomeIcon icon={'chevron-left'} />
          </div>

          <img
            src="https://desert-storm.s3-us-west-2.amazonaws.com/assets/flux-logo.png"
            alt="flux logo"
            title={'Flux'}
          />
        </div>
        <h4>Sign Up</h4>
        <span className={'fx-auth-header--details'}>
          Please enter your details to get started.
        </span>
        <form name={'signUp--form'} onSubmit={handleSubmit} noValidate>
          <TextInput
            placeholder={'john.doe@iflux.app'}
            error={emailError}
            label={'Email Address'}
            required={true}
            type={'email'}
            name="Email"
            value={email}
            onChange={handleEmail}
          />
          <TextInput
            placeholder={'***********'}
            error={passwordError}
            label={'Password'}
            required={true}
            name="Password"
            type={'password'}
            onChange={handlePassword}
            value={password}
          />
          <Button
            $loading={isProcessing}
            disabled={emailError || passwordError || isProcessing}
            primary
            wide
          >
            Submit
          </Button>
          {/*<Link to={'/forgot-password/'}>Forgot Password?</Link>*/}
        </form>
        <div className="fx-footer--text">
          <p>
            Already have an account? <Link to={'/sign-in/'}>Log In here!</Link>
          </p>
        </div>
      </main>
    </Container>
  );
};

export default Register;
