import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import Container from './styles';
import { Button, Modal, Spinner, TextInput } from '../../../armoury';

import ProductItem from '../../../components/Dashboard/Store/ProductItem';
import { AddProduct, EditProduct } from '../../../components/Dashboard/Store';
import { delivery, event, store } from '../../../api';
import { formatAmount } from '../../../helpers';
import { handleDeliveryInfoUpdate } from '../../../store/actions';
import { useHistory } from 'react-router-dom';

const FluxEvents = () => {
  const user = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const history = useHistory();

  const form = React.useRef(null);

  const { business } = user;

  const isBusinessAdmin = business?.admin;

  const [productModal, setAddProduct] = React.useState(false);
  const [editProductModal, setEditModal] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState('products');
  const [isProcessing, setProcessing] = React.useState(false);

  const [catalogs, setCatalogs] = React.useState([]);

  const [details, setDetails] = React.useState('');

  const [deliveryInfo, setDeliveryInfo] = React.useState([]);

  const [addInfo, setAddInfo] = React.useState(false);
  const [addressing, setAddressing] = React.useState(false);

  const [deliveryArea, setArea] = React.useState('');
  const [deliveryAmount, setAmount] = React.useState('');

  const [confirmDelete, setConfirmDelete] = React.useState(false);
  const [deleteData, setDeleteData] = React.useState({});
  const [deleting, setDeleting] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);

  const [gettingAddresses, setGettingAddress] = React.useState(false);

  React.useLayoutEffect(() => {
    const enter = async event => {
      const inputs = form.current.querySelectorAll('input');
      if (event.keyCode === 13) {
        await addAddress(event, {
          area: inputs[0].value,
          amount: inputs[1].value,
        });
        event.preventDefault();
      }
    };

    if (activeTab === 'delivery-info' && addInfo) {
      document.addEventListener('keydown', enter);
    }

    return () => document.removeEventListener('keydown', enter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTab, addInfo]);

  React.useEffect(() => {
    (async () => await fetchEvents())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (isBusinessAdmin) {
      (async () => await getAddresses())();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteAddress = async () => {
    setDeleting(true);
    try {
      const data = await delivery.deleteAddress({ id: deleteData.id });

      if (data?.success) {
        setDeleteData({});
        setConfirmDelete(false);
        await getAddresses();
      }
    } finally {
      setDeleting(false);
    }
  };

  const deleteCatalog = async () => {
    setDeleting(true);
    try {
      const data = await store.deleteCatalog({ ref: details?.ref });

      if (data?.success) {
        await fetchEvents();
        await setDeleteModal(false);
      }
    } finally {
      setDeleting(false);
    }
  };

  const getAddresses = async () => {
    setGettingAddress(true);
    try {
      const data = await delivery.getAddresses();
      if (data?.success) {
        setDeliveryInfo([...data.response]);
        await dispatch(handleDeliveryInfoUpdate(data.response));
      }
    } finally {
      setGettingAddress(false);
    }
  };

  const addAddress = async (event, { area, amount }) => {
    event.preventDefault();
    setAddressing(true);

    try {
      // const data = await delivery.addAddress({
      //   area: deliveryArea || area,
      //   amount: deliveryAmount || amount,
      // });
      //
      // if (data?.success) {
      //   setAddInfo(false);
      //
      //   setArea('');
      //   setAmount('');
      //
      //   await getAddresses();
      // }

    } finally {
      setAddressing(false);
    }
  };

  const fetchEvents = async () => {
    setProcessing(true);
    try {
      const data = await event.fetch();

      if (data?.success) {
        setCatalogs(data?.response.results);
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Container isProcessing={isProcessing}>
        <div className='fx--header-container'>
          <div className={'fx--header-container-child'}>
            <h2 className={'fx--header'}>
              Flux Events<span>.</span>
            </h2>
            {isBusinessAdmin && (
              <Button
                onClick={() => history.push('/flux-store/members/')}
                style={{ fontSize: '0.8rem', fontWeight: 400 }}
              >
                <FontAwesomeIcon icon={'users'} />
                View Events Members
              </Button>
            )}
          </div>
          {isBusinessAdmin && (
            <div className={'fx--header-tablist'}>
              <ul role={'tablist'} aria-label={'flux-store account options'}>
                {/*<li*/}
                {/*  role={'tab'}*/}
                {/*  aria-selected={activeTab === 'products'}*/}
                {/*  aria-controls={'controlled-box'}*/}
                {/*  // className={activeTab === 'products' ? 'active' : ''}*/}
                {/*  onClick={() => setActiveTab('products')}*/}
                {/*>*/}
                {/*  Tickets*/}
                {/*</li>*/}
              </ul>
            </div>
          )}
          {activeTab === 'delivery-info' && (
            <>
              <section className={'delivery-section'}>
                <div className={'delivery-info__section'}>
                  {addInfo && (
                    <div className='delivery-info__form'>
                      <form ref={form} onSubmit={addAddress}>
                        <TextInput
                          placeholder={'e.g Lagos Mainland'}
                          value={deliveryArea}
                          disabled={addressing}
                          label={'Location'}
                          onChange={({ target: { value } }) => {
                            setArea(value);
                          }}
                        />
                        <TextInput
                          placeholder={1200}
                          value={deliveryAmount}
                          disabled={addressing}
                          type={'number'}
                          label={'Fee'}
                          onChange={({ target: { value } }) => {
                            setAmount(value);
                          }}
                        />
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                          }}
                        >
                          <Button secondary onClick={() => setAddInfo(false)}>
                            Cancel
                          </Button>
                          <Button
                            type={'submit'}
                            primary
                            onClick={async event => {
                              event.preventDefault();
                              await addAddress(event, {
                                area: deliveryArea,
                                amount: deliveryAmount,
                              });
                            }}
                            disabled={
                              addressing || !deliveryAmount || !deliveryArea
                            }
                            $loading={addressing}
                          >
                            Save
                          </Button>
                        </div>
                      </form>
                    </div>
                  )}
                  <div className={'delivery-info__container'}>
                    {gettingAddresses ? (
                      <Spinner />
                    ) : !!deliveryInfo.length ? (
                      !addInfo && (
                        <div className='delivery-info'>
                          <div className={'row'}>
                            <h5>Location</h5>
                            <h5>Fee</h5>
                          </div>
                          {deliveryInfo.map(delivery => (
                            <div
                              className='row'
                              key={`${delivery.area}${Math.random() * 100}`}
                            >
                              <span>{delivery.area}</span>
                              <span>{formatAmount(delivery.amount / 100)}</span>
                              <div>
                                <span
                                  role={'button'}
                                  className={'delete-button'}
                                  onClick={() => {
                                    setConfirmDelete(true);
                                    setDeleteData(delivery);
                                  }}
                                >
                                  Delete
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      )
                    ) : (
                      !addInfo && (
                        <div className={'empty-delivery--info'}>
                          <span>You haven't added any delivery info</span>
                        </div>
                      )
                    )}
                    {!addInfo && (
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'flex-end',
                          marginTop: '1.4rem',
                        }}
                      >
                        <Button onClick={() => setAddInfo(true)}>
                          <FontAwesomeIcon icon={'plus'} />
                          Add delivery fee
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </section>
            </>
          )}
        </div>
        {activeTab === 'products' && (
          <div className='fx--product-list-container' id={'controlled-box'}>
            <div className={'fx--header-store-options'}>
              <div>
                <Button primary onClick={() => setAddProduct(true)}>
                  <FontAwesomeIcon icon={'plus'} />
                  Add Event
                </Button>
              </div>
            </div>
            <div className='fx--product-list-box'>
              {isProcessing ? (
                <Spinner />
              ) : !!catalogs.length ? (
                catalogs.map(catalog => (
                  <ProductItem
                    key={JSON.stringify(catalog)}
                    name={catalog.name}
                    image={catalog?.image_url_1}
                    data={catalog}
                    onEdit={async () => {
                      await setDetails(() => catalog);
                      await setAddProduct(false);
                      await setEditModal(true);
                    }}
                    onDelete={async () => {
                      await setDetails(() => catalog);
                      await setDeleteModal(true);
                    }}
                  />
                ))
              ) : null}
            </div>
          </div>
        )}
      </Container>
      <AddProduct
        visible={productModal}
        onDismiss={() => setAddProduct(false)}
        onComplete={async () => await fetchEvents()}
        onDeliveryOption={() => {
          setAddProduct(false);
          setActiveTab('delivery-info');
        }}
      />
      <EditProduct
        visible={editProductModal}
        onDismiss={() => {
          setDetails('');
          setEditModal(false);
        }}
        data={details}
        onComplete={async () => await fetchEvents()}
      />
      <Modal
        id={'deleteStoreMember'}
        visible={deleteModal}
        onDismiss={() => {
          setDetails('');
          setDeleteModal(false);
        }}
      >
        <h5>Are you sure you want to delete this catalog?</h5>
        <div
          style={{
            marginTop: '1.4rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button
            secondary
            style={{ flex: 0.48 }}
            onClick={() => setConfirmDelete(false)}
            disabled={deleting}
          >
            No
          </Button>
          <Button
            style={{ flex: 0.48 }}
            onClick={() => deleteCatalog()}
            disabled={deleting}
            $loading={deleting}
          >
            Yes
          </Button>
        </div>
      </Modal>
      <Modal
        id={'deleteAddress'}
        visible={confirmDelete}
        title={'Confirm Delete'}
      >
        <h5>Are you sure you want to delete this address?</h5>
        <div
          style={{
            marginTop: '1.4rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button
            secondary
            style={{ flex: 0.48 }}
            onClick={() => setConfirmDelete(false)}
            disabled={deleting}
          >
            No
          </Button>
          <Button
            style={{ flex: 0.48 }}
            onClick={() => deleteAddress()}
            disabled={deleting}
            $loading={deleting}
          >
            Yes
          </Button>
        </div>
      </Modal>{' '}
    </>
  );
};

export default FluxEvents;
