
const objectWithoutKeyword = (object, keyword) => {
  const keys = Object.keys(object);

  return keys
    .filter(key => !key.toLowerCase().includes(keyword))
    .reduce(
      (acc, val) => ({
        ...acc,
        [val]: object[val],
      }),
      {},
    );
};

export default objectWithoutKeyword;
