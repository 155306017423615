import * as React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container, { BadgeContainer, ModalBody } from './styles';
import { Horizon, Modal } from '../../../../../armoury';
import { copy, formatAmount } from '../../../../../helpers';
import { useSelector } from 'react-redux';

const TicketItem = ({ image, name, price = 0, data, onEdit, onDelete }) => {
  const [isMenuOpen] = React.useState(false);
  const [showDetails, setShowDetails] = React.useState(false);
  const user = useSelector(({ user }) => user);
  console.log(user);

  const [details] = React.useState(data || '');

  const edit = async () => {
    await onEdit(details);
  };

  const deleteCatalog = async () => {
    await onDelete(details);
  };

  // React.useEffect(() => {
  //   if (showDetails) {
  //     getCount();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [showDetails]);

  // const getCount = async () => {
  //   try {
  //     const { success, response } = await event.get({
  //       id: data.id,
  //     });
  //
  //     if (success) {
  //       console.log(response);
  //     }
  //   } finally {
  //     //
  //   }
  // };

  return (
    <>
      <Container onClick={() => setShowDetails(true)}>
        <LazyLoadImage
          effect={'blur'}
          style={{
            boxShadow: '0 0 2px #00000029',
            width: '100%',
            height: '150px',
            borderRadius: '8px',
            overflow: 'hidden',
            objectFit: 'cover',
            objectPosition: 'bottom',
          }}
          src={
            image ||
            'https://flux-frontend-s3.s3-us-west-2.amazonaws.com/flux-store/fabian-albert-ePJUCF48vgo-unsplash.png'
          }
          alt={name}
        />
        <figcaption>
          <span className={'product-name'}>{name}</span>
          {!!price && (
            <span className="product-price">{formatAmount(price / 100)}</span>
          )}
          <Badge status={'published'} />
        </figcaption>
        {/*<FontAwesomeIcon*/}
        {/*  icon={'ellipsis-v'}*/}
        {/*  className={'svg'}*/}
        {/*  onClick={e => {*/}
        {/*    e.stopPropagation();*/}
        {/*    setOpenMenu(o => !o);*/}
        {/*  }}*/}
        {/*/>*/}
        {isMenuOpen && (
          <nav
            aria-label={'available card options'}
            className={'menu'}
            id={'navMenu'}
          >
            <ul role={'menubar'}>
              <li role={'menuitem'}>View Card Details</li>
              <li role={'menuitem'}>Topup Card</li>
              <li role={'menuitem'}>Withdraw</li>
            </ul>
          </nav>
        )}
      </Container>
      <Modal
        id={'productDetails'}
        visible={showDetails}
        title={''}
        onDismiss={() => setShowDetails(false)}
        ariaLabel={'product details'}
        customStyle={{ maxWidth: '720px', width: '100%', margin: 'auto 12px' }}
      >
        <ModalBody>
          <div>
            <div className="product-pictures--container">
              <div
                id={'maxi-image'}
                style={{
                  backgroundImage: `url('${data?.image_url_1}')`,
                }}
              />
            </div>
          </div>
          <div>
            <h3>Event Details</h3>
            <h4>{details.name || ''}</h4>
            <p>
              <strong>Description:</strong> {details.description || ''}
            </p>
            <div>
              <div>
                {!!price && (
                  <span className={'price'}>{formatAmount(price / 100)}</span>
                )}
              </div>
            </div>
            <Horizon color={'#000000'} thickness={1} />
            <div className={'extras-container'}>
              <div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <span aria-labelledby={'item1'} style={{ fontWeight: 400 }}>
                    {data?.location}
                  </span>
                </div>
              </div>
              {/*<div>*/}
              {/*  <span id={'item2'}>Delivery</span>*/}
              {/*  <span aria-labelledby={'item2'}>Nationwide Delivery</span>*/}
              {/*</div>*/}
            </div>
            <div className="action-container">
              <div>
                <div
                  role={'button'}
                  className={'edit-product'}
                  onClick={() => edit()}
                >
                  <FontAwesomeIcon icon={'pen'} />
                </div>
                <div
                  role={'button'}
                  className={'edit-product'}
                  onClick={() =>
                    copy(
                      `${process.env.REACT_APP_STORE_CLIENT_URL}catalog/details/${user?.business.name_slug}/${details.ref}/`,
                    )
                  }
                >
                  <FontAwesomeIcon icon={'link'} />
                </div>
                <div
                  role={'button'}
                  className={'edit-product'}
                  onClick={() => deleteCatalog()}
                >
                  <FontAwesomeIcon icon={'trash'} />
                </div>
              </div>
              {/*<div style={{ marginTop: 24 }}>*/}
              {/*  <Button*/}
              {/*    primary*/}
              {/*    onClick={() => history.push(`/event/tickets/${data?.id}/`)}*/}
              {/*  >*/}
              {/*    View Tickets*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const Badge = ({ status }) => {
  return <BadgeContainer status={status}>{status}</BadgeContainer>;
};

export default TicketItem;
