import { server, errorHandler } from '../../lib';
import toast from 'react-hot-toast';

const verifyUser = async ({ id }) => {
  try {
    const { status, data: response } = await server.get(
      id.includes('@')
        ? `/users/details/?flux_tag=${id}`
        : `/users/details/?flux_id=${id}`,
    );

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const sendMoney = async ({ receiver, amount, description }) => {
  let body = {
    receiver,
    amount: amount * 100,
  };

  if (!!description) {
    body = {
      ...body,
      description,
    };
  }
  try {
    const { status } = await server.post('/transactions/v2/', body);

    if (status === 200) {
      toast.success('Great! Money sent successfully');

      return {
        success: true,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const transaction = {
  verifyUser,
  sendMoney,
};

export default transaction;
