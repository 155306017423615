export const banks = [
  {
    name: 'Access Bank',
    id: '044',
  },
  {
    name: 'Citibank',
    id: '023',
  },
  {
    name: 'Diamond Bank',
    id: '063',
  },
  {
    name: 'Ecobank Nigeria',
    id: '050',
  },
  {
    name: 'Fidelity Bank Nigeria',
    id: '070',
  },
  {
    name: 'First Bank of Nigeria',
    id: '011',
  },
  {
    name: 'First City Monument Bank',
    id: '214',
  },
  {
    name: 'Guaranty Trust Bank',
    id: '058',
    slug: 'gtb',
  },
  {
    name: 'Heritage Bank Plc',
    id: '030',
  },
  {
    name: 'Jaiz Bank',
    id: '301',
  },
  {
    name: 'Keystone Bank Limited',
    id: '082',
  },
  {
    name: 'Providus Bank Plc',
    id: '101',
  },
  {
    name: 'Polaris Bank',
    id: '076',
  },
  {
    name: 'Stanbic IBTC Bank Nigeria Limited',
    id: '221',
  },
  {
    name: 'Standard Chartered Bank',
    id: '068',
  },
  {
    name: 'Sterling Bank',
    id: '232',
  },
  {
    name: 'Suntrust Bank Nigeria Limited',
    id: '100',
  },
  {
    name: 'Union Bank of Nigeria',
    id: '032',
  },
  {
    name: 'United Bank for Africa',
    id: '033',
  },
  {
    name: 'Unity Bank Plc',
    id: '215',
  },
  {
    name: 'Wema Bank',
    id: '035',
  },
  {
    name: 'Zenith Bank',
    id: '057',
    slug: 'zenith',
  },
];

const categories = [
  'Food Services',
  'Catering Services',
  'E-commerce',
  'Fashion and Clothing',
  'Fashion and Beauty',
  'Dry Cleaning Services',
  'Internet Services',
  'Transportation Services',
  'Cyber Cafe',
  'Real Estate',
  'Graphics Design',
  'Software development',
  'Clothing Events',
  'Shoe Events',
  'Drinks and Breweries',
  'Tutoring Services',
  'Shoe Services',
  'Business Center',
  'Ice Cream Services',
  'Computers and Gadgets',
  'IT Services',
  'Exterior decoration',
];

const productCategories1 = [
  'Food and Drinks',
  'Beauty',
  'Fashion, Clothing and shoes',
  'Dry Cleaning',
  'Internet Service',
  'Real Estate',
  'Graphics Design',
  'Software Development',
  'Tutoring Service',
  'Computers and Gadgets',
  'IT Services',
  'Event',
];

export const productCategories = productCategories1.map(category => ({
  name: category,
  id: category.toLowerCase().split(' ').join('-'),
}));

export const businessCategories = categories.map(category => ({
  name: category,
  id: category.toLowerCase().split(' ').join('-'),
}));
