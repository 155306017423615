import { server, errorHandler } from '../../lib';
import toast from 'react-hot-toast';

const fetchCards = async () => {
  try {
    const { status, data: response } = await server.get('/virtual_cards/');

    if (status === 200) {
      return {
        success: true,
        response: response.results,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const create = async ({ amount, card_style }) => {
  try {
    const { status, data: response } = await server.post(
      '/virtual_cards/create/',
      {
        amount: amount * 100,
        currency: 'USD',
        card_style,
      },
    );

    if (status === 200) {
      toast.success(response.msg);

      return {
        success: true,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const withdraw = async ({ ref, amount }) => {
  try {
    const { status, data: response } = await server.post(
      '/virtual_cards/withdraw/',
      {
        ref,
        amount: amount * 100,
      },
    );

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const terminate = async ({ ref }) => {
  try {
    const { status, data: response } = await server.post(
      '/virtual_cards/terminate/',
      {
        ref,
      },
    );

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const block = async ({ ref }) => {
  try {
    const { status, data: response } = await server.post(
      '/virtual_cards/status/',
      {
        ref,
        action: 'block',
      },
    );

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const unblock = async ({ ref }) => {
  try {
    const { status, data: response } = await server.post(
      '/virtual_cards/status/',
      {
        ref,
        action: 'unblock',
      },
    );

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const card = {
  create,
  fetchCards,
  withdraw,
  terminate,
  block,
  unblock,
};

export default card;
