import { applyMiddleware, compose, createStore } from 'redux';
import { persistReducer, persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { composeWithDevTools } from 'redux-devtools-extension';

import reducers from './reducers';

const persistConfig = {
  key: 'red-dawn',
  storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);

const config = () => {
  const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(compose(thunk))),
  );
  const persistor = persistStore(store);

  return {
    store,
    persistor,
  };
};

export default config;
