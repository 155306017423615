import * as React from 'react';
import { useTheme } from 'styled-components';
import S3 from 'react-aws-s3';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Horizon, Modal, TextInput } from '../../../../../armoury';
import Container, {
  FileUploadContainer,
  FooterContainer,
  UploadedImages,
} from './styles';
import { BREAKPOINTS } from '../../../../../constants';
import { NProgress } from '../../../../../lib';
import { objectWithoutKeyword } from '../../../../../helpers';
import { event as events } from '../../../../../api';

const awsConfig = {
  // bucketName: process.env.REACT_APP_BUCKET_NAME,
  // dirName: 'Catalogs',
  // accessKeyId: process.env.REACT_APP_ACCESS_ID,
  // secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  // region: process.env.REACT_APP_AWS_REGION,
  bucketName: 'flux-frontend-s3',
  dirName: 'Catalogs',
  accessKeyId: 'AKIAT5EZTCF4IKLLXOF2',
  secretAccessKey: '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',
  region: 'us-west-2',
};

const AddProduct = ({ visible, onDismiss, onComplete, onDeliveryOption }) => {
  const [isProcessing, setProcessing] = React.useState(false);
  const input = React.useRef(null);
  const { colors } = useTheme();

  const [images, setImages] = React.useState([]);

  const [step, setStep] = React.useState(1);

  const [uploading, setUploading] = React.useState(false);

  const [event, setEvent] = React.useState({
    name: '',
    nameError: false,
    description: '',
    descriptionError: false,
    location: '',
    locationError: false,
    start_sale_date: '',
    end_sale_date: '',
    event_date: '',
  });

  const addProduct = async () => {
    setProcessing(true);
    try {
      const data = await events.create({
        ...objectWithoutKeyword(event, 'error'),
        title: event.name,
        start_sale_date: event.startDate,
        end_sale_date: event.endDate,
        event_date: event.eventDate,
        ...images.reduce(
          (acc, val, index) => ({
            ...acc,
            [`image_url_${index + 1}`]: val,
          }),
          {},
        ),
      });

      if (data?.success) {
        await onDismiss();

        setEvent({
          name: '',
          nameError: false,
          description: '',
          descriptionError: false,
          amount: '',
          amountError: false,
        });
        await onComplete();
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleInputClick = async () => input.current?.click();

  const handleFileChange = async () => {
    NProgress.start();
    setUploading(true);
    try {
      if (images.length < 5) {
        const file = input.current?.files[0];
        const fileName = `${new Date().valueOf()}${file?.name}`;

        const s3Flux = new S3(awsConfig);

        if (!uploading) {
          const s3response = await s3Flux.uploadFile(file, fileName);

          if (s3response?.status === 204) {
            await setImages(() => [...images, s3response?.location]);
          }
        }
      }
    } finally {
      NProgress.done();
      setUploading(false);
    }
  };

  const deleteImage = async location => {
    setUploading(true);
    setProcessing(true);
    NProgress.start();
    try {
      const fileName = location.split('Catalogs/')[1];
      const s3Flux = new S3(awsConfig);

      if (!uploading) {
        await s3Flux.deleteFile(fileName);

        const index = images.indexOf(location);
        const IMAGES = [...images];
        IMAGES.splice(index, 1);

        setImages(() => IMAGES);
      }
    } finally {
      NProgress.done();
      setUploading(false);
      setProcessing(false);
    }
  };

  return (
    <Modal
      id={'addProduct'}
      visible={visible}
      ariaLabel={'Create event'}
      onDismiss={onDismiss}
      title={'Create Event'}
      customStyle={{
        maxWidth: BREAKPOINTS.medium,
        width: '100%',
        overflowY: 'auto !important',
      }}
    >
      <Horizon />
      <Container>
        <div>
          <div aria-label={'step 1 of adding product to catalogue'}>
            <div className={'step-container'} role={'tablist'}>
              <div
                role={'tab'}
                aria-selected={step === 1}
                onClick={() => setStep(1)}
              >
                <div />
                <span>Event details</span>
              </div>
              <div className={'separator'} />
              <div
                role={'tab'}
                aria-selected={step === 2}
                onClick={() => setStep(2)}
              >
                <div />
                <span>Banner</span>
              </div>
            </div>
          </div>
        </div>

        <div>
          <div>
            {step === 1 && (
              <form name="product--form" noValidate>
                <TextInput
                  name={'title'}
                  type={'text'}
                  placeholder={"Event's name"}
                  required
                  label={'Title'}
                  value={event.name}
                  error={event.nameError}
                  onChange={({ target: { value } }) => {
                    setEvent({
                      ...event,
                      name: value,
                      nameError: !value,
                    });
                  }}
                />
                <TextInput
                  name={'description'}
                  type={'text'}
                  placeholder={'Description'}
                  label={'Description'}
                  required
                  textArea
                  value={event.description}
                  error={event.descriptionError}
                  onChange={({ target: { value } }) => {
                    setEvent({
                      ...event,
                      description: value,
                      descriptionError: !value,
                    });
                  }}
                />
                <fieldset className={'data'}>
                  <TextInput
                    name={'description'}
                    type={'text'}
                    placeholder={'YYYY-MM-DD HH:MM'}
                    label={'Ticket Sale Start Date'}
                    required
                    value={event.startDate}
                    onChange={({ target: { value } }) => {
                      setEvent({
                        ...event,
                        startDate: value,
                        startDateError: !value,
                      });
                    }}
                  />
                </fieldset>
                <fieldset className={'data'}>
                  <TextInput
                    name={'description'}
                    type={'text'}
                    placeholder={'YYYY-MM-DD HH:MM'}
                    label={'Ticket Sale End Date'}
                    required
                    value={event.endDate}
                    onChange={({ target: { value } }) => {
                      setEvent({
                        ...event,
                        endDate: value,
                        endDateError: !value,
                      });
                    }}
                  />
                </fieldset>
                <TextInput
                  name={'location'}
                  placeholder={'10 Hughes avenue, Yaba'}
                  required
                  label={'Event location'}
                  value={event.location}
                  error={event.locationError}
                  onChange={({ target: { value } }) => {
                    setEvent({
                      ...event,
                      location: value,
                      locationError: !value || value < 1,
                    });
                  }}
                />
                <fieldset className={'data'}>
                  <TextInput
                    name={'description'}
                    type={'text'}
                    placeholder={'YYYY-MM-DD HH:MM'}
                    label={'Event Date'}
                    required
                    value={event.eventDate}
                    onChange={({ target: { value } }) => {
                      setEvent({
                        ...event,
                        eventDate: value,
                        eventDateError: !value,
                      });
                    }}
                  />
                </fieldset>
              </form>
            )}
            {step === 2 && (
              <div id={'image-upload'}>
                <FileUploadContainer
                  role={'button'}
                  aria-label={'upload product images'}
                  onClick={() => handleInputClick()}
                >
                  <div>
                    <label htmlFor="{'products'}">
                      <FontAwesomeIcon icon="upload" />
                      Upload Image. Max 2MB
                    </label>
                    <input
                      ref={input}
                      type="file"
                      name={'products'}
                      className={'fx-file__input'}
                      onChange={() => handleFileChange()}
                      multiple
                      accept={'image/png, image/jpeg'}
                    />
                  </div>
                </FileUploadContainer>
                <UploadedImages>
                  {images.map((image, index) => (
                    <div aria-label={'delete image'} key={image}>
                      <div>
                        <img src={image} alt={`product ${index}`} />
                      </div>
                      <FontAwesomeIcon
                        icon={'times-circle'}
                        color={colors.primary}
                        onClick={() => deleteImage(image)}
                      />
                    </div>
                  ))}
                </UploadedImages>
              </div>
            )}
          </div>
          <FooterContainer className="mobile">
            <span role={'button'} />
            <div>
              {!isProcessing && step !== 1 && (
                <Button
                  secondary
                  disabled={isProcessing}
                  $loading={isProcessing}
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Back
                </Button>
              )}
              <Button
                primary
                aria-label={'submit ticket details form'}
                onClick={step === 1 ? () => setStep(2) : () => addProduct()}
                disabled={isProcessing}
                $loading={isProcessing}
              >
                {step === 1 ? 'Continue' : 'Create ticket'}
              </Button>
            </div>
          </FooterContainer>
        </div>
      </Container>
      <FooterContainer>
        <span role={'button'} />
        <div>
          {!isProcessing && step !== 1 && (
            <Button
              secondary
              disabled={isProcessing}
              $loading={isProcessing}
              onClick={() => {
                setStep(1);
              }}
            >
              Back
            </Button>
          )}
          <Button
            primary
            aria-label={'submit product details form'}
            onClick={step === 1 ? () => setStep(2) : () => addProduct()}
            disabled={isProcessing}
            $loading={isProcessing}
          >
            Next
          </Button>
        </div>
      </FooterContainer>
    </Modal>
  );
};

export default AddProduct;
