import { errorHandler, server } from '../../lib';
import { toast } from 'react-hot-toast';

const get = async () => {
  try {
    const { status, data: response } = await server.get('/business/members/');

    if (status === 200) {
      return {
        response: response.results,
        success: true,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const addMember = async ({ user }) => {
  try {
    const { status, data: response } = await server.post('/business/members/', {
      user,
    });

    if (status === 201) {
      toast.success('Great! Member added successfully');

      return {
        response,
        success: true,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const deleteMember = async ({ id }) => {
  try {
    const { status, data: response } = await server.delete(
      `/business/members/${id}/`,
    );

    if (status === 204) {
      return {
        response,
        success: true,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const members = {
  getMembers: get,
  addMember,
  deleteMember,
};

export default members;
