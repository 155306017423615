import axios from 'axios';
import { v4 as uuid } from 'uuid';

import { errorHandler, NProgress } from '../../lib';

const server = axios.create({
  baseURL: process.env.REACT_APP_STORE_API_URL,
});

server.interceptors.request.use(async config => {
  config.headers = {
    Authorization: `Bearer ${process.env.REACT_APP_STORE_TOKEN}`,
  };

  NProgress.start();
  return config;
});

server.interceptors.response.use(
  response => {
    NProgress.done();
    return response;
  },
  err => {
    NProgress.done();
    return Promise.reject(err);
  },
);

const createRef = async ({ amount, merchant_id, catalog }) => {
  try {
    const { status, data: response } = await server.post(
      'https://fluxpay.fluxapi.co.uk/api/v1/transaction/generate',
      {
        amount: amount * 100,
      },
      {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_STORE_TOKEN}`,
        },
      },
    );

    if (status === 200 && response.code === 200) {
      const token = response?.transaction_ref;

      const catData = Object.values(catalog).join('__').replace(' ', '-');

      const clientURL = `${
        process.env.REACT_APP_STORE_CHECKOUT_CLIENT_URL
      }checkout/?session_id=${token}&token=${uuid()}&client_id=${merchant_id}&amount=${amount}&cat_info=${catData}`;

      const newWindow = window.open(
        clientURL,
        'payWithFlux',
        'resizeable=true',
      );

      if (
        !newWindow ||
        newWindow.closed ||
        typeof newWindow.closed === 'undefined'
      ) {
        window.location.href = clientURL;
      }
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const catalog = {
  createRef,
};

export default catalog;
