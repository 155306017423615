import * as React from 'react';

import { card } from '../../../../api';
import { Button, Horizon, Modal, TextInput } from '../../../../armoury';
import { DialogContent } from '../styles';
import { formatAmount } from '../../../../helpers';

const Withdraw = ({ visible, onDismiss, reference }) => {
  const [amount, setAmount] = React.useState('');
  const [amountError, setAmountError] = React.useState(false);

  const [isProcessing, setIsProcessing] = React.useState(false);

  const withdraw = async () => {
    setIsProcessing(true);

    try {
      const data = await card.withdraw({ amount, ref: reference });

      if (data?.success) {
        await onDismiss();
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal
      id={'withdrawFromDollarCard'}
      visible={visible}
      onDismiss={onDismiss}
      ariaLabel={'withdraw dollar virtual card'}
      title={'Withdraw (Dollar)'}
    >
      <DialogContent>
        <div className={'fx-card--form-container'}>
          <form action="">
            <TextInput
              placeholder={'Enter amount ($)'}
              label={'How much would you like to withdraw from your card?'}
              type={'number'}
              labelStyle={{ color: 'rgb(112,112,112)' }}
              value={amount}
              error={amountError}
              onChange={({ target: { value } }) => {
                setAmount(value);
                setAmountError(!value || !Number(value));
              }}
            />
            {/*{amountError && (*/}
            {/*  <span className={'error-text'}>*/}
            {/*    You can only fund between $5 and $5000*/}
            {/*  </span>*/}
            {/*)}*/}
            <div className={'fx-card--rate-row'}>
              <div className={'row'}>
                <span></span>
                <span>@&#8358;400/&#36;</span>
              </div>
              <Horizon />
              <div className={'row'}>
                <span>Total Withdrawable</span>
                <span style={{ color: '#222222' }}>
                  {formatAmount(400 * Number(amount))}
                </span>
              </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                primary
                $loading={isProcessing}
                disabled={isProcessing || !amount || amountError}
                onClick={() => withdraw()}
                style={{ textAlign: 'center' }}
              >
                Withdraw
              </Button>
            </div>
          </form>
        </div>
      </DialogContent>
    </Modal>
  );
};

export default Withdraw;
