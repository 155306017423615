import styled from 'styled-components';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.base};
  max-width: 360px;
  width: 100%;
  box-shadow: 0 0 5px ${({ theme }) => theme.colors.shadow};
  padding: 12px 0;
  border-radius: 8px;

  div.row-header {
    padding: 4px 24px;

    > div {
      display: flex;
      flex-direction: column;

      span:first-child {
        margin-bottom: 4px;
        font-size: 0.9rem;
        font-weight: 400;
        rgba(34, 34, 34, 1)
      }

      span:nth-child(2) {
        font-weight: 300;
        color: rgba(34, 34, 34, 0.5);
      }
    }
  }

  div.card-content{
    padding: 0 24px;
    div.row {
      padding: 12px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      span:first-child {
        color: rgba(34, 34, 34, 1);
      }

      span:nth-child(2) {
        color: rgba(34, 34, 34, 0.5);
        font-weight: 300;
      }
    }
  }
`;

export default Container;
