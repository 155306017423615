import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container, { DialogContent } from './styles';
import {
  Button,
  Modal,
  Drawer,
  TextInput,
  Horizon,
  Spinner,
  VCard,
} from '../../../armoury';
import { card } from '../../../api';
import { formatAmount } from '../../../helpers';

const CreateModal = ({ visible, onDismiss }) => {
  const [cardType, setCardType] = React.useState(0);

  const [amount, setAmount] = React.useState('');
  const [amountError, setAmountError] = React.useState(false);

  const [isProcessing, setIsProcessing] = React.useState(false);

  const create = async () => {
    setIsProcessing(true);
    try {
      const data = await card.create({ amount, card_style: cardType });

      if (data?.success) {
        await onDismiss();
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <Modal
      id={'createDollarCard'}
      visible={visible}
      onDismiss={onDismiss}
      ariaLabel={'create dollar virtual card'}
      title={'Create card'}
    >
      <DialogContent>
        <Drawer.Group
          customStyle={{ display: 'flex', padding: '0' }}
          title={'Select card type'}
          labelStyle={{
            color: 'rgb(112,112,112)',
            fontWeight: '400',
            fontSize: '0.8rem',
          }}
        >
          <Drawer.Item
            type={'simple'}
            active={cardType === 0}
            onClick={() => setCardType(0)}
          >
            <img
              src="https://flux-frontend-s3.s3-us-west-2.amazonaws.com/red-dawn/card-1.svg"
              alt="a deep purple background with patches or pink and green"
              style={{ height: 40, width: 60, borderRadius: 6 }}
            />
          </Drawer.Item>
          <Drawer.Item
            type={'simple'}
            active={cardType === 1}
            onClick={() => setCardType(1)}
            itemStyle={{ marginLeft: 12 }}
          >
            <img
              src="https://flux-frontend-s3.s3-us-west-2.amazonaws.com/red-dawn/card-2.svg"
              alt="a purple background with a patches of green and orange"
              style={{ height: 40, width: 60, borderRadius: 6 }}
            />
          </Drawer.Item>
          <Drawer.Item
            type={'simple'}
            onClick={() => setCardType(2)}
            itemStyle={{ position: 'relative', top: '-8px', marginLeft: 12 }}
            active={cardType === 2}
          >
            <img
              src="https://flux-frontend-s3.s3-us-west-2.amazonaws.com/red-dawn/card-3.svg"
              alt="a dark background and little star twinkles and a moon like shape at the top"
              style={{ height: 40, width: 60, borderRadius: 6 }}
            />
          </Drawer.Item>
        </Drawer.Group>
        <Horizon />
        <div className={'fx-card--form-container'}>
          <form action="">
            <TextInput
              placeholder={'Enter amount ($)'}
              label={'How much would you like to fund your card with?'}
              type={'number'}
              labelStyle={{ color: 'rgb(112,112,112)' }}
              value={amount}
              error={amountError}
              onChange={({ target: { value } }) => {
                setAmount(value);
                setAmountError(!value || !Number(value));
              }}
            />
            {amountError && (
              <span className={'error-text'}>
                You can only fund between $5 and $5000
              </span>
            )}
            <div className={'fx-card--rate-row'}>
              <div className={'row'}>
                <span></span>
                <span>@&#8358;528/&#36;</span>
              </div>
              <div className={'row'}>
                <span>Card Creation Fee</span>
                <span>&#8358;792.00</span>
              </div>
              <Horizon />
              <div className={'row'}>
                <span>Total Creditable Fee</span>
                <span style={{ color: '#222222' }}>
                  {formatAmount(528 * Number(amount))}
                </span>
              </div>
            </div>
            <Button
              primary
              wide
              $loading={isProcessing}
              disabled={isProcessing || !amount || amountError}
              onClick={() => create()}
            >
              Create Card
            </Button>
          </form>
        </div>
      </DialogContent>
    </Modal>
  );
};

const Cards = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const [isFetching, setFetching] = React.useState(false);

  const [cards, setCards] = React.useState([]);

  React.useEffect(() => {
    (async () => await getCards())();
  }, []);

  const getCards = async () => {
    setFetching(true);
    try {
      const data = await card.fetchCards();

      if (data?.success) {
        setCards(() => setCards(data.response));
      }
    } finally {
      setFetching(false);
    }
  };

  return (
    <>
      <Container>
        <div className="fx--empty-card_container">
          <div className={'fx--cards_empty'}>
            <h2>
              Flux Cards<span>.</span>
            </h2>
            {isFetching ? (
              <div>
                <Spinner />
              </div>
            ) : !cards.length ? (
              <>
                <p>
                  Pay your bills and make everyday purchases, with your Flux
                  virtual dollar card.
                </p>
                <Button
                  primary
                  onClick={() => setModalOpen(true)}
                  disabled={isFetching}
                >
                  <FontAwesomeIcon icon={'plus'} />
                  Create Dollar Card
                </Button>
              </>
            ) : (
              <>
                {cards
                  .filter(card => card?.is_active)
                  .map(card => (
                    <VCard dollarCard={card} key={JSON.stringify(card)} />
                  ))}
              </>
            )}
          </div>
        </div>
      </Container>
      <CreateModal
        visible={isModalOpen}
        onDismiss={() => setModalOpen(false)}
      />
    </>
  );
};

export default Cards;
