import axios from 'axios';
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';

import { NProgress } from '../index';

const getToken = async () => {
  const encryptedToken = await Cookies.get('red-dawn');

  if (!!encryptedToken) {
    const tokenByte = await CryptoJS?.TripleDES.decrypt(
      encryptedToken,
      'pluginUpdatesAvailable',
    );

    return tokenByte.toString(CryptoJS?.enc.Utf8);
  }
};

const server = axios.create({
  // baseURL: 'https://beta-api.fluxapi.co.uk',
  baseURL: 'https://core-api.fluxapi.co.uk',
});

server.interceptors.request.use(async config => {
  const token = await getToken();

  if (token) {
    config.headers = {
      Authorization: `Bearer ${token}`,
    };
  }

  NProgress.start();
  return config;
});

server.interceptors.response.use(
  response => {
    NProgress.done();
    return response;
  },
  err => {
    NProgress.done();
    return Promise.reject(err);
  },
);

export default server;
