import { css, default as styled } from 'styled-components';
import { lighten } from 'polished';

import { query } from '../../../../helpers';

const Container = styled.figure`
  max-width: 230px;
  overflow: hidden;
  padding-bottom: 12px;
  cursor: pointer;
  position: relative;
  z-index: 0;

  div.img-container {
  }

  img {
    width: 100%;
  }

  figcaption {
    padding: 4px 1px;

    span.product-name,
    span.product-price {
      display: block;
    }

    span.product-name {
      color: rgba(34, 34, 34, 0.7);
      font-weight: 400;
      font-size: 0.8rem;
      margin-bottom: 6px;
    }

    span.product-price {
      color: ${({ theme }) => theme.colors['primary-text']};
      font-weight: 400;
      font-size: 0.9rem;
      margin-bottom: 15px;
    }
  }

  //border-bottom: 1px solid #00000029;

  .svg {
    color: ${({ theme }) => theme.colors.base};
    position: absolute;
    right: 10px;
    top: 12px;
    width: 20px;
    height: 20px;
    padding: 4px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.5) 0 0 no-repeat padding-box;
    opacity: 1;
    backdrop-filter: blur(7px);
    -webkit-backdrop-filter: blur(7px);
  }

  nav.menu {
    background-color: #ffffff;
    position: absolute;
    right: 36px;
    top: 12px;
    border-radius: 4px;
    box-shadow: 0 5px 12px ${({ theme }) => theme.colors.shadow};

    ul {
      list-style-type: none;

      li {
        padding: 12px 16px;
        cursor: pointer;
        color: rgba(34, 34, 34, 0.6);
        font-weight: 400;
        font-size: 0.8rem;

        &:hover {
          background-color: #e7e7e7;
        }
      }
    }
  }
`;

export const BadgeContainer = styled.span`
  padding: 4px 8px;
  border-radius: 3px;
  text-transform: capitalize;
  margin-bottom: 12px;

  ${({ status }) =>
          status.toLowerCase().startsWith('publish') &&
          css`
            background-color: #e2ffec;
            color: #47c479;
          `}

  ${({ status }) =>
          status.toLowerCase().startsWith('unpublish') &&
          css`
            background-color: #ffe9e9;
            color: #ff5e5e;
          `}
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: row;

  ${query.maxWidth('medium')`
    flex-direction: column;
  `};

  > div:first-child {
    flex: 0.5;
    padding: 0 12px;
    margin-bottom: 2rem;

    div.product-pictures--container {
      display: flex;
      flex-direction: column;
      flex: 1;

      > div {
        &:first-child {
            background-color: ${({ theme }) => theme.colors.primary};
          border-radius: 8px;
          min-height: 250px;
          height: 100%;
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50% 50%;
        }

        &:nth-child(2) {
          margin-top: 1rem;
          display: flex;
          justify-content: center;

          div.mini-image {
            height: 50px;
            width: 50px;
              background-color: ${({ theme }) => theme.colors.primary};
            border-radius: 0.3rem;
            background-size: cover;
            background-repeat: no-repeat;
            cursor: pointer;
            background-position: 50% 50%;
            position: relative;

            &.active {
              cursor: not-allowed;
              &::after {
                content: '';
                position: absolute;
                height: 10px;
                width: 100px;
                bottom: 0;
              }
            }

            &:not(:last-child) {
              margin-right: 1rem;
            }
          }
        }
      }
    }
  }

  > div:nth-child(2) {
    flex: 0.5;
    padding: 0 12px;

    h3 {
      margin: 0;
      color: ${({ theme }) => theme.colors['primary-text']};
    }

    h4 {
      color: ${({ theme }) => theme.colors['primary-text']};
    }

    p {
      color: ${({ theme }) => lighten(0.4, theme.colors['primary-text'])};
      font-weight: lighter;
      font-size: 0.9rem;
      margin-bottom: 1rem;
    }

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;

      > div {
        span.price {
          margin-right: 12px;
          font-weight: 400;
        }

        span.status {
          display: inline-block;
          color: #47c479;

          &::before {
            content: '';
            height: 10px;
            width: 10px;
            background-color: red;
          }
        }
      }
    }

    div.extras-container {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;

      > div {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 4px;

        span {
          font-size: 0.8rem;
          font-weight: 300;
        }

        span:first-child {
          color: ${({ theme }) => lighten(0.2, theme.colors['primary-text'])};
        }

        span:nth-child(2) {
          color: ${({ theme }) => lighten(0, theme.colors['primary-text'])};
          font-weight: 400;
        }
      }
    }

    div.action-container {
      margin-top: 1rem;
      display: block;

      div {
        display: flex;

        > div {
          background-color: red;
          height: 36px;
          width: 36px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 6px;

          &:first-child {
            background-color: rgba(226, 237, 255, 1);

            svg {
              color: rgba(65, 135, 255, 1);
            }
          }

          &:nth-child(2) {
            background-color: rgba(249, 246, 251, 1);

            svg {
              color: rgba(133, 90, 175, 1);
            }
          }

          &:nth-child(3) {
            background-color: rgba(255, 233, 233, 1);

            svg {
              color: rgba(255, 94, 94, 1);
            }
          }

          &:not(:last-child) {
            margin-right: 1.5rem;
          }
        }
      }
    }
  }
`;

export default Container;
