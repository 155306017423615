import styled from 'styled-components';
import { lighten } from 'polished';

const Container = styled.div`
  padding: 8px 12px;
  height: 42px;
  background-color: ${({ theme }) => lighten(0.45, theme.colors.primary)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;

  svg {
    color: ${({ theme, uploaded }) =>
      uploaded ? theme.colors.success.base : '#ababab'};
    font-size: 0.9rem;
    margin-right: 12px;
  }

  > div {
    span {
      color: ${({ theme }) => theme.colors['primary-text']};
    }
  }

  > span {
    cursor: pointer;
    color: ${({ theme }) => theme.colors.primary};
  }

  &:not(:last-child) {
    margin-bottom: 1.5rem;
  }
`;

export default Container;
