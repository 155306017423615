import * as React from 'react';

import Container from './styles';
import { capitalize } from '../../helpers';

const DropD = ({
  label,
  error,
  id,
  name,
  value,
  onChange,
  disabled,
  required,
  placeholder,
  options = [],
}) => {
  return (
    <Container error={error} disabled={disabled}>
      {!!label && <label htmlFor={id}>{label}</label>}
      <select
        required={required}
        name={name}
        onChange={e => onChange && onChange(e)}
        value={value}
        disabled={disabled}
      >
        <option value="">{placeholder || 'No item selected'}</option>
        {options.map(option => (
          <option value={option.id} key={JSON.stringify(option)}>
            {capitalize(option.name)}
          </option>
        ))}
      </select>
    </Container>
  );
};

export default DropD;
