import { default as React } from 'react';

import Container from './style';

const TextInput = ({
  id,
  placeholder,
  required,
  name = '',
  error = false,
  textArea = false,
  label = '',
  type = 'text',
  onChange,
  value,
  disabled,
  labelStyle,
  onBlur,
}) => {
  const errorRef = React.useRef(null);
  return (
    <Container error={error} disabled={disabled}>
      {!!label && (
        <label htmlFor={id} style={labelStyle}>
          {label}
        </label>
      )}
      {!textArea ? (
        <input
          type={type}
          placeholder={placeholder}
          required={required}
          name={name}
          onChange={e => {
            errorRef.current.innerHTML = '';
            if (onChange) onChange(e);
          }}
          onBlur={onBlur}
          value={value}
          disabled={disabled}
        />
      ) : (
        <textarea
          name={name}
          cols={20}
          rows={2}
          disabled={disabled}
          required={required}
          value={value}
          onBlur={onBlur}
          placeholder={placeholder}
          onChange={e => {
            errorRef.current.innerHTML = '';
            if (onChange) onChange(e);
          }}
        />
      )}
      <p className="error-msg" ref={errorRef} />
    </Container>
  );
};

export default TextInput;
