import styled from 'styled-components';

const Container = styled.div`
  padding: 12px 0;

  div.rates-container {
    padding: 18px 0;

    div.row {
      padding: 4px 0;
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      span:first-child {
        color: rgba(34, 34, 34, 0.7);
        font-weight: 300;
      }

      span:nth-child(2) {
        color: rgba(34, 34, 34, 1);
        font-weight: 400;
      }
    }
  }

  div.form-row {
    margin-bottom: 12px;
  }

  span.helper-text {
    color: rgba(34, 34, 34, 0.7);
    font-size: 0.8rem;
    font-weight: 300;

    span.important {
      color: rgba(255, 94, 94, 1);
    }
  }
`;

export default Container;
