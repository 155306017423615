import styled from 'styled-components';
import { Modal } from '../../../../armoury';

const Container = styled(Modal).attrs(props => ({
  customStyle: {

  },
}))``;

export default Container;
