import { UPDATE_DELIVERY_INFO } from '../types';

const updateInfo = payload => {
  return {
    type: UPDATE_DELIVERY_INFO,
    payload,
  };
};

export const handleDeliveryInfoUpdate = payload => async dispatch => {
  await dispatch(updateInfo(payload));
};
