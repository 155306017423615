import * as React from 'react';
import { useDispatch } from 'react-redux';

import Container from './styles';

import { Button, TextInput } from '../../../../armoury';
import { user } from '../../../../api';
import { handleUserRefresh } from '../../../../store/actions';

const CreateBusinessAccount = ({ visible, onDismiss }) => {
  const dispatch = useDispatch();

  const [name, setName] = React.useState('');
  const [nameError, setNameError] = React.useState(false);

  const [description, setDescription] = React.useState('');
  const [descriptionError, setDescriptionError] = React.useState(false);

  const [isProcessing, setProcessing] = React.useState(false);

  React.useEffect(() => {
    (async () => await dispatch(handleUserRefresh()))();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createBusiness = async event => {
    event.preventDefault();

    setProcessing(true);
    try {
      const data = await user.upgradeToBusiness({ name, description });

      if (data?.success) {
        setName('');
        setDescription('');
        setDescriptionError(false);
        setNameError(false);
        await onDismiss();
        window.location.href = '/compliance/';
      }
    } finally {
      setProcessing(false);
    }
  };

  const disabled = React.useMemo(
    () =>
      !name || nameError || !description || descriptionError || isProcessing,
    [description, descriptionError, isProcessing, name, nameError],
  );

  return (
    <Container
      visible={visible}
      onDismiss={onDismiss}
      title={'Become a merchant'}
    >
      <form style={{ marginTop: '1rem' }}>
        <TextInput
          placeholder={"What's the name of your business"}
          label={'Business name'}
          value={name}
          error={nameError}
          onChange={({ target: { value } }) => {
            setName(value);
            setNameError(!value);
          }}
        />
        <TextInput
          placeholder={'What do you do?'}
          label={'Business description'}
          value={description}
          error={descriptionError}
          onChange={({ target: { value } }) => {
            setDescription(value);
            setDescriptionError(!value);
          }}
        />
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: '1.5rem',
          }}
        >
          <Button secondary onClick={() => onDismiss()} disabled={isProcessing}>
            Cancel
          </Button>
          <Button
            primary
            onClick={createBusiness}
            $loading={isProcessing}
            disabled={disabled}
          >
            Submit
          </Button>
        </div>
      </form>
    </Container>
  );
};

export default CreateBusinessAccount;
