import * as React from 'react';

import { Modal, Button } from '../../../../armoury';
import { card } from '../../../../api';

const Block = ({ visible, onDismiss, reference }) => {
  const [isBlocking, setBlocking] = React.useState(false);

  const block = async () => {
    setBlocking(true);
    try {
      const data = await card.terminate({ ref: reference });

      if (data?.success) {
        await onDismiss();
      }
    } finally {
      setBlocking(false);
    }
  };

  return (
    <Modal id={'blockCard'} title={'Block card'} visible={visible} onDismiss={onDismiss}>
      <div style={{ textAlign: 'center' }}>
        <p>Are you sure you want to block this card?</p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <div>
          <Button
            primary
            $loading={isBlocking}
            onClick={() => block()}
            disabled={isBlocking}
          >
            Block
          </Button>
        </div>
        <div>
          <Button secondary>Cancel</Button>
        </div>
      </div>
    </Modal>
  );
};

export default Block;
