import * as React from 'react';

import { Button, Horizon, Modal, TextInput } from '../../../../../armoury';
import Container, { FooterContainer } from './styles';
import { BREAKPOINTS } from '../../../../../constants';
import { objectWithoutKeyword } from '../../../../../helpers';
import { ticket } from '../../../../../api';

const AddTicket = ({ visible, onDismiss, onComplete, eventID }) => {
  const [isProcessing, setProcessing] = React.useState(false);

  const [step, setStep] = React.useState(1);

  const [event, setEvent] = React.useState({
    name: '',
    nameError: false,
    description: '',
    descriptionError: false,
    amount: '',
    amountError: false,
    quantity: '',
    quantityError: false,
  });

  const addTicket = async () => {
    setProcessing(true);
    try {
      const data = await ticket.create({
        ...objectWithoutKeyword(event, 'error'),
        available_quantity: event.quantity,
        amount: event.amount * 100,
        event: Number(eventID),
      });

      if (data?.success) {
        await onDismiss();

        setEvent({
          name: '',
          nameError: false,
          description: '',
          descriptionError: false,
          amount: '',
          amountError: false,
        });
        await onComplete();
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal
      id={'addProduct'}
      visible={visible}
      ariaLabel={'Create ticker'}
      onDismiss={onDismiss}
      title={'Create Ticket'}
      customStyle={{
        maxWidth: BREAKPOINTS.medium,
        width: '100%',
        overflowY: 'auto !important',
      }}
    >
      <Horizon />
      <Container>
        <div>
          <div>
            {step === 1 && (
              <form name="product--form" noValidate>
                <TextInput
                  name={'title'}
                  type={'text'}
                  placeholder={"Event's title"}
                  required
                  label={'Name'}
                  value={event.name}
                  error={event.nameError}
                  onChange={({ target: { value } }) => {
                    setEvent({
                      ...event,
                      name: value,
                      nameError: !value,
                    });
                  }}
                />
                <TextInput
                  name={'description'}
                  type={'text'}
                  placeholder={'Description'}
                  label={'Description'}
                  required
                  textArea
                  value={event.description}
                  error={event.descriptionError}
                  onChange={({ target: { value } }) => {
                    setEvent({
                      ...event,
                      description: value,
                      descriptionError: !value,
                    });
                  }}
                />
                <TextInput
                  name={'amount'}
                  type={'number'}
                  placeholder={'1,000'}
                  required
                  label={'Amount'}
                  value={event.amount}
                  error={event.amountError}
                  onChange={({ target: { value } }) => {
                    setEvent({
                      ...event,
                      amount: value,
                      amountError: !value || value < 100,
                    });
                  }}
                />
                <TextInput
                  name={'available_quantity'}
                  type={'number'}
                  placeholder={'10'}
                  required
                  label={'Quantity available'}
                  value={event.quantity}
                  error={event.quantityError}
                  onChange={({ target: { value } }) => {
                    setEvent({
                      ...event,
                      quantity: value,
                      quantityError: !value || value < 1,
                    });
                  }}
                />
              </form>
            )}
          </div>
          <FooterContainer className="mobile">
            <span role={'button'} />
            <div>
              {!isProcessing && step !== 1 && (
                <Button
                  secondary
                  disabled={isProcessing}
                  $loading={isProcessing}
                  onClick={() => {
                    setStep(1);
                  }}
                >
                  Back
                </Button>
              )}
              <Button
                primary
                aria-label={'submit ticket details form'}
                onClick={() => addTicket()}
                disabled={isProcessing}
                $loading={isProcessing}
              >
                {step === 1 ? 'Continue' : 'Create ticket'}
              </Button>
            </div>
          </FooterContainer>
        </div>
      </Container>
      <FooterContainer>
        <span role={'button'} />
        <div>
          {!isProcessing && step !== 1 && (
            <Button
              secondary
              disabled={isProcessing}
              $loading={isProcessing}
              onClick={() => {
                setStep(1);
              }}
            >
              Back
            </Button>
          )}
          <Button
            primary
            aria-label={'submit product details form'}
            onClick={() => addTicket()}
            disabled={isProcessing}
            $loading={isProcessing}
          >
            Next
          </Button>
        </div>
      </FooterContainer>
    </Modal>
  );
};

export default AddTicket;
