const formatAmount = (
  amount,
  currency = {
    locales: document.documentElement.lang || 'en-NG',
    default: 'NGN',
  },
) => {
  return new Intl.NumberFormat(currency.locales, {
    style: 'currency',
    currency: currency.default,
  }).format(amount);
};

export default formatAmount;
