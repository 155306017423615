import * as React from 'react';

import Container from './styles';
import { Modal, TextInput, Button } from '../../../../../../armoury';

const Buy = ({ visible, onDismiss }) => {
  return (
    <Modal
      id={'buyCrypto'}
      title={'Buy crypto'}
      visible={visible}
      onDismiss={onDismiss}
      ariaLabel={'buy crypto'}
    >
      <Container>
        <span className={'helper-text'}>
          Please enter the amount of BTC in NGN you want to buy.{' '}
          <span className={'important'}>₦500 minimum</span>
        </span>
        <TextInput placeholder={'₦0.00'} />
        <div className="rates-container">
          <div className="row">
            <span>Our Rate</span>
            <span>₦4,563,673.13/BTC</span>
          </div>
          <div className="row">
            <span>Equivalent</span>
            <span>0.0457BTC</span>
          </div>
        </div>
      </Container>
      <Button primary wide>
        Buy
      </Button>
    </Modal>
  );
};

export default Buy;
