import styled from 'styled-components';

import { query } from '../../helpers';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors['base-200']};
  height: 100vh;
  display: flex;

  aside {
    min-height: 100vh;
    background-color: ${({ theme }) => theme.colors.base};
    width: 260px;
    box-shadow: 0 0 5px ${({ theme }) => theme.colors.shadow};
    -webkit-transition: 100ms ease-in-out;
    -moz-transition: 100ms ease-in-out;
    -o-transition: 100ms ease-in-out;
    transition: 100ms ease-in-out;

    ${query.maxWidth('>large1')`
      width: 0;
      opacity: 0;
    `}
    
    &.open {
      position: absolute;
      width: 260px !important;
      opacity: 1;
      z-index: 991;
      top: 0;
      min-height: calc(100vh + 60px);
    }

    div.fx-user-avatar__container {
      height: 160px;
      background-color: ${({ theme }) => theme.colors.base};
      display: flex;
      flex-direction: column;
      padding: 24px 0;
      align-items: center;
      border-bottom: 1px solid ${({ theme }) => theme.colors.shadow};

      a {
        height: 60px;
        width: 60px;

        img {
          height: inherit;
          width: inherit;
        }

        margin-bottom: 16px;
      }

      div.fx-user__details {
        text-align: center;
        line-height: 1.4em;

        span,
        div {
          color: ${({ theme }) => theme.colors['primary-text']};
          font-size: 1rem;
        }

        span {
          font-weight: 300;
        }

        div {
          font-weight: 700;
        }
      }
    }

    nav.fx-nav--container {
      background-color: ${({ theme }) => theme.colors.base};
      padding-top: 14px;
      padding-left: 12px;

      ul {
        li {
          svg {
            width: 20px;
          }

          &.fx--send-money {
            svg {
              transform: rotate(45deg);
            }
          }

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          a,
          div {
            height: 45px;
            display: flex;
            align-items: center;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding-left: 12px;
            color: ${({ theme }) => theme.colors['primary-text']};
            font-size: 0.8rem;

            svg {
              font-size: 1.2rem;
              margin-right: 8px;
            }

            &:not(.active) {
              opacity: 0.5;
            }

            &.active,
            &:hover {
              background-color: ${({ theme }) => theme.colors['brand-light']};
              color: ${({ theme }) => theme.colors.primary};
              outline-color: transparent;
            }

            transition: 300ms ease-in-out background-color;
          }

          span.badge {
            padding: 0.2rem 0.6rem;
            background-color: red;
            color: ${({ theme }) => theme.colors.base};
            border-radius: 1rem;
            margin-left: 0.2rem;
            text-transform: capitalize;
          }
        }
      }
    }

    + div.fx-user-dashboard__content {
      width: calc(100vw - 260px);
      display: flex;
      flex-direction: column;
      -webkit-transition: 100ms ease-in-out;
      -moz-transition: 100ms ease-in-out;
      -o-transition: 100ms ease-in-out;
      transition: 100ms ease-in-out;

      ${query.maxWidth('>large1')`
        width: 100%;
      `};
    }

    div.fx-nav--header {
      height: 70px;
      padding: 4px 12px;
      display: flex;
      align-items: center;
      flex-direction: row;
      border-bottom: 1px solid #e1e1e1;

      img.fx-logo {
        height: 30px;
        width: 30px;
        border-radius: 50%;
      }

      div.fx-vr {
        height: 60%;
        background-color: #e1e1e1;
        width: 1px;
        margin: 0 10px;
        align-self: center;
      }

      > span {
        color: ${({ theme }) => theme.colors['primary-text']};
        margin-left: 12px;
        font-weight: 300;

        span {
          font-weight: 600;
        }
      }
    }
  }

  div.fx-topup-modal-container {
    margin: 24px 0;
  }
`;

export const Nav = styled.nav`
  background-color: ${({ theme }) => theme.colors.base};
  box-shadow: 0 0 5px ${({ theme }) => theme.colors.shadow};
  height: 60px;
  align-items: center;
  padding: 1rem 1.2rem;
  width: 100%;
  display: none;
  ${query.maxWidth('>large1')`
    display: flex;      
  `};

  div#mobile-nav {
    width: 24px;
    height: 24px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 500ms ease-in-out;
    -moz-transition: 500ms ease-in-out;
    -o-transition: 500ms ease-in-out;
    transition: 500ms ease-in-out;

    span {
      display: block;
      position: absolute;
      height: 0.18rem;
      width: 100%;
      background: ${({ theme }) => theme.colors.primary};
      border-radius: 9px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 250ms ease-in-out;
      -moz-transition: 250ms ease-in-out;
      -o-transition: 250ms ease-in-out;
      transition: 250ms ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 10px;
      }

      &:nth-child(3) {
        top: 20px;
      }
    }

    &.open span {
      &:nth-child(1) {
        top: 18px;
        -webkit-transform: rotate(135deg);
        -moz-transform: rotate(135deg);
        -o-transform: rotate(135deg);
        transform: rotate(135deg);
      }

      &:nth-child(2) {
        opacity: 0;
        left: -60px;
      }

      &:nth-child(3) {
        top: 18px;
        -webkit-transform: rotate(-135deg);
        -moz-transform: rotate(-135deg);
        -o-transform: rotate(-135deg);
        transform: rotate(-135deg);
      }
    }
  }
`;

export default Container;
