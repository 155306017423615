import { default as React } from 'react';

import Container from './style';

const DrawerItem = ({ label, active, onClick, type, children, itemStyle }) => {
  return (
    <Container
      active={active}
      onClick={e => onClick && onClick(e)}
      role={'radio'}
      aria-checked={active}
      type={type}
      style={itemStyle}
    >
      <div className={'fx-radio'}>
        <input
          type="radio"
          checked={active}
          readOnly
          role={'none'}
          tabIndex={active ? -1 : 0}
        />
      </div>
      <span>{label}</span>
      {children}
    </Container>
  );
};

export default DrawerItem;
