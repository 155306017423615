import * as React from 'react';

import Container from './styles';

const Option = ({
  children = 'Date',
  active = false,
  label = 'Just a button component',
  onClick,
  disabled,
}) => {
  return (
    <Container
      role={'button'}
      aria-label={label}
      active={active}
      onClick={() => onClick()}
      aria-disabled={disabled}
    >
      {children}
    </Container>
  );
};

export default Option;
