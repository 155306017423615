import styled from 'styled-components';
import { setMaxWidth } from '../../../../helpers';
import { BREAKPOINTS } from '../../../../constants';

const Container = styled.main`
  padding: 16px;
  min-height: 100vh;

  div.fx--header-container {
    background-color: ${({ theme }) => theme.colors.base};
    border-radius: 10px;
    box-shadow: 0 0 2px ${({ theme }) => theme.colors.shadow};

    > div {
      padding: 12px 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    h3.fx-title {
      font-weight: 700;
    }

    div.fx--header-store-options {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      margin-bottom: 12px;

      button {
        font-weight: 400;
        font-size: 0.9rem;

        svg {
          margin-right: 8px;
          font-size: 0.7rem;
        }
      }

      > div:first-child {
        button:first-child {
          margin-right: 20px;
        }
      }
    }
  }

  section {
    padding: 12px 24px;
    position: relative;

    div.form-container {
      ${setMaxWidth(BREAKPOINTS.small)};
      padding: 24px;
      border-radius: 4px;

      form {
        div.action-container {
          display: flex;
          margin-top: 12px;
          flex-direction: row-reverse;
          justify-content: space-between;
        }
      }

      div.file-upload__container {
        padding: 12px 6px;
        border-radius: 4px;
      }
    }
  }
`;

export const TabList = styled.div`
  height: 50px;
  margin-bottom: 2rem;
  border-radius: 4px;
  display: flex;
  flex-direction: row;

  > div:not(:nth-child(2)) {
    height: 2rem;
    width: 2rem;
    background-color: ${({ theme }) => theme.colors.primary};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    align-self: center;
    box-shadow: 0 0 30px #00000029;
    cursor: pointer;

    span {
      color: #ffffff;
    }
  }

  > div:nth-child(2) {
    height: 0.1rem;
    background-color: ${({ theme }) => theme.colors.primary};
    width: 80%;
    align-self: center;
  }
`;

export default Container;
