const propStartsWith = (object, key) => {
  const keys = Object.keys(object);

  if (!!keys.length) {
    return Object.entries(
      keys
        .filter(k => k.startsWith(key) && !!object[k])
        .reduce(
          (acc, val) => ({
            ...acc,
            [val]: object[val],
          }),
          {},
        ),
    );
  }

  return [];
};

export default propStartsWith;
