import { isAndroid, isIOS } from '../ua';

const formatDate = date => {
  return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
    .toISOString()
    .split('T')[0];
};

export const cleanUpDate = (date) => {
  let [d, t] = date.split(',');

  d = d.split('/').join('-');
  t = t.split(' ')[1];

  let DATE = new Date(d),
    month = '' + (DATE.getMonth() + 1),
    day = '' + DATE.getDate(),
    year = DATE.getFullYear();

  if (isAndroid() || isIOS() || Number.isNaN(month) || Number.isNaN(day) || Number.isNaN(year)) {
    let [dd, mm, y] = d.split('-');

    year = y;
    month = mm;
    day = dd;
  }

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;


  return `${[year, month, day].join('-')} ${t}`;
};

export default formatDate;
