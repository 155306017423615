import styled, { css } from 'styled-components';

const Container = styled.div`
  border: 0.12rem solid ${({ theme }) => theme.colors.primary};
  border-radius: 0.3rem;
  display: inline-flex;
  height: 2.5rem;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  background-color: #f9f6fb;
  color: ${({ theme }) => theme.colors.primary};

  ${({ active, theme }) =>
    active &&
    css`
      background-color: ${theme.colors.primary};
      color: ${theme.colors.base};
    `}
  
  &:not(:last-child) {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }
`;

export default Container;
