import { errorHandler, server } from '../../lib';

const fetchBasicDetails = async () => {
  try {
    const { status, data: response } = await server.get('/auth/user/');

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const fetchRecentTransactions = async () => {
  try {
    const { status, data: response } = await server.get('/users/recent/');

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const fetchBankAccount = async () => {
  try {
    const { status, data: response } = await server.get('/users/bank_account/');

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const account = {
  fetchBasicDetails,
  fetchRecentTransactions,
  fetchBankAccount,
};

export default account;
