import styled from 'styled-components';

const Container = styled.main`
  padding: 16px;
  min-height: 100vh;

  div.fx--empty-card_container {
    padding: 12px 24px;
    background-color: ${({ theme }) => theme.colors.base};
    border-radius: 10px;
    box-shadow: 0 0 2px ${({ theme }) => theme.colors.shadow};

    h3.fx-title {
      font-weight: 700;
    }

    div.fx--cards_empty {
      max-width: 350px;
      width: 100%;

      h2 {
        color: #855aaf;
        font-style: italic;
        font-size: 1.4rem;

        span {
          color: #fc6190;
          font-size: 40px;
        }
      }

      p {
        color: rgba(174, 174, 174, 1);
        margin-bottom: 24px;
      }

      button {
        font-weight: 300;
        display: flex;
        margin-bottom: 12px;

        svg {
          font-size: 0.5rem;
          margin-right: 4px;
          align-self: center;
        }
      }
    }
  }
`;

export const DialogContent = styled.div`
  margin-top: 24px;
  border: 1px solid #22222233;
  border-radius: 8px;
  padding: 12px 20px;

  div.fx-card--form-container {
    padding: 12px 0;

    div.row {
      display: flex;
      justify-content: space-between;
      margin: 12px 0;

      span {
        color: rgba(34, 34, 34, 0.6);
      }
    }

    button {
      margin-top: 40px;
      text-align: center;
    }
  }

  span.error-text {
    color: ${({ theme }) => theme.colors.danger.base};
    display: block;
    margin-top: -10px;
    margin-bottom: 20px;
  }
`;

export default Container;
