import * as React from 'react';

import Container from './styles';
import { Modal, TextInput, Button } from '../../../../../../armoury';

const Sell = ({ visible, onDismiss }) => {
  return (
    <Modal
      id={'sellCrypto'}
      title={'Sell crypto'}
      visible={visible}
      onDismiss={onDismiss}
      ariaLabel={'sell crypto'}
    >
      <Container>
        <div className="rates-container">
          <div className="row">
            <span style={{ color: 'rgba(34, 34, 34, 1)' }}>
              You have <span style={{ fontWeight: 'bold' }}>2,476BTC</span> to
              sell
            </span>
          </div>
          <div className="row">
            <span>Selling Rate</span>
            <span>@₦4,354,955.23/BTC</span>
          </div>
        </div>
        <div className={'form-row'}>
          <span className={'helper-text'}>Set minimum buyable amount</span>
          <TextInput placeholder={'₦0.00'} />
        </div>
        <div className={'form-row'}>
          <span className={'helper-text'}>Set maximum buyable amount</span>
          <TextInput placeholder={'₦0.00'} />
        </div>
      </Container>
      <Button primary wide>
        Sell
      </Button>
    </Modal>
  );
};

export default Sell;
