import toast from 'react-hot-toast';
import { errorHandler, server } from '../../lib';

const addAddress = async ({ area, amount }) => {
  try {
    const { status, data: response } = await server.post(
      '/business/delivery/',
      {
        area,
        amount: amount * 100,
      },
    );

    if (status === 201) {
      toast.success('Successfully added address');
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const getAddresses = async () => {
  try {
    const { status, data: response } = await server.get('/business/delivery/');

    if (status === 200) {
      return {
        success: true,
        response: response.results,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const deleteAddress = async ({ id }) => {
  try {
    const { status, data: response } = await server.delete(
      `/business/delivery/${id}/`,
    );

    if (status === 204) {
      toast.success('Successfully deleted address');

      return {
        response,
        success: true,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const filter = async ({ delivery_info }) => {
  try {
    const { status, data: response } = await server.get(
      `/catalogs/orders/?delivery_info=${delivery_info}`,
    );

    if (status === 200) {
      return {
        success: true,
        response: response.results,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const delivery = {
  addAddress,
  getAddresses,
  deleteAddress,
  filter,
};

export default delivery;
