import * as React from 'react';

import Card from './Card';
import Container from './styles';

const Crypto = () => {
  const cryptos = [
    'bitcoin',
    'ethereum',
    'tron',
    'dash',
    'usdt',
    'litecoin',
  ];

  return (
    <Container>
      {cryptos.map(crypto => (
        <Card name={crypto} key={JSON.stringify(crypto)} />
      ))}
    </Container>
  );
};

export default Crypto;
