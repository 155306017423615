import styled from 'styled-components';
import { query } from '../../../../../helpers';

const Container = styled.div`
  display: flex;
  flex-direction: row;

  fieldset {
    border: 0;
    outline: 0;
    padding: 0;
    margin: 1rem 0;

    legend {
      margin-bottom: 0.5rem;
    }
  }

  div.react-datepicker-wrapper {
    width: 100%;

    div.react-datepicker__input-container {
      > input {
        border: 1.8px solid #00000029;
        width: 100% !important;
        padding: 12px 8px;
      }
    }
  }

  > div {
    margin-top: -7px;

    &:first-child {
      flex: 0.4;
      border-right: 1.5px solid #00000029;

      ${query.maxWidth('medium')`
        display: none;
      `}
    }

    &:nth-child(2) {
      flex: 0.6;
      padding: 32px 36px;
      max-height: 70vh;
      overflow-y: auto;

      ${query.maxWidth('medium')`
        flex: 1;
      `}
      > div {
        overflow-y: auto;
      }
    }
  }

  .multiselect {
    max-width: 345px;
  }

  div.step-container {
    padding: 2rem 1rem;
    display: flex;
    flex-direction: column;
    width: 20px;
    align-items: center;

    div[role='tab'] {
      height: 20px;
      width: 20px;
      border: 2px solid ${({ theme }) => theme.colors.primary};
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;

      span {
        position: absolute;
        top: 2px;
        left: 30px;
        text-wrap: none;
        color: rgba(34, 34, 34, 0.7);
        font-weight: 400;
        font-size: 0.8rem;
        white-space: nowrap;
      }

      div {
        height: 10px;
        width: 10px;
        border-radius: 50%;
        transition: 200ms ease-in-out background-color,
        300ms ease-in-out opacity;
        opacity: 0;
      }

      &[aria-selected='true'] {
        div {
          background-color: ${({ theme }) => theme.colors.primary};
          opacity: 1;
        }
      }
    }

    div.separator {
      width: 2px;
      height: 60px;
      background-color: ${({ theme }) => theme.colors.primary};
      align-self: center;
    }
  }
`;

export const FooterContainer = styled.div`
  border-top: 1.5px solid #00000029;
  padding: 24px 0 8px 0;
  display: flex;
  justify-content: space-between;

  span[role='button'] {
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 400;
    font-size: 0.9rem;
    margin-top: 12px;
  }

  > div {
    button {
      &:first-child {
        margin-right: 24px;
      }
    }
  }

  ${query.minWidth('>medium')`
    &.mobile {
      display: none;
    }
  `}

  ${query.maxWidth('medium')`
     &.mobile {
      border-top: none;
      padding: 24px 0 0 0;
     }
     
     &:not(.mobile) {
      display: none
     }
     
     > div {
      button {
         &:first-child {
         margin-right: 0;
     }
  `};
`;

export const FileUploadContainer = styled.div`
  height: 150px;
  border: 2px dashed ${({ theme }) => theme.colors.primary};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > div {
    text-align: center;

    label {
      color: rgba(34, 34, 34, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      svg {
        color: ${({ theme }) => theme.colors.primary};
        font-size: 1.4rem;
        margin-bottom: 12px;
      }
    }
  }
`;

export const UploadedImages = styled.div`
  margin-top: 12px;
  display: flex;

  div {
    position: relative;
    height: 60px;
    width: 60px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 6px;

    > div {
      height: inherit;
      width: inherit;
      border-radius: 6px;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }

    img {
      height: inherit;
      width: inherit;
    }

    svg {
      position: absolute;
      top: -5px;
      right: -8px;
      background-color: ${({ theme }) => theme.colors.base};
      border: 2px solid ${({ theme }) => theme.colors.base};
      border-radius: 50%;
      font-size: 1.2rem;
      cursor: pointer;
    }
  }

  &:not(:last-child) {
    margin-right: 2.5rem;
  }
`;

export default Container;
