import { default as styled, css, keyframes } from 'styled-components';

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export default styled.button`
  padding: 8px 20px;
  border-radius: 30px;
  border: 0;
  font-weight: bold;
  font-size: 0.92rem;
  position: relative;
  transition: background-color 100ms, color 100ms, border 100ms;

  &:disabled {
    cursor: not-allowed;
  }

  ${({ wide }) =>
    wide &&
    css`
      display: block;
      width: 100%;
      padding: 12px 20px;
    `};

  ${({ primary, theme }) =>
    primary &&
    css`
      &:not(:disabled) {
        background-color: ${theme.colors.primary};
        border: 2px solid ${theme.colors.primary};
        color: ${theme.colors.base};

        &:hover {
          background-color: ${theme.colors.base};
          border: 2px solid ${theme.colors.primary};
          color: ${theme.colors.primary};
        }
      }

      &:disabled {
        background-color: ${theme.colors.disabled};
        border: 2px solid ${theme.colors.disabled};
        color: ${theme.colors.base};
      }
    `};

  ${({ secondary, theme }) =>
    secondary &&
    css`
      &:not(:disabled) {
        background-color: ${theme.colors.base};
        border: 2px solid ${theme.colors.primary};
        color: ${theme.colors.primary};

        &:hover {
          background-color: ${theme.colors.primary};
          border: 2px solid ${theme.colors.primary};
          color: ${theme.colors.base};
        }
      }

      &:disabled {
        background-color: ${theme.colors.base};
        border: 2px solid ${theme.colors.disabled};
        color: ${theme.colors.disabled};
      }
    `};

  ${({ tertiary, theme }) =>
    tertiary &&
    css`
      &:not(:disabled) {
        background-color: transparent;
        border: 0;
        color: ${theme.colors.primary};

        &:after {
          content: '';
          display: block;
          height: 2px;
          width: 100%;
          transition: background-color 300ms;
        }

        &:hover {
          &:after {
            background-color: ${theme.colors.primary};
          }
        }

        &:active,
        &:focus {
          background: transparent;
          outline-color: transparent;
        }
      }

      &:disabled {
        background-color: transparent;
        color: ${theme.colors.disabled};
      }
    `};

  &:active,
  &:focus {
    outline-color: transparent;
    opacity: 0.8;
  }

  ${({ $loading, disabled, theme, primary }) =>
    $loading &&
    disabled &&
    css`
      display: flex;
      justify-content: center;

      div.spinner {
        height: 15px;
        width: 15px;
        border: 2px solid transparent;
        border-left-color: ${primary
          ? theme.colors.base
          : theme.colors.disabled};
        border-top-color: ${primary
          ? theme.colors.base
          : theme.colors.disabled};
        border-radius: 50%;
        margin-right: 0.6em;
        align-self: center;
        animation: ${rotate} 500ms infinite;
      }
    `};
`;
