import { default as React } from 'react';

import Container from './styles';

const Button = ({
  children,
  primary = true,
  secondary = false,
  tertiary = false,
  disabled,
  $loading = false,
  wide,
  onClick,
  style,
  type = 'submit',
}) => {
  return (
    <Container
      primary={primary}
      secondary={secondary}
      tertiary={tertiary}
      disabled={disabled}
      $loading={$loading}
      wide={wide}
      onClick={e => onClick && onClick(e)}
      style={style}
      type={type}
    >
      {$loading && (
        <div
          className={'spinner'}
          role={'progressbar'}
          aria-valuetext={'processing'}
        />
      )}
      {children}
    </Container>
  );
};

export default Button;
