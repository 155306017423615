import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import Container from './styles';
import { Button, Modal, Spinner } from '../../../../armoury';

import AddTicket from '../../../../components/Dashboard/Store/Ticket/Add';
import EditTicket from '../../../../components/Dashboard/Store/Ticket/Edit';
import { store, ticket } from '../../../../api';
import { copy, slugify } from '../../../../helpers';
import TicketItem from '../../../../components/Dashboard/Store/Ticket/TicketItem';

const Tickets = () => {
  const user = useSelector(({ user }) => user);
  const { event_id } = useParams();

  const { business } = user;

  const isBusinessAdmin = business?.admin;

  const [productModal, setAddProduct] = React.useState(false);
  const [editProductModal, setEditModal] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState('products');
  const [isProcessing, setProcessing] = React.useState(false);

  const [catalogs, setCatalogs] = React.useState([]);

  const [details, setDetails] = React.useState('');

  const [, setConfirmDelete] = React.useState(false);
  const [deleting, setDeleting] = React.useState(false);
  const [deleteModal, setDeleteModal] = React.useState(false);

  React.useEffect(() => {
    (async () => await fetchTickets())();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const deleteCatalog = async () => {
    setDeleting(true);
    try {
      const data = await store.deleteCatalog({ ref: details?.ref });

      if (data?.success) {
        await fetchTickets();
        await setDeleteModal(false);
      }
    } finally {
      setDeleting(false);
    }
  };

  const fetchTickets = async () => {
    setProcessing(true);
    try {
      const { success, response } = await ticket.fetch({
        event_id,
      });

      if (success) {
        setCatalogs(response);
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Container isProcessing={isProcessing}>
        <div className="fx--header-container">
          <div className={'fx--header-container-child'}>
            <h2 className={'fx--header'}>
              Event Tickets<span>.</span>
            </h2>
          </div>
          {isBusinessAdmin && (
            <div className={'fx--header-tablist'}>
              <ul role={'tablist'} aria-label={'flux-store account options'}>
                {/*<li*/}
                {/*  role={'tab'}*/}
                {/*  aria-selected={activeTab === 'products'}*/}
                {/*  aria-controls={'controlled-box'}*/}
                {/*  // className={activeTab === 'products' ? 'active' : ''}*/}
                {/*  onClick={() => setActiveTab('products')}*/}
                {/*>*/}
                {/*  Tickets*/}
                {/*</li>*/}
              </ul>
            </div>
          )}
        </div>
        {activeTab === 'products' && (
          <div className="fx--product-list-container" id={'controlled-box'}>
            <div className={'fx--header-store-options'}>
              <div>
                <Button primary onClick={() => setAddProduct(true)}>
                  <FontAwesomeIcon icon={'plus'} />
                  Add Ticket
                </Button>
                <Button
                  secondary
                  onClick={() =>
                    copy(
                      new URL(
                        `https://iflux.store/store/${slugify(
                          user?.business.name,
                        )}/`,
                      ),
                    )
                  }
                >
                  Copy Store Link
                </Button>
              </div>
            </div>
            <div className="fx--product-list-box">
              {isProcessing ? (
                <Spinner />
              ) : !!catalogs.length ? (
                catalogs.map(catalog => (
                  <TicketItem
                    key={JSON.stringify(catalog)}
                    name={catalog.name}
                    price={catalog.amount}
                    image={catalog.event_info.image_url_1}
                    data={catalog}
                    onEdit={async () => {
                      await setDetails(() => catalog);
                      await setAddProduct(false);
                      await setEditModal(true);
                    }}
                    onDelete={async () => {
                      await setDetails(() => catalog);
                      await setDeleteModal(true);
                    }}
                  />
                ))
              ) : null}
            </div>
          </div>
        )}
      </Container>
      <AddTicket
        visible={productModal}
        eventID={event_id}
        onDismiss={() => setAddProduct(false)}
        onComplete={async () => await fetchTickets()}
        onDeliveryOption={() => {
          setAddProduct(false);
          setActiveTab('delivery-info');
        }}
      />
      <EditTicket
        visible={editProductModal}
        onDismiss={() => {
          setDetails('');
          setEditModal(false);
        }}
        data={details}
        onComplete={async () => await fetchTickets()}
      />
      <Modal
        id={'deleteStoreMember'}
        visible={deleteModal}
        onDismiss={() => {
          setDetails('');
          setDeleteModal(false);
        }}
      >
        <h5>Are you sure you want to delete this catalog?</h5>
        <div
          style={{
            marginTop: '1.4rem',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button
            secondary
            style={{ flex: 0.48 }}
            onClick={() => setConfirmDelete(false)}
            disabled={deleting}
          >
            No
          </Button>
          <Button
            style={{ flex: 0.48 }}
            onClick={() => deleteCatalog()}
            disabled={deleting}
            $loading={deleting}
          >
            Yes
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default Tickets;
