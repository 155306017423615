import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container from './styles';

const BreadCrumb = ({ paths = [] }) => {
  if (!Array.isArray(paths)) {
    throw TypeError(`Type Array expected, got ${typeof paths}`);
  }

  const lastItem = React.useMemo(() => paths[paths.length - 1], [paths]);

  return (
    <Container>
      {paths.map(path => (
        <React.Fragment key={JSON.stringify(path)}>
          <span
            className={'fx-breadcrumb-title'}
            style={{ opacity: path === lastItem ? 1 : 0.5 }}
          >
            {path}
          </span>
          <span>
            {path !== lastItem && <FontAwesomeIcon icon={'chevron-right'} />}
          </span>
        </React.Fragment>
      ))}
    </Container>
  );
};

export default BreadCrumb;
