import * as React from 'react';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useTheme } from 'styled-components';
import { Spinner } from '../index';

import Container from './style';

const Table = ({
                 columns = [
                   {
                     label: 'Name',
                     value: 'name',
                   },
                   {
                     label: 'Name',
                     value: 'name',
                   },
                   {
                     label: 'Name',
                     value: 'name',
                   },
                   {
                     label: 'Name',
                     value: 'name',
                   },
                   {
                     label: 'Name',
                     value: 'name',
                   },
                   {
                     label: 'Name',
                     value: 'name',
                   },
                 ],
                 data = [],
                 onRowClick,
                 isFetching = false,
                 title, count,
               }) => {
  // const { colors } = useTheme();

  const [tableData, setTableData] = React.useState([]);

  React.useEffect(() => {
    if (!!columns.length && !!data.length) {
      setTableData(() => data);
    }
  }, [columns, data]);

  return (
    <Container $loading={isFetching}>
      <div className={'fx-table--container'}>
        {isFetching ? (
          <div aria-live={'polite'} className={'table-loader'}>
            <Spinner />
          </div>
        ) : !!data.length ? (
          <table aria-label={`${title} table`}>
            <thead>
            <tr>
              {columns.map(({ label, key }) => (
                <th key={`${key}${Math.random() * 100}`}>{label}</th>
              ))}
            </tr>
            </thead>
            {!!tableData.length && (
              <tbody>
              {tableData.map((data, index) => (
                <tr
                  className={!!onRowClick ? 'change-background-hover' : ''}
                  onClick={!!onRowClick ? () => onRowClick(data) : null}
                  key={index}
                >
                  {columns.map((column, index) => (
                    <td key={index}>
                      {typeof column?.render === 'function'
                        ? column?.render(data[column?.key], data)
                        : data[column?.key]}
                    </td>
                  ))}
                </tr>
              ))}
              </tbody>
            )}
          </table>
        ) : (
          <div
            aria-label={'there is no item in this table'}
            className={'no-item'}
          >
            <span>Oops! there are no {title} at this time</span>
          </div>
        )}
      </div>
      <div className={'fx-table--pagination-tab'}>
        <nav role={'navigation'} aria-label={'table pagination'}>
          <ul>
            {/*<li*/}
            {/*  role={'button'}*/}
            {/*  aria-label={'go back'}*/}
            {/*  className={'fx-table-nav back'}*/}
            {/*  aria-disabled={isFetching || false}*/}
            {/*>*/}
            {/*  /!*<FontAwesomeIcon icon={'chevron-left'} color={colors.primary} />*!/*/}
            {/*</li>*/}
            {!!count && <li aria-label={count} className={'fx-table-pagecount'}>
              Count: {count}
            </li>}
            {/*<li*/}
            {/*  role={'button'}*/}
            {/*  aria-label={'go forward'}*/}
            {/*  className={`fx-table-nav forward ${'disabled'}`}*/}
            {/*  aria-disabled={isFetching}*/}
            {/*>*/}
            {/*  /!*<FontAwesomeIcon icon={'chevron-right'} color={colors.primary} />*!/*/}
            {/*</li>*/}
          </ul>
        </nav>
      </div>
    </Container>
  );
};
export default Table;
