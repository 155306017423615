import { default as styled, css } from 'styled-components';

const Container = styled.span`
  padding: 4px 6px;
  max-width: 5rem;
  width: 100%;
  border-radius: 2px;
  display: block;
  text-align: center;

  ${({ type }) =>
    type === 'success' &&
    css`
      background-color: #e2ffec;
      color: #47c479;
    `};

  ${({ type }) =>
    type === 'failed' &&
    css`
      background-color: #ffe9e9;
      color: #ff5e5e;
    `};

  ${({ type }) =>
    type === 'pending' &&
    css`
      background-color: #fffbc5;
      color: #ffcc00;
    `}
`;

export default Container;
