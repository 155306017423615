import { css, default as styled } from 'styled-components';
import { setMaxWidth } from '../../helpers';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.gradient};
  padding: 8px 12px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  main.bordered-backdrop {
    height: inherit;
    background-color: ${({ theme }) => theme.colors.base};
    ${setMaxWidth(800)};
    padding: 24px;
    border-radius: 12px;
    box-shadow: 0 0 3px #00000029;
    overflow: hidden;
  }

  ${({ $loading }) =>
    $loading &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `};
`;

export default Container;
