import styled from 'styled-components';

const MAX_HEIGHT = '400px';

const Container = styled.div`
  position: relative;

  div.fx-table--container {
    max-height: ${MAX_HEIGHT};
    overflow: auto;
    flex-grow: 1;
  }

  table {
    border-collapse: collapse;
    position: relative;

    thead {
      width: 100%;

      th {
        position: sticky;
        background-color: ${({ theme }) => theme.colors.base};
        top: 0;
        width: 400px;
      }
    }

    tbody {
      tr {
        &:not(:last-child) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        }
      }
    }

    tr {
      &.change-background-hover {
        &:hover {
          opacity: 0.7;
          cursor: pointer;
        }
      }

      th,
      td {
        text-align: left;
        padding: 0 24px;
        width: 320px;
        height: 50px;
      }

      td {
        color: ${({ theme }) => theme.colors['primary-text']};
        font-size: 0.7rem;
      }

      &:first-child {
        th {
          color: rgba(34, 34, 34, 0.5);
          border-bottom: 1px solid rgba(0, 0, 0, 0.16);
        }
      }

      &:not(:first-child) {
        position: relative;
      }
    }
  }

  div.fx-table--pagination-tab {
    height: 60px;
    width: 100%;
    border-radius: 0 0 10px 10px;
    box-shadow: 0 0 3px #00000029;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    ul {
      display: flex;
    }

    li {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    li.fx-table-nav {
      height: 30px;
      width: 30px;
      border-radius: 2px;

      &.back,
      &.forward {
        background-color: ${({ theme }) => theme.colors['brand-light']};
      }

      &.back {
        margin-right: 4px;
      }

      &.forward {
        margin-left: 4px;
      }

      &:not([aria-disabled='false']) {
        cursor: not-allowed;
        opacity: 0.4;
      }
    }

    li.fx-table-pagecount {
      width: auto;
      padding: 0 8px;
      border-radius: 3px;
      border: 1.999px solid #aeaeae;
      color: #aeaeae;
      user-select: none;
    }
  }

  div.table-loader {
    height: ${MAX_HEIGHT};
    background-color: rgba(255, 255, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  div.no-item {
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgba(0, 0, 0, 0.16);
    text-transform: lowercase;
    
    span {
      &:first-letter {
        text-transform: uppercase;
      }
    }
  }
`;

export default Container;
