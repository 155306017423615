import { default as styled, keyframes } from 'styled-components';

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  div.spinner {
    height: 40px;
    width: 40px;
    border: 2px solid transparent;
    border-left-color: ${({ theme, color }) => color || theme.colors.primary};
    border-top-color: ${({ theme, color }) => color || theme.colors.primary};
    border-radius: 50%;
    align-self: center;
    animation: ${rotate} 500ms infinite;
  }
`;

export default Container;
