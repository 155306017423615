import { css, default as styled } from 'styled-components';

import { borderThickness } from '../../constants';
import { toPx } from '../../helpers';

const Container = styled.div`
  label {
    display: block;
  }

  box-sizing: border-box;

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: ${({ theme }) => theme.colors.base};
    margin-top: 8px;
    display: block;
    width: 100%;
    border-radius: 4px;
    border: ${toPx(borderThickness)} solid #00000029;
    padding: 12px 8px;
    font-size: 0.8rem;
    outline-color: transparent;
    color: #aeaeae;

    ${({ error, theme }) =>
      error &&
      css`
        border-width: 2px;
        border-color: ${theme.colors.danger.base};
      `};

    ::placeholder {
      color: #aeaeae;
      font-size: 0.8rem;
    }
  }

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.6;
      user-select: none;
    `};

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export default Container;
