import * as React from 'react';

import Container from './styles';

const Spinner = ({ customStyle, color }) => {
  return (
    <Container style={{ ...customStyle }} color={color}>
      <div
        className={'spinner'}
        role={'progressbar'}
        aria-valuetext={'processing'}
      />
    </Container>
  );
};

export default Spinner;
