const initialState = {
  data: {},
  isFetching: false,
};

const account = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_BASIC_DETAILS':
      return { ...state, data: action.payload };
    case 'UPDATING_BASIC_DETAILS':
      return { ...state, isFetching: action.payload };
    default:
      return state;
  }
};

export default account;
