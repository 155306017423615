import styled from 'styled-components';

export const Container = styled.main`
  padding: 16px;
  min-height: 100vh;

  div.fx-user--wallet-title__container {
    display: flex;
    justify-content: space-between;

    svg {
      font-size: 16px;
    }
  }

  h2.fx--header {
    color: #855aaf;
    font-style: italic;
    font-size: 1.4rem;

    span {
      color: #fc6190;
      font-size: 40px;
    }
  }

  div.fx-user--wallet__details {
    padding: 12px 24px;
    background-color: ${({ theme }) => theme.colors.base};
    border-radius: 10px;
    box-shadow: 0 0 2px ${({ theme }) => theme.colors.shadow};

    h3.fx-title {
      font-weight: 700;
    }

    div.fx-wallet--card__container {
      display: flex;
      overflow-x: auto;

      > div:not(:first-child) {
        margin-left: 24px;
      }
    }

    div.fx-wallet--action__container {
      margin: 12px 0;

      button {
        &:not(:last-child) {
          margin-right: 16px;
        }

        height: 40px;
        padding-left: 32px;
        padding-right: 32px;

        font-size: 0.8rem;
        font-weight: 300;
      }
    }
  }

  div.fx-wallet--transaction-history {
    background-color: ${({ theme }) => theme.colors.base};
    border-radius: 10px;
    box-shadow: 0 0 2px ${({ theme }) => theme.colors.shadow};
    margin-top: 32px;

    > div:first-child {
      padding: 12px 24px;
    }
  }

  div.fx-withdraw--content {
    > span {
      color: ${({ theme }) => theme.colors['primary-text']};
      font-weight: 300;
      font-size: 0.8rem;
      opacity: 0.7;
      margin-bottom: 20px;
    }

    button {
      margin-top: 32px;
    }
  }
`;

export const AccountHolder = styled.div`
  margin-bottom: 12px;

  div.fx-withdraw--modal_account-holder {
    border: 1px solid rgba(174, 174, 174, 0.6);
    max-width: 250px;
    width: 100%;
    margin: auto;
    border-radius: 2px;
    padding: 12px 10px;
    text-align: center;

    span {
      color: #47c479;
    }
  }
`;
export default Container;
