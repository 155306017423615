import { default as React } from 'react';

import Container from '../style';

const DrawerGroup = ({ title, children, customStyle, labelStyle }) => {
  return (
    <Container role={'radiogroup'} aria-label={title}>
      <h5 style={labelStyle}>{title}</h5>
      <div style={customStyle}>{children}</div>
    </Container>
  );
};

export default DrawerGroup;
