import { UPDATE_BASIC_DETAILS, UPDATING_BASIC_DETAILS } from '../types';

import { account } from '../../../api';

const updateDetails = payload => {
  return {
    type: UPDATE_BASIC_DETAILS,
    payload,
  };
};

const fetchingDetails = payload => {
  return {
    type: UPDATING_BASIC_DETAILS,
    payload,
  };
};

export const getBasicDetails = () => async dispatch => {
  await dispatch(fetchingDetails(true));
  try {
    const data = await account.fetchRecentTransactions();

    if (data?.success) {
      await dispatch(updateDetails(data.response));
    }
  } finally {
    await dispatch(fetchingDetails(false));
  }
};

export const clearBasicDetails = () => async dispatch => {
  await dispatch(fetchingDetails(true));
  try {
    await dispatch(updateDetails({}));
  } finally {
    await dispatch(fetchingDetails(false));
  }
};
