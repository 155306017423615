import handleError from '../../lib/errorHandler';
import { server } from '../../lib';
import { toast } from 'react-toastify';

const tickets = {
  async fetch({ event_id }) {
    try {
      const { status, data: response } = await server.get(
        `/tickets/event_tickets/${event_id}/`,
      );

      if (status === 200) {
        return {
          success: true,
          response: response.results,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  },
  async create(body) {
    try {
      const { status, data: response } = await server.post('/tickets/v2/', {
        ...body,
      });

      if (status === 201) {
        return {
          success: true,
          response,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  },
  async mark({ ticket_ref }) {
    try {
      const { status, data: response } = await server.post('/tickets/mark/', {
        ticket_ref,
      });

      if (status === 200 && response.success) {
        toast.success(response.msg);

        return {
          success: true,
          response,
        };
      } else {
        toast.error(response.ticket_ref[0]);
        return {
          success: false,
          response,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  },
};

export default tickets;
