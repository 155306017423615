import { default as React } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container from './styles';
import { formatAmount } from '../../helpers';

const Card = ({
  active,
  type,
  label,
  balance,
  cardTitle,
  onClick,
  onFilterChange,
}) => {
  const [secure, setSecure] = React.useState(true);
  const [activePaymentFilter, setActivePaymentFilter] = React.useState('TODAY');

  return (
    <Container active={active} type={type} onClick={onClick} role={'button'}>
      <div>
        <span className={'fx-id'}>{cardTitle}</span>
      </div>
      <div className={'fx-wallet--balance__container'}>
        <span className={`fx-wallet--balance${secure ? ' secure' : ''}`}>
          {formatAmount(Number(balance / 100) || 0)}
        </span>
        <FontAwesomeIcon
          icon={secure ? 'eye' : 'eye-slash'}
          onClick={() => setSecure(c => !c)}
          color={'#ffffff'}
        />
      </div>
      {type === 'payment' && (
        <div className={'fx-card--sales-options__container'}>
          <div
            role={'button'}
            className={`${activePaymentFilter === 'TODAY' ? 'active' : ''}`}
            onClick={async () => {
              setActivePaymentFilter('TODAY');
              await onFilterChange('TODAY');
            }}
          >
            Today
          </div>
          <div
            role={'button'}
            className={`${activePaymentFilter === 'LAST_7_DAYS' ? 'active' : ''}`}
            onClick={async () => {
              setActivePaymentFilter('LAST_7_DAYS');
              await onFilterChange('LAST_7_DAYS');
            }}
          >
            This week
          </div>
          <div
            role={'button'}
            className={`${activePaymentFilter === 'LAST_30_DAYS' ? 'active' : ''}`}
            onClick={async () => {
              setActivePaymentFilter('LAST_30_DAYS');
              await onFilterChange('LAST_30_DAYS');
            }}
          >
            This month
          </div>
          <div
            role={'button'}
            className={`${activePaymentFilter === 'ALL' ? 'active' : ''}`}
            onClick={async () => {
              setActivePaymentFilter('ALL');
              await onFilterChange('ALL');
            }}
          >
            All time
          </div>
        </div>
      )}
      <div className={'fx-card--label__container'}>
        <span>{label}</span>
      </div>
      {active && <div aria-label={label} className={'fx-card--marker'} />}
    </Container>
  );
};

export default Card;
