import styled from 'styled-components';
import { setMaxWidth } from '../../../../helpers';

const Container = styled.main`
  padding: 16px;
  min-height: 100vh;

  > div {
    background-color: ${({ theme }) => theme.colors.base};
    height: 100%;
    width: 100%;
    box-shadow: 0 0 2px #00000029;
    border-radius: 10px;
    padding: 20px 24px;

    h3 {
      color: ${({ theme }) => theme.colors['primary-text']};
    }

    hr {
      margin: 24px 0;
    }

    form.fx-add-cash-form {
      padding: 12px 4px;
      ${setMaxWidth(400)};
      margin: 0;
    }
  }

  div.fx-account-number-box {
    height: 150px;
    border-radius: 6px;
    margin-top: 12px;
    border: 1px solid ${({ theme }) => theme.colors.primary};
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    margin-bottom: 60px;

    > div {
      display: flex;
      text-align: center;
      flex-direction: column;
    }

    span {
      color: ${({ theme }) => theme.colors['primary-text']};
      opacity: 0.5;
      font-weight: 300;
    }

    p:first-of-type {
      font-size: 1.5rem;
      font-weight: bold;
      margin: 14px 0;
    }

    p:not(:first-of-type) {
      margin: 5px 0;
    }
  }

  div.fx-modal-footer {
  }
`;

export default Container;
