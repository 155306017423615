import toast from 'react-hot-toast';
import Cookies from 'js-cookie';

import { LOGIN, LOGOUT } from '../types';
import { user } from '../../../api';
import { NProgress } from '../../../lib';
import { clearBasicDetails } from '../account';

export const login = payload => {
  return {
    type: LOGIN,
    payload,
  };
};

export const logOut = () => {
  return {
    type: LOGOUT,
  };
};

export const handleLogin = ({ email, password }) => async dispatch => {
  const data = await user.login({ email, password });

  if (data?.response) {
    await dispatch(login({ ...data.response }));

    return {
      success: true,
    };
  }
};

export const handleRegistration = ({ email, password }) => async dispatch => {
  const data = await user.register({ email, password });

  if (data?.response) {
    await dispatch(login({ ...data.response }));

    return {
      success: true,
    };
  }
};

export const handleBasicProfileUpdate = body => async dispatch => {
  const data = await user.updateBasicProfile({ ...body });

  if (data?.response) {
    await dispatch(login({ ...data.response }));

    return {
      response: data.response,
      success: true,
    };
  }
};

export const handleLogout = () => async dispatch => {
  NProgress.start();

  try {
    await Cookies.remove('red-dawn');
    await dispatch(logOut());

    toast.success('You are logged out');

    return {
      success: true,
    };
  } finally {
    NProgress.done();
  }
};

export const handleBusinessUpdate = ({
  business_id,
  name,
  description,
}) => async () => {
  const data = await user.updateBusinessProfile({
    business_id,
    name,
    description,
  });

  if (data?.response) {
    return {
      success: true,
      response: data.response,
    };
  }
};

export const handleUserRefresh = () => async dispatch => {
  const data = await user.get();

  if (data?.success) {
    await dispatch(clearBasicDetails());
    await dispatch(login({ ...data.response }));
  }
};
