import { errorHandler, server } from '../../lib';
import toast from 'react-hot-toast';

const getBusiness = async ({ id }) => {
  try {
    const { status, data: response } = await server.get(`/business/v2/${id}/`);

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const getBusinessBalance = async ({ id }) => {
  try {
    // const {status, data: response} = await server.get(``)
  } catch ({ response }) {
    errorHandler(response);
  }
};

const updateBusiness = async ({ id, body }) => {
  try {
    const { status, data: response } = await server.put(`/business/v2/${id}/`, {
      ...body,
    });

    if (status === 200) {
      toast.success('Great! Business profile updated successfully');

      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const getDocuments = async () => {
  try {
    const { status, data: response } = await server.get('/users/documents/');

    if (status === 200) {
      return {
        success: true,
        response: response.results,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const updateDocuments = async ({ doc_type, doc }) => {
  try {
    const { status, data: response } = await server.post('/users/documents/', {
      doc,
      doc_type,
    });

    if (status === 201) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const business = {
  get: getBusiness,
  update: updateBusiness,
  getDocuments,
  updateDocuments,
  getBusinessBalance,
};

export default business;
