import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';

import Container from './styles';
import useOutsideClick from '../../hooks/useOutsideClick';

const Modal = ({
  children,
  ariaDescribedBy,
  ariaLabelledBy,
  ariaLabel = '',
  customStyle = {},
  overflow,
  onDismiss = console.log,
  visible,
  title,
  id = '',
}) => {
  const { colors } = useTheme();

  const el = React.useRef(null);
  const modalInstance = React.useRef(null);
  useOutsideClick(modalInstance, onDismiss);

  const [show, setShow] = React.useState(false);
  const [parentFocusableEl, setParentFocusableEl] = React.useState(
    document.querySelector('body'),
  );
  const [firstFocusable, setFirstFocusable] = React.useState({});
  const [lastFocusable, setLastFocusable] = React.useState({});
  const [focusableEls, setFocusableEls] = React.useState([]);

  React.useEffect(() => {
    setShow(visible);
    toggleOverflow();

    if (!visible) {
      close();
    } else {
      // getActiveEl();
      // getFocusableEls();
      focusFirstEl();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // React.useLayoutEffect(() => {
  //   document.addEventListener('keydown', handleKeyDown);
  //
  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [visible]);

  React.useLayoutEffect(() => {
    getActiveEl();
    focusFirstEl();
    focusFirstEl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const focusFirstEl = () => {
    setTimeout(() => {
      if (firstFocusable instanceof HTMLElement) {
        firstFocusable.focus();
      }
    }, 1000);
  };

  const toggleOverflow = () => {
    if (overflow === 'hide') {
      document.body.style.overflow = show ? 'hidden' : 'auto';
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getFocusableEls = () => {
    let listOfFocusableEls = modalInstance.current?.querySelectorAll(
      'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), [tabindex="0"]',
    );

    listOfFocusableEls = [...(listOfFocusableEls || [])];
    const {
      0: firstFocusableEl,
      [focusableEls.length - 1]: lastFocusableEl,
    } = focusableEls;

    setFirstFocusable(firstFocusableEl);
    setLastFocusable(lastFocusableEl);

    setFocusableEls(listOfFocusableEls);
  };

  // eslint-disable-next-line no-unused-vars
  const handleKeyDown = event => {
    const TAB_CODE = 9;
    const ESC_CODE = 27;

    function handleForwardTab() {
      if (document.activeElement.isSameNode(lastFocusable)) {
        event.preventDefault();
        firstFocusable.focus();
      }
    }

    function handleBackwardTab() {
      // console.log('backward');
      if (document.activeElement.isSameNode(firstFocusable)) {
        event.preventDefault();
        lastFocusable.focus();
      }
    }

    if (show) {
      switch (event.keyCode) {
        case TAB_CODE:
          if (!!focusableEls.length && focusableEls.length === 1) {
            event.preventDefault();
            break;
          }

          if (event.shiftKey) {
            handleBackwardTab();
          } else {
            handleForwardTab();
          }
          break;
        case ESC_CODE:
          if (typeof onDismiss === 'function') onDismiss();
          break;
        default:
          break;
      }
    }
  };

  const close = () => {
    setTimeout(() => {
      parentFocusableEl.focus();
    }, 1000);
  };

  const getActiveEl = () => {
    setParentFocusableEl(document.activeElement);
  };

  if (show) {
    return (
      <Container ref={el} id={`${id || ''}Modal`}>
        <div id={'fxModalContainer'}>
          <div
            className="fx-modal"
            role={'dialog'}
            style={{ ...customStyle }}
            aria-modal={true}
            aria-labelledby={ariaLabelledBy}
            aria-describedby={ariaDescribedBy}
            aria-label={ariaLabel}
            aria-hidden={!show}
            id={'fxModal'}
            ref={modalInstance}
          >
            <div className={'fx-modal--header'}>
              <h3>{title}</h3>
              <div
                role={'button'}
                onClick={async () => {
                  await onDismiss();
                  close();
                }}
              >
                <FontAwesomeIcon icon={'times-circle'} color={colors.primary} />
              </div>
            </div>
            {children}
          </div>
          <div className="fx-modal-overlay" id="modal-overlay" />
        </div>
      </Container>
    );
  }

  return null;
};

export default Modal;
