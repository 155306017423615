import * as React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import Container from '../styles';
import { Button, TextInput } from '../../../armoury';
import { isEmail, formValidator } from '../../../helpers';
import { handleLogin } from '../../../store/actions';

const Login = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const mounted = React.useRef(null);

  const [email, setEmail] = React.useState('');
  const [emailError, setEmailError] = React.useState(false);

  const [password, setPassword] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);

  const [isProcessing, setIsProcessing] = React.useState(false);

  React.useEffect(() => {
    mounted.current = true;

    return () => (mounted.current = false);
  }, []);

  const handleEmail = ({ target: { value } }) => {
    setEmail(value);
    setEmailError(!isEmail(value));
  };

  const handlePassword = ({ target: { value } }) => {
    setPassword(value);
    setPasswordError(password.length < 6);
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (
      formValidator(
        document.forms['signIn--form'].getElementsByTagName('input'),
      )
    ) {
      setIsProcessing(true);
      try {
        const response = await dispatch(handleLogin({ email, password }));

        if (response?.success) {
          setIsProcessing(false);
          history.push('/wallets/');
        }
      } finally {
        if (mounted) {
          setIsProcessing(false);
        }
      }
    }
  };

  return (
    <Container>
      <main className={'fx-auth-container'}>
        <div className={'fx-auth-container__image-container'}>
          <img
            src="https://desert-storm.s3-us-west-2.amazonaws.com/assets/flux-logo.png"
            alt="flux logo"
            title={'Flux'}
          />
        </div>
        <h4>Welcome!</h4>
        <span className={'fx-auth-header--details'}>
          Please login to proceed.
        </span>
        <form name={'signIn--form'} onSubmit={handleSubmit} noValidate>
          <TextInput
            placeholder={'john.doe@iflux.app'}
            error={emailError}
            label={'Email Address'}
            required={true}
            name="email"
            type={'email'}
            value={email}
            onChange={handleEmail}
          />
          <TextInput
            placeholder={'**********'}
            error={passwordError}
            label={'Password'}
            required={true}
            name="password"
            type={'password'}
            onChange={handlePassword}
            value={password}
          />
          <Button
            primary
            wide
            $loading={isProcessing}
            disabled={emailError || passwordError || isProcessing}
          >
            Log In
          </Button>
          {/*<Link to={'/forgot-password/'}>Forgot Password?</Link>*/}
        </form>
        <div className="fx-footer--text">
          <p>
            Don't have an account yet?{' '}
            <Link to={'/sign-up/'}>Sign up here!</Link>
          </p>
        </div>
      </main>
    </Container>
  );
};

export default Login;
