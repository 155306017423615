import { combineReducers } from 'redux';

//reducers
import user from './auth';
import account from './account';
import delivery from './delivery';

const reducers = combineReducers({
  user,
  account,
  delivery,
});

export default reducers;
