const initialState = [];

const deliveryInfo = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_DELIVERY_INFO':
      return [...action.payload];
    default:
      return state;
  }
};

export default deliveryInfo;
