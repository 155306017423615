import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';
import S3 from 'react-aws-s3';

import {
  Button,
  DropD,
  Horizon,
  Modal,
  Spinner,
  TextInput,
} from '../../../../../armoury';
import { NProgress } from '../../../../../lib';
import Container, {
  FileUploadContainer,
  FooterContainer,
  UploadedImages,
} from './styles';
import { BREAKPOINTS } from '../../../../../constants';
import { productCategories } from '../../../../../config';
import { store } from '../../../../../api';
import {
  objectWithoutKeyword,
  propStartsWith,
  formValidator,
} from '../../../../../helpers';

const awsConfig = {
  bucketName: process.env.REACT_APP_BUCKET_NAME,
  dirName: 'Catalogs',
  accessKeyId: process.env.REACT_APP_ACCESS_ID,
  secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
  region: process.env.REACT_APP_AWS_REGION,
};

const EditProduct = ({ visible, onDismiss, onComplete, data }) => {
  const input = React.useRef(null);
  const { colors } = useTheme();

  const [images, setImages] = React.useState([
    ...propStartsWith(data, 'image').map(d => d[1]),
  ]);

  const [details, setDetails] = React.useState({});

  const [step, setStep] = React.useState(1);
  const [isProcessing, setProcessing] = React.useState(false);
  const [isFetching] = React.useState(false);

  const [uploading, setUploading] = React.useState(false);

  const [product, setProduct] = React.useState(() => ({
    name: details.name || '',
    nameError: false,
    description: details.description || '',
    descriptionError: false,
    kind: details.kind || '',
    kindError: false,
    category: details.category || '',
    categoryError: false,
    amountError: false,
    amount: details.amount / 100 || '',
  }));

  const updateProduct = async () => {
    setProcessing(true);
    try {
      const data = await store.updateCatalog({
        id: details.ref,
        ...objectWithoutKeyword(product, 'error'),
        amount: product.amount * 100,
        ...images.reduce(
          (acc, val, index) => ({
            ...acc,
            [`image_url_${index + 1}`]: val,
          }),
          {},
        ),
      });

      if (data?.success) {
        await onDismiss();
        setProduct({
          name: '',
          nameError: false,
          description: '',
          descriptionError: false,
          kind: '',
          kindError: false,
          category: '',
          categoryError: false,
          amount: '',
          amountError: false,
        });
        await setImages([]);
        await setStep(1);
        await onComplete();
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleInputClick = async () => input.current?.click();

  const handleFileChange = async () => {
    NProgress.start();
    setUploading(true);
    try {
      if (images.length < 5) {
        const file = input.current?.files[0];
        const fileName = `${new Date().valueOf()}${file?.name || 'blessed'}`;

        const s3Flux = new S3(awsConfig);

        if (!uploading) {
          const s3response = await s3Flux.uploadFile(file, fileName);

          if (s3response?.status === 204) {
            await setImages(() => [...images, s3response?.location]);
          }
        }
      }
    } finally {
      NProgress.done();
      setUploading(false);
    }
  };

  const deleteImage = async location => {
    setUploading(true);
    setProcessing(true);
    NProgress.start();
    try {
      const fileName = location.split('Catalogs/')[1];
      const s3Flux = new S3(awsConfig);

      if (!uploading) {
        await s3Flux.deleteFile(fileName);

        const index = images.indexOf(location);
        const IMAGES = [...images];
        IMAGES.splice(index, 1);

        setImages(() => IMAGES);
      }
    } finally {
      NProgress.done();
      setUploading(false);
      setProcessing(false);
    }
  };

  React.useLayoutEffect(() => {
    setDetails(() => data);
    setProduct({ ...product, ...data, amount: data.amount / 100 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleNavStep2 = () => {
    if (
      formValidator([
        ...document.forms['product--form'].getElementsByTagName('input'),
        ...document.forms['product--form'].getElementsByTagName('textarea'),
      ])
    ) {
      setStep(2);
    }
  };

  return (
    <Modal
      id={'editProduct'}
      visible={visible}
      ariaLabel={'Add product to catalogue'}
      onDismiss={onDismiss}
      title={'Edit product'}
      customStyle={{ maxWidth: BREAKPOINTS.medium, width: '100%' }}
    >
      <Horizon />
      <Container>
        <div>
          <div aria-label={'step 1 of adding product to catalogue'} />
        </div>
        <div>
          {isFetching ? (
            <Spinner />
          ) : (
            <div>
              {step === 1 && (
                <form noValidate name="product--form" onSubmit={handleNavStep2}>
                  <TextInput
                    name={'title'}
                    type={'text'}
                    placeholder={"Event's title e.g PS4 Slim Pro"}
                    required
                    label={'Event title'}
                    value={product.name}
                    error={product.nameError}
                    onChange={({ target: { value } }) => {
                      setProduct({
                        ...product,
                        name: value,
                        nameError: !value,
                      });
                    }}
                  />
                  <TextInput
                    name={'amount'}
                    type={'number'}
                    placeholder={'1,000'}
                    required
                    label={"Event's price"}
                    value={product.amount}
                    error={product.amountError}
                    onChange={({ target: { value } }) => {
                      setProduct({
                        ...product,
                        amount: value,
                        amountError: !value || value < 50,
                      });
                    }}
                  />
                  <DropD
                    error={product.kindError}
                    value={product.kind}
                    label={'Event kind (product or service)'}
                    name={'product-kind'}
                    required
                    options={[
                      {
                        name: 'Product',
                        id: 'PHYSICAL',
                      },
                      {
                        name: 'Service (Digital)',
                        id: 'DIGITAL',
                      },
                    ]}
                    onChange={({ target: { value } }) => {
                      setProduct({
                        ...product,
                        kind: value,
                        kindError: !value,
                      });
                    }}
                    placeholder={'Select kind of product'}
                  />
                  <DropD
                    error={product.categoryError}
                    value={product.category}
                    label={'Event category'}
                    name={'product-category'}
                    required
                    options={[...productCategories]}
                    onChange={({ target: { value } }) => {
                      setProduct({
                        ...product,
                        category: value,
                        categoryError: !value,
                      });
                    }}
                    placeholder={'Select category of product'}
                  />
                  <TextInput
                    name={'description'}
                    type={'text'}
                    placeholder={'Short Description'}
                    label={'Event description'}
                    required
                    value={product.description}
                    error={product.descriptionError}
                    onChange={({ target: { value } }) => {
                      setProduct({
                        ...product,
                        description: value,
                        descriptionError: !value,
                      });
                    }}
                  />
                </form>
              )}
              {step === 2 && (
                <>
                  <FileUploadContainer
                    role={'button'}
                    aria-label={'upload product images'}
                    onClick={() => handleInputClick()}
                  >
                    <div>
                      <label htmlFor="{'products'}">
                        <FontAwesomeIcon icon="upload" />
                        Upload Image. Max 2MB
                      </label>
                      <input
                        ref={input}
                        type="file"
                        name={'products'}
                        className={'fx-file__input'}
                        onChange={() => handleFileChange()}
                        multiple
                        accept={'image/png, image/jpeg'}
                      />
                    </div>
                  </FileUploadContainer>
                  <UploadedImages>
                    {images.map((image, index) => (
                      <div aria-label={'delete image'} key={image}>
                        <div>
                          <img src={image} alt={`product ${index}`} />
                        </div>
                        <FontAwesomeIcon
                          icon={'times-circle'}
                          color={colors.primary}
                          onClick={() => deleteImage(image)}
                        />
                      </div>
                    ))}
                  </UploadedImages>
                </>
              )}
            </div>
          )}
        </div>
      </Container>
      <FooterContainer>
        <span role={'button'}></span>
        <div>
          {!isProcessing && step !== 1 && (
            <Button
              secondary
              disabled={isProcessing}
              $loading={isProcessing}
              onClick={() => {
                setStep(1);
              }}
            >
              Back
            </Button>
          )}
          <Button
            primary
            aria-label={'submit product details form'}
            onClick={
              step === 1 ? () => handleNavStep2() : () => updateProduct()
            }
            disabled={isProcessing}
            $loading={isProcessing}
          >
            Next
          </Button>
        </div>
      </FooterContainer>
    </Modal>
  );
};

export default EditProduct;
