import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';

import Container, {
  FilterContainer as MemberContainer,
  ModalContainer,
} from './styles';

import { Button, Modal, Table, Tag, TextInput } from '../../../../armoury';
import { members, transaction } from '../../../../api';
import { isTagValid, stripPhone } from '../../../../helpers';
import { AccountHolder } from '../../Wallets/styles';

const StoreMembers = () => {
  const { colors } = useTheme();

  const [isProcessing, setProcessing] = React.useState(false);
  const [isFetching, setFetching] = React.useState(false);

  const [, openModal] = React.useState(false);
  const [filterOpen, openFilter] = React.useState(false);
  const [deleteOpen, openDelete] = React.useState(false);

  const [deleteData, setDeleteData] = React.useState('');

  const [filterOptions] = React.useState({
    locations: [],
  });

  const [table, setTable] = React.useState({
    columns: [],
    data: [],
  });

  React.useEffect(() => {
    (async () => {
      await getMembers();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getMembers = async () => {
    setProcessing(true);
    try {
      const data = await members.getMembers();

      if (data?.success) {
        setTable(() => ({
          columns: [
            {
              label: 'Name',
              key: 'user',
              render: user => user.full_name,
            },
            {
              label: 'Flux ID',
              key: 'user',
              render: user => user?.flux_id,
            },
            {
              label: 'Phone Number',
              key: 'user',
              render: user => {
                return !user.phone ? (
                  <>
                    <span style={{ color: '#646464' }}>Not Available</span>
                  </>
                ) : (
                  <a
                    href={`tel:${stripPhone(user.phone)}`}
                    style={{ color: colors.primary }}
                    rel={'noopener noreferrer'}
                    target={'_blank'}
                  >
                    {stripPhone(user.phone)}
                  </a>
                );
              },
            },
            {
              label: 'Status',
              key: 'active',
              render: active => (
                <Tag type={active ? 'success' : 'pending'}>
                  {active ? 'Active' : 'Pending'}
                </Tag>
              ),
            },
            {
              label: 'Action',
              key: 'pk',
              render: pk => (
                <div
                  role={'button'}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '2rem',
                    backgroundColor: colors.danger.light,
                    padding: '0.4rem 0.6rem',
                    position: 'relative',
                    left: '0.5rem',
                  }}
                  onClick={() => {
                    setDeleteData(pk);
                    openDelete(true);
                  }}
                >
                  <FontAwesomeIcon
                    icon={'trash'}
                    style={{
                      color: colors.danger.base,
                      fontSize: '0.8rem',
                      textAlign: 'center',
                    }}
                  />
                </div>
              ),
            },
          ],
          data: data.response,
        }));
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <>
      <Container>
        <div className="fx--empty-card_container">
          <div className={'fx--orders_empty'}>
            <div className={'fx--orders__header-row'}>
              <h2>
                Event staff<span>.</span>
              </h2>
            </div>

            <div className={'fx-filter--container'}>
              <Button primary onClick={() => openFilter(true)}>
                <FontAwesomeIcon icon={'plus'} />
                Add staff
              </Button>
            </div>
          </div>
        </div>
        <div className="fx--empty-card_container" style={{ marginTop: '24px' }}>
          <Table
            columns={table.columns}
            data={table.data}
            isFetching={isProcessing}
            title={'Events members'}
          />
          <AddMember
            visible={filterOpen}
            onDismiss={() => openFilter(false)}
            data={filterOptions}
            disabled={isFetching}
            onProcessing={status => setFetching(status)}
            onFilter={async data => {
              await setTable({
                ...table,
                data,
              });
            }}
          />
          <DeleteModal
            visible={deleteOpen}
            onDismiss={async target => {
              setDeleteData('');
              openDelete(false);
              openModal(!target);
              await getMembers();
            }}
            data={deleteData}
          />
        </div>
      </Container>
    </>
  );
};

const DeleteModal = ({ visible, onDismiss, data }) => {
  const [modalConfirm, confirmModal] = React.useState(false);
  const [isProcessing, setProcessing] = React.useState(false);

  const deleteMember = async () => {
    setProcessing(true);
    try {
      const d = await members.deleteMember({ id: data });

      if (d?.success) {
        await onDismiss('all');
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal
      id={'deleteStoreMember'}
      visible={visible}
      onDismiss={onDismiss}
      ariaLabel={'delete store member'}
    >
      <ModalContainer status={'success'}>
        <div className="fx-modal--header">
          <h4>Delete Member</h4>
        </div>
        <div
          className="fx-modal--description-note"
          style={{ marginBottom: '1rem' }}
        >
          <span>
            Deleting this member will mean they no longer have access to your
            Flux Store catalog, and orders
          </span>
        </div>
        <Button
          primary
          wide
          $loading={isProcessing}
          disabled={isProcessing}
          onClick={async event => {
            event.preventDefault();

            if (modalConfirm) {
              await deleteMember();
            } else {
              confirmModal(true);
            }
          }}
        >
          {!modalConfirm
            ? 'Yes, I want to delete this customer'
            : 'Delete Member'}
        </Button>
      </ModalContainer>
    </Modal>
  );
};

const AddMember = ({ visible, onDismiss }) => {
  const [isProcessing, setProcessing] = React.useState(false);

  const [user, setUser] = React.useState('');

  const [id, setId] = React.useState('');
  const [idError, setIdError] = React.useState(false);

  React.useEffect(() => {
    setUser('');
  }, [id]);

  const confirmUser = async () => {
    setProcessing(true);
    try {
      const data = await transaction.verifyUser({ id });

      if (data?.success) {
        setUser(() => data.response);
      }
    } finally {
      setProcessing(false);
    }
  };

  const add = async () => {
    setProcessing(true);
    try {
      const data = await members.addMember({ user: user?.pk });

      if (data?.success) {
        await dismiss();
      }
    } finally {
      setProcessing(false);
    }
  };

  const dismiss = async () => {
    setUser('');
    setId('');
    await onDismiss();
  };

  return (
    <Modal id={'addStoreMember'}
      visible={visible}
      onDismiss={!isProcessing && dismiss}
      title={'Add Events Member'}
    >
      <MemberContainer>
        <form>
          <div style={{ marginTop: '1rem' }}>
            <TextInput
              value={id}
              onChange={({ target: { value } }) => {
                setId(value);
                setIdError(() => {
                  const text = value.toLowerCase();
                  if (text.startsWith('f')) {
                    return value.length < 5;
                  } else if (text.startsWith('@')) {
                    return !isTagValid(value);
                  } else return true;
                });
              }}
              error={idError}
              required
              disabled={isProcessing}
              placeholder={'F1234 or @snapdragon'}
              label={'Flux ID or Flux Tag'}
            />
          </div>
          {!!user && (
            <AccountHolder style={{ marginTop: '1rem' }}>
              <div
                aria-label={'account holder name'}
                className={'fx-withdraw--modal_account-holder'}
              >
                <span>{user?.full_name}</span>
              </div>
            </AccountHolder>
          )}
          <Button
            wide
            primary
            disabled={isProcessing || !id || idError}
            onClick={!user ? confirmUser : add}
            $loading={isProcessing}
            style={{ marginTop: '1rem' }}
          >
            {!user ? 'Confirm User' : 'Submit'}
          </Button>
        </form>
      </MemberContainer>
    </Modal>
  );
};

export default StoreMembers;
