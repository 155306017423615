import { createGlobalStyle } from 'styled-components';
import { normalize, lighten } from 'polished';

import { query } from '../helpers';

export default createGlobalStyle`
  ${normalize()}

  ;
  * {
    box-sizing: border-box;

    &::selection {
      background-color: ${({ theme }) => lighten(0.2, theme.colors.primary)};
      color: ${({ theme }) => lighten(0.9, theme.colors.secondary)};
    }

    &::-moz-selection {
      background-color: ${({ theme }) => lighten(0.2, theme.colors.primary)};
      color: ${({ theme }) => lighten(0.9, theme.colors.secondary)};
    }
  }

  html .fx-file__input {
    display: none;
  }

  html, body {
    overflow-x: hidden;
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;

    #nprogress .bar {
      background: ${({ theme }) => theme.colors.primary};
      height: 3px;
    }

    #nprogress .peg {
      box-shadow: 0 0 10px ${({ theme }) => theme.colors.primary},
      0 0 ${({ theme }) => theme.colors.primary};
    }

    #nprogress .spinner-icon {
      border-top-color: ${({ theme }) => theme.colors.primary};
      border-left-color: ${({ theme }) => theme.colors.primary};
    }
  }

  body {
    font-family: ${({ theme }) => theme['font-family']};
    font-size: 1.2em;
    max-width: 100vw;

    &.no-scroll-x {
      overflow-x: hidden;
    }

    &.no-scroll-y {
      overflow-y: hidden;
    }

    &.no-scroll {
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      width: 0;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background: #ffffff;
    }

    &::-webkit-scrollbar-thumb {
      background: ${({ theme }) => theme.colors.secondary};
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: ${({ theme }) => theme.colors.secondary};
    }

    :focus {
      outline-width: 0.2em;
      outline-style: dashed;
      outline-color: ${({ theme }) => theme.colors.primary};
    }

    [type='text'],
    [type='password'],
    [type='search'],
    [type='tel'],
    [type='email'] {
      &:focus {
        outline-color: transparent;
      }

      &:requi${({ theme }) => theme.colors.primary},
      &:invalid {
        box-shadow: none;
      }

      &::placeholder {
        color: rgb(189, 189, 189);
      }
    }

    ${query.maxWidth('medium')`
      font-size: 90%;
    `};
    ${query.minWidth('>medium')`
      font-size: 92%;
    `};
    ${query.between('large1', 'large2')`
      font-size: 88%;
    `};

    button,
    [role='button'],
    [type='submit'] {
      appearance: none;
      cursor: pointer;
      outline-color: transparent;
    }

    label {
      user-select: none;
    }

    a {
      text-decoration: none;

      &:focus {
        outline: 2px solid ${({ theme }) => theme.colors.primary};
      }
    }

    input:-webkit-autofill {
      color: transparent !important;
      background-image: none !important;
      background-color: #fff !important;
      -webkit-text-fill-color: rgba(61, 21, 95, .7) !important;
    }

    #app {
      -webkit-overflow-scrolling: touch;
    }

    img {
      user-select: none;
    }

    ul, ol {
      list-style-type: none;
      padding: 0;
    }

    [tabindex='0']:focus {
      outline: 2px dashed ${({ theme }) => theme.colors.primary};
      outline-offset: 3px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 12px 0;
  }
`;
