import { css, default as styled } from 'styled-components';
import { toPx } from '../../helpers';
import { rem } from 'polished';
import { borderThickness } from '../../constants';

const Container = styled.div`
  label {
    display: block;
  }

  box-sizing: border-box;

  input[type='number'] {
    -moz-appearance: textfield;
    margin: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='text'],
  input[type='tel'],
  input[type='password'],
  input[type='search'],
  input[type='number'],
  input[type='email'],
  input[type='url'],
  textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    background-color: ${({ theme }) => theme.colors.base};
    margin-top: 8px;
    display: block;
    width: 100%;
    border-radius: 4px;
    border: ${toPx(borderThickness)} solid #00000029;
    padding: 12px 8px;
    font-size: 0.8rem;
    outline-color: transparent;

    ${({ disabled }) =>
      disabled &&
      css`
        cursor: not-allowed;
        opacity: 0.6;
        user-select: none;
      `};

    ${({ error, theme }) =>
      error &&
      css`
        border-width: 2px;
        border-color: ${theme.colors.danger.base};
      `};

    ::placeholder {
      color: #aeaeae;
      font-size: 0.8rem;
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  textarea {
    resize: none;
  }

  p.error-msg {
    text-align: left;
    margin: 0px;
    margin-top: 0.5em;
    font-size: ${rem('12px')};
    letter-spacing: ${rem('0.13px')};
    color: #ff5e5e;
    opacity: 1;
  }
`;

export default Container;
