import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faAddressBook,
  faArrowUp,
  faCalendar,
  faCaretDown,
  faCaretRight,
  faCheckCircle,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faClipboardCheck,
  faCog,
  faCreditCard,
  faEllipsisV,
  faEye,
  faEyeSlash,
  faFilter,
  faFunnelDollar,
  faLink,
  faMoneyCheckAlt,
  faPen,
  faPhone,
  faPlus,
  faQrcode,
  faShoppingBag,
  faShoppingBasket,
  faStore,
  faStoreAlt,
  faTimesCircle,
  faTrash,
  faUndoAlt,
  faUpload,
  faUserCircle,
  faUsers,
  faUserShield,
  faWallet,
} from '@fortawesome/free-solid-svg-icons';

library.add(
  faChevronLeft,
  faChevronRight,
  faWallet,
  faShoppingBag,
  faUserCircle,
  faStore,
  faAddressBook,
  faTimesCircle,
  faPhone,
  faShoppingBasket,
  faCog,
  faCreditCard,
  faEye,
  faPlus,
  faEyeSlash,
  faTrash,
  faArrowUp,
  faEllipsisV,
  faStoreAlt,
  faChevronDown,
  faUpload,
  faLink,
  faPen,
  faUserShield,
  faCheckCircle,
  faFunnelDollar,
  faMoneyCheckAlt,
  faUndoAlt,
  faFilter,
  faClipboardCheck,
  faCaretRight,
  faCaretDown,
  faUsers,
  faQrcode,
  faCalendar,
);
