import { default as React, useState } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import toast from 'react-hot-toast';

import Container from '../styles';
import { Button, Drawer, TextInput } from '../../../armoury';
import { isPicked } from '../../../helpers';
import { handleBasicProfileUpdate } from '../../../store/actions';
import Cookies from 'js-cookie';

const Step1 = ({ onBlur }) => {
  const [data, setData] = React.useState({
    name: '',
    nameError: false,
    phone: '',
    phoneError: false,
  });

  return (
    <>
      <TextInput
        placeholder={'James Bond'}
        error={data.nameError}
        label={'Full Name'}
        required
        type={'text'}
        value={data.name}
        onChange={({ target: { value } }) => {
          setData({
            ...data,
            name: value,
            nameError: !value,
          });
        }}
        onBlur={() => onBlur(data)}
      />
      <TextInput
        placeholder={'09012121212'}
        label={'Phone Number'}
        required
        error={data.phoneError}
        type={'number'}
        value={data.phone}
        onChange={({ target: { value } }) => {
          setData({
            ...data,
            phone: value,
            phoneError: !value,
          });
        }}
        onBlur={() => onBlur(data)}
      />
    </>
  );
};

const Step2 = ({ type, onChange }) => {
  return (
    <>
      <Drawer.Group title={'What type of account do you want?'}>
        <Drawer.Item
          label={'Personal'}
          active={type === 'individual'}
          onClick={() => onChange('individual')}
        />
        <Drawer.Item
          label={'Business'}
          active={type === 'business'}
          onClick={() => onChange('business')}
        />
      </Drawer.Group>
    </>
  );
};

const AccountSetup = () => {
  const router = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(({ user }) => user);
  const mounted = React.useRef(null);
  const token = Cookies.get('red-dawn');

  const isComplete = isPicked(user, ['full_name', 'phone', 'account_type']);

  const [name, setName] = useState('');
  const [accountType, setAccountType] = useState('individual');
  const [phoneNO, setPhone] = useState('');

  const [step, setStep] = useState(1);
  const [isProcessing, setIsProcessing] = useState(false);

  const changeStep = stepper => {
    if (stepper === -1) {
      setStep(c => (step === 1 ? c : --c));
    } else {
      setStep(c => (step === 3 ? c : ++c));
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    setIsProcessing(true);
    try {
      const response = await dispatch(
        handleBasicProfileUpdate({
          full_name: name,
          account_type: 'individual',
          phone: phoneNO,
        }),
      );

      if (response?.success) {
        setIsProcessing(false);

        toast.success(
          'Great! Account successfully setup, please check your email for instructions on how to verify your account',
        );

        router.push('/wallets/');
      }
    } finally {
      if (mounted) {
        setIsProcessing(false);
      }
    }
  };

  if (!token) {
    return <Redirect to={'/sign-in/'} />;
  }

  if (!isComplete) {
    return (
      <Container>
        <main className={'fx-auth-container'}>
          <div className={'fx-auth-container__image-container'}>
            {step !== 1 && (
              <div
                role={'button'}
                onClick={() => changeStep(-1)}
                className={'chevron-container'}
              >
                <FontAwesomeIcon icon={'chevron-left'} />
              </div>
            )}

            <img
              src="https://desert-storm.s3-us-west-2.amazonaws.com/assets/flux-logo.png"
              alt="Flux logo"
              title={'Flux'}
            />
          </div>
          <h4>Complete your account setup</h4>
          <form onSubmit={handleSubmit}>
            {step === 1 && (
              <Step1
                onBlur={data => {
                  setName(data.name);
                  setPhone(data.phone);
                }}
              />
            )}
            {step === 2 && (
              <Step2
                type={accountType}
                onChange={type => setAccountType(type)}
              />
            )}
            <Button
              $loading={isProcessing}
              disabled={isProcessing}
              primary
              // onClick={() => changeStep()}
              wide
            >
              {step !== 3 ? 'Next' : 'Complete'}
            </Button>
          </form>
        </main>
      </Container>
    );
  } else return null;
};
export default AccountSetup;
