import styled from 'styled-components';

const Container = styled.div`
  margin-top: 24px;

  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, auto);
  grid-gap: 2em;
`;

export default Container;
