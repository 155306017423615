import styled from 'styled-components';

const Container = styled.form`
  display: flex;
  flex-direction: row;

  > div {
    margin-top: -7px;

    ul[role='tablist'] {
      display: flex;
      flex-direction: column;
      padding-left: 2px;

      li {
        cursor: pointer;
        height: 2.5rem;
        padding: 0 12px;
        display: flex;
        align-items: center;
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
        color: ${({ theme }) => theme.colors['primary-text']};
        font-size: 0.8rem;
        font-weight: 400;
        margin-bottom: 0.6rem;

        &.active {
          &:not(.logout) {
            color: ${({ theme }) => theme.colors.primary};
            background-color: #f9f6fb;
          }
        }
        
        &.logout {
          color: ${({ theme }) => theme.colors.danger.base};

          &:hover {
            background: ${({ theme }) => theme.colors.danger.light};
          }
        }
      }
    }

    &:first-child {
      flex: 0.4;
      border-right: 1.5px solid #00000029;
    }

    &:nth-child(2) {
      flex: 0.6;
      padding: 32px 36px;

      > div {
        overflow-y: auto;
      }
    }
  }
`;

export const FooterContainer = styled.div`
  border-top: 1.5px solid #00000029;
  padding: 24px 0 8px 0;
  display: flex;
  justify-content: flex-end;

  span[role='button'] {
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 400;
    font-size: 0.9rem;
    margin-top: 12px;
  }
`;

export default Container;
