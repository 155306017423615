import * as React from 'react';

const useOutsideClick = (ref, action) => {
  React.useEffect(() => {
    const handleOutsideClick = async event => {
      if (ref.current && !ref.current.contains(event.target)) {
        await action();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [action, ref]);
};

export default useOutsideClick;
