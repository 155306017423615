import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { default as Container, FooterContainer } from './styles';

import { Button, Horizon, Modal, TextInput } from '../../../armoury';
import { BREAKPOINTS } from '../../../constants';
import { handleLogout } from '../../../store/actions';
import { user } from '../../../api';
import { formValidator, isCorrectDateFormat, isTagValid } from '../../../helpers';

const Settings = ({ visible, onDismiss }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userData = useSelector(({ user }) => user);

  const [isProcessing, setProcessing] = React.useState(false);

  const [activeTab, setActiveTab] = React.useState('update-profile');

  const [bvn, setBvn] = React.useState('');
  const [bvnError, setBvnError] = React.useState(false);

  const [fluxTag, setFluxTag] = React.useState(userData?.flux_tag || '');
  const [, setFluxTagError] = React.useState(false);

  const [fullName, setFullName] = React.useState(userData?.full_name || '');
  const [fullNameError] = React.useState(false);

  const [phone, setPhone] = React.useState(userData?.phone || '');
  const [phoneError, setPhoneError] = React.useState(false);

  const [dob, setDob] = React.useState(userData?.dob || '');
  const [dobError, setDobError] = React.useState(false);

  const [oldPassword, setOldPassword] = React.useState('');
  const [oldPasswordError, setOldPasswordError] = React.useState(false);

  const [newPassword1, setNewPassword1] = React.useState('');
  const [newPasswordError1, setNewPasswordError1] = React.useState(false);

  const [newPassword2, setNewPassword2] = React.useState('');
  const [newPasswordError2, setNewPasswordError2] = React.useState(false);

  const logout = async () => {
    const response = await dispatch(handleLogout());

    if (response?.success) {
      history.push('/sign-in/');
    }
  };

  const verifyBVN = async () => {
    setProcessing(true);

    try {
      await user.updateBVN({ bvn });

      setBvn('');
    } finally {
      setProcessing(false);
    }
  };

  const changePassword = async () => {
    setProcessing(true);
    try {
      const data = await user.changePassword({
        old_password: oldPassword,
        new_password1: newPassword1,
        new_password2: newPassword2,
      });

      if (data?.success) {
        // console.log(data.response);
      }
    } finally {
      setProcessing(false);
    }
  };

  const updateProfile = async () => {
    setProcessing(true);

    try {
      const data = await user.updateBasicProfile({
        full_name: fullName,
        flux_tag: fluxTag,
        phone,
        dob,
      });

      if (data?.success) {
        // console.log(data.response);
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleSubmit = () => {
    if (
      formValidator([
        ...document.forms['settings--form'].getElementsByTagName('input'),
        ...document.forms['settings--form'].getElementsByTagName('textarea'),
      ])
    ) {
      if (activeTab === 'update-profile') updateProfile();
      else if (activeTab === 'account-verification') verifyBVN();
      else if (activeTab === 'change-password') changePassword();
    }
  };

  return (
    <>
      <Modal
        id={'settings'}
        title={'Settings'}
        visible={visible}
        onDismiss={onDismiss}
        customStyle={{ maxWidth: BREAKPOINTS.medium, width: '100%' }}
      >
        <Horizon />
        <Container>
          <div>
            <ul
              role={'tablist'}
              className={'settings-tablist'}
              aria-label={'settings actions'}
            >
              <li
                role={'tab'}
                aria-selected={activeTab === 'update-profile'}
                aria-controls={'accountVerificationForm'}
                className={activeTab === 'update-profile' ? 'active' : ''}
                onClick={() => setActiveTab('update-profile')}
              >
                Update Profile
              </li>
              <li
                role={'tab'}
                aria-selected={activeTab === 'account-verification'}
                aria-controls={'accountVerificationForm'}
                className={activeTab === 'account-verification' ? 'active' : ''}
                onClick={() => setActiveTab('account-verification')}
              >
                Account Verification
              </li>
              <li
                role={'tab'}
                aria-selected={activeTab === 'change-password'}
                aria-controls={'passwordChangeForm'}
                className={activeTab === 'change-password' ? 'active' : ''}
                onClick={() => setActiveTab('change-password')}
              >
                Change Password
              </li>
              <Horizon />
              <li className={'logout'} onClick={() => logout()}>
                Logout
              </li>
            </ul>
          </div>
          <div>
            <form
              noValidate
              name='settings--form'
              onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              {activeTab === 'update-profile' && (
                <div id={'accountVerificationForm'}>
                  <TextInput
                    placeholder={'e.g @snapdragon'}
                    label={'Flux tag'}
                    name={'Flux tag'}
                    type={'text'}
                    required
                    onChange={({ target: { value } }) => {
                      setFluxTag(value);
                      setFluxTagError(!value || !isTagValid(value));
                    }}
                  />
                  <TextInput
                    placeholder={'Arnold Schwarzenegger'}
                    label={'Full Name'}
                    type={'text'}
                    name={'Full Name'}
                    required
                    value={fullName}
                    error={fullNameError}
                    onChange={({ target: { value } }) => {
                      setFullName(value);
                    }}
                  />
                  <TextInput
                    placeholder={'Phone number'}
                    label={'Phone Number'}
                    name={'Phone Number'}
                    type={'tel'}
                    required
                    value={phone}
                    error={phoneError}
                    onChange={({ target: { value } }) => {
                      setPhoneError(value);

                      if (value.length <= 11) {
                        setPhone(value);
                      }
                      setPhoneError(value.length < 11);
                    }}
                  />
                  <TextInput
                    label={'DOB'}
                    type={'text'}
                    placeholder={'Date of Birth'}
                    required
                    name={'DOB'}
                    value={dob}
                    error={dobError}
                    onChange={({ target: { value } }) => {
                      setDob(value);
                      setDobError(!value || !isCorrectDateFormat(value));
                    }}
                  />
                </div>
              )}
              {activeTab === 'account-verification' && (
                <div>
                  <TextInput
                    placeholder={'213412512'}
                    label={'Bank Verification Number (BVN)'}
                    type={'number'}
                    required
                    name={'BVN'}
                    value={bvn}
                    onChange={({ target: { value } }) => {
                      setBvn(value);
                      setBvnError(!value || value.length < 11);
                    }}
                    error={bvnError}
                  />
                </div>
              )}
              {activeTab === 'change-password' && (
                <div id={'passwordChangeForm'}>
                  <TextInput
                    placeholder={'Current Password'}
                    label={'Current Password'}
                    type={'password'}
                    required
                    name={'Current Password'}
                    value={oldPassword}
                    error={oldPasswordError}
                    onChange={({ target: { value } }) => {
                      setOldPassword(value);
                      setOldPasswordError(!value);
                    }}
                  />
                  <TextInput
                    placeholder={'New Password'}
                    label={'New Password'}
                    type={'password'}
                    required
                    name={'New Password'}
                    value={newPassword1}
                    error={newPasswordError1}
                    onChange={({ target: { value } }) => {
                      setNewPassword1(value);
                      setNewPasswordError1(!value);
                    }}
                  />
                  <TextInput
                    placeholder={'New Password'}
                    label={'Confirm New Password'}
                    type={'password'}
                    required
                    name={'New Password'}
                    value={newPassword2}
                    error={newPasswordError2}
                    onChange={({ target: { value } }) => {
                      setNewPassword2(value);
                      setNewPasswordError2(!value);
                    }}
                  />
                </div>
              )}
            </form>
          </div>
        </Container>
        <FooterContainer>
          <div>
            {activeTab === 'update-profile' && (
              <Button
                primary
                onClick={handleSubmit}
                $loading={isProcessing}
                // disabled={updateDisabled}
              >
                Update
              </Button>
            )}
            {activeTab === 'account-verification' && (
              <Button
                primary
                // disabled={bvnDisabled}
                onClick={handleSubmit}
                $loading={isProcessing}
              >
                Verify
              </Button>
            )}
            {activeTab === 'change-password' && (
              <Button
                primary
                onClick={handleSubmit}
                $loading={isProcessing}
                // disabled={changePasswordDisabled}
              >
                Change Password
              </Button>
            )}
          </div>
        </FooterContainer>
      </Modal>
    </>
  );
};

export default Settings;
