import toast from 'react-hot-toast';
import Cookies from 'js-cookie';
import CryptoJS from 'crypto-js';

import { errorHandler, server } from '../../lib';

const get = async () => {
  try {
    const { status, data: response } = await server.get('/auth/user/');

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const login = async ({ email, password }) => {
  try {
    const { status, data: response } = await server.post('/auth/login/', {
      email,
      password,
    });

    if (status === 200) {
      toast.success('Welcome back');

      const token = await CryptoJS.TripleDES.encrypt(
        response.token,
        'pluginUpdatesAvailable',
      ).toString();

      Cookies.set('red-dawn', token, { expires: 3 });

      return {
        response: response.user,
        success: true,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const register = async ({ email, password }) => {
  try {
    const { status, data: response } = await server.post('/auth/register/', {
      email,
      password1: password,
    });

    if (status === 201) {
      const token = await CryptoJS.TripleDES.encrypt(
        response.token,
        'pluginUpdatesAvailable',
      ).toString();

      Cookies.set('red-dawn', token, { expires: 3 });

      return {
        response: response.user,
        success: true,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const updateBasicProfile = async data => {
  try {
    const { status, data: response } = await server.put('/auth/user/', {
      ...data,
    });

    if (status === 200) {
      return {
        response,
        success: true,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const updateBusinessProfile = async ({ business_id, name, description }) => {
  const body = { name, description };

  try {
    const { status, data: response } = await server.put(
      `/business/v2/${business_id}`,
      body,
    );

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const changePassword = async ({
  old_password,
  new_password1,
  new_password2,
}) => {
  try {
    const { status, data: response } = await server.post(
      '/auth/password/change/',
      {
        old_password,
        new_password1,
        new_password2,
      },
    );

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const updateBVN = async ({ bvn }) => {
  try {
    const { status, data: response } = await server.post('/users/bvn/', {
      bvn,
    });

    if (status === 200 && response.success) {
      toast.success(response.msg);
    } else {
      toast.error('Unable to verify your BVN');
    }

    return response;
  } catch ({ response }) {
    errorHandler(response);
  }
};

const upgradeToBusiness = async ({ name, description }) => {
  try {
    const { status, data: response } = await server.post(
      '/users/account_change/',
      {
        account_type: 'business',
        name,
        description,
      },
    );

    if (status === 200) {
      toast.success(
        'Congratulations!! You are now a merchant, please complete your account setup',
      );

      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const getSalesBalance = async ({ period }) => {
  try {
    const { status, data: response } = await server.post('/business/stats/', {
      period,
    });

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const user = {
  get,
  login,
  register,
  updateBVN,
  changePassword,
  getSalesBalance,
  upgradeToBusiness,
  updateBasicProfile,
  updateBusinessProfile,
};

export default user;
