import toast from 'react-hot-toast';
import { server, errorHandler } from '../../lib';

const verify = async ({ bank_code, account_no }) => {
  try {
    const { status, data: response } = await server.post(
      '/users/verify/account/',
      {
        bank_code,
        account_no,
      },
    );

    if (status === 200) {
      if (response.resolved) {
        return {
          success: true,
          response,
        };
      } else {
        toast.error(response.error);
      }
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const transfer = async ({ amount, bank_code, acct_name, acct_no }) => {
  try {
    const { status, data: response } = await server.post(
      '/wallet/v2/withdraw/',
      {
        amount: amount * 100,
        bank_code,
        acct_name,
        acct_no,
      },
    );

    if (status === 200) {
      toast.success('Great! Withdrawal successful');
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const bank = {
  verify,
  transfer,
};

export default bank;
