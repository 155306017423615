import styled from 'styled-components';

const Container = styled.div`
  height: 200px;
  max-width: 400px;
  width: 100%;
  background-color: #855aaf;
  border-radius: 12px;
  padding: 18px;
  position: relative;
  user-select: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;

  &:hover {
    box-shadow: 0 5px 12px ${({ theme }) => theme.colors.shadow};
  }

  display: flex;
  flex-direction: column;

  div.icon-section {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg {
      color: #ffffff;
      cursor: pointer;
    }
  }

  div.card-balance--section {
    height: 80px;
    display: flex;
    align-items: center;

    span {
      color: #ffffff;
      font-size: 2rem;
      font-weight: 400;
    }
  }

  div.card-number--section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #ffffff;
    height: 50px;

    div {
      display: flex;
      justify-content: space-between;
    }
  }

  nav.menu {
    background-color: #ffffff;
    position: absolute;
    right: 32px;
    border-radius: 4px;
    box-shadow: 0 5px 12px ${({ theme }) => theme.colors.shadow};

    ul {
      list-style-type: none;

      li {
        padding: 12px 16px;
        cursor: pointer;
        color: rgba(34, 34, 34, 0.6);
        font-weight: 400;
        font-size: 0.8rem;

        &:hover {
          background-color: #e7e7e7;
        }
      }
    }
  }
`;

export default Container;
