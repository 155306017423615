import toast from 'react-hot-toast';

const copy = async data => {
  try {
    await navigator.clipboard.writeText(data);
  } catch ({ response }) {
    //
  } finally {
    toast.success('Copied to clipboard');
  }
};

export default copy;
