import React from 'react';
import { ThemeProvider } from 'styled-components';
import { hot } from 'react-hot-loader';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import Cookies from 'js-cookie';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { GlobalStyles, theme } from './base/theme';
import './lib/icon';
import { networkHandler } from './lib';
import { AccountSetup, Dashboard, SignIn, SignUp } from './views';

export const ProtectedRoute = ({ children, ...rest }) => {
  const token = Cookies?.get('red-dawn');

  return (
    <Route
      {...rest}
      render={() => {
        return !!token ? children : <Redirect to={'/sign-in/'} />;
      }}
    />
  );
};

function App() {
  React.useEffect(() => {
    networkHandler();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <Router>
        <Switch>
          <Route path={'/sign-in'} component={SignIn} />
          <Route path={'/sign-up'} component={SignUp} />
          <Route exact path={'/complete-setup'} component={AccountSetup} />

          <ProtectedRoute>
            <Route path={'/'} component={Dashboard} />
          </ProtectedRoute>
        </Switch>
      </Router>
      <ToastContainer />
    </ThemeProvider>
  );
}

export default hot(module)(App);
