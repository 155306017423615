import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';
import QrReader from 'modern-react-qr-reader';

import Container, {
  FABContainer,
  FilterContainer,
  ModalContainer,
} from './styles';

import {
  Button,
  Horizon,
  Modal,
  Option,
  Table,
  Tag,
  TextInput,
} from '../../../armoury';
import { delivery, orders, ticket } from '../../../api';
import { formatAmount, formatDate, stripPhone, wait } from '../../../helpers';
import { theme } from '../../../base/theme';
import { lighten } from 'polished';
import toast from 'react-hot-toast';

const Orders = () => {
  const { colors } = useTheme();

  const [isProcessing, setProcessing] = React.useState(false);
  const [isFetching, setFetching] = React.useState(false);
  const [isSearching, setSearching] = React.useState(false);

  const [count, setCount] = React.useState(0);

  const [data, setData] = React.useState({});
  const [modalOpen, openModal] = React.useState(false);
  const [filterOpen, openFilter] = React.useState(false);
  const [refundOpen, openRefund] = React.useState(false);
  const [confirmOpen, openConfirm] = React.useState(false);

  const [scanner, setScanner] = React.useState(false);

  const [search, setSearch] = React.useState('');

  const [filterOptions, setFilterOptions] = React.useState({
    locations: [],
  });

  const [table, setTable] = React.useState({
    columns: [],
    data: [],
  });

  React.useEffect(() => {
    (async () => {
      if (!isSearching && !!search) {
        await wait(async () => {
          await filterBySearch();
        }, 2000);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  React.useEffect(() => {
    (async () => {
      await getOrders();
      await getDeliveryLocations();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const [fetching, setFetching] = React.useState('');

  const filterBySearch = async () => {
    setSearching(true);
    try {
      const data = await orders.search({ search });

      if (data?.success) {
        setTable({
          ...table,
          data: [...data.response],
        });
      }
    } finally {
      setSearching(false);
    }
  };

  const getDeliveryLocations = async () => {
    setFetching(true);
    try {
      const data = await delivery.getAddresses();

      if (data?.success) {
        setFilterOptions({
          ...filterOptions,
          locations: [...data.response],
        });
      }
    } finally {
      setFetching(false);
    }
  };

  const getOrders = async () => {
    setProcessing(true);
    try {
      const data = await orders.get();
      setCount(data.count);

      if (data?.success) {
        setTable(() => ({
          columns: [
            {
              label: 'Title',
              key: 'ticket',
              render: ticket => ticket.name,
            },
            {
              label: 'Customer name',
              key: 'name',
            },
            {
              label: 'Ticket Ref. No.',
              key: 'ticket',
              render: ticket => ticket.ref,
            },
            {
              label: 'Amount',
              key: 'ticket',
              render: ticket => (
                <span style={{ color: colors.primary }}>
                  {formatAmount(ticket.amount / 100)}
                </span>
              ),
            },
            {
              label: 'User Ref. No.',
              key: 'other_ref',
            },
            {
              label: 'Phone Number',
              key: 'phone',
              render: phone => (
                <a
                  href={`tel:${stripPhone(phone)}`}
                  style={{ color: colors.primary }}
                  rel={'noopener noreferrer'}
                  target={'_blank'}
                >
                  {stripPhone(phone)}
                </a>
              ),
            },
            {
              label: 'Date',
              key: 'created_at',
              render: date => new Date(date).toLocaleDateString(),
            },
            {
              label: 'Order Status',
              key: 'completed',
              render: () => <Tag type={'success'}>fulfilled</Tag>,
            },
          ],
          data: data.response,
        }));
      }
    } finally {
      setProcessing(false);
    }
  };

  const onScan = async ticket_ref => {
    if (ticket_ref) {
      setScanner(false);
      try {
        await ticket.mark({
          ticket_ref,
        });
      } finally {
        await getOrders();
      }
    }
  };

  const onError = () => {
    toast.error('An error occurred starting up the camera');
  };

  const scannerStyle = {
    height: '90%',
    width: '100%',
    backgroundColor: lighten(0.3, theme.colors.primary),
    position: 'absolute',
    top: 0,
    left: 0,
    borderRadius: '20px',
  };

  return (
    <>
      <Container>
        <div className="fx--empty-card_container">
          <div className={'fx--orders_empty'}>
            <h2>
              Sales Wallet Transactions<span>.</span>
            </h2>

            <div className={'fx-filter--container'}>
              <Button secondary onClick={() => openFilter(true)}>
                <FontAwesomeIcon icon={'filter'} />
                <span>Filter</span>
              </Button>
              <div className={'vr'} />
              <TextInput
                label={''}
                placeholder={'Enter Event name / Reference'}
                value={search}
                onChange={({ target: { value } }) => {
                  setSearch(value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="fx--empty-card_container" style={{ marginTop: '24px' }}>
          <Table
            columns={table.columns}
            data={table.data}
            onRowClick={d => {
              setData(d);
              openModal(true);
            }}
            isFetching={isProcessing}
            title={'Sales Transactions'}
            count={count}
          />
          <TransactionDetails
            data={data}
            visible={modalOpen}
            onDismiss={() => openModal(false)}
            onRefund={() => {
              openModal(false);
              openRefund(true);
            }}
            onConfirm={() => {
              openModal(false);
              openConfirm(true);
            }}
          />
          <Filter
            visible={filterOpen}
            onDismiss={() => openFilter(false)}
            data={filterOptions}
            disabled={isFetching}
            onProcessing={status => setFetching(status)}
            onFilter={async data => {
              await setTable({
                ...table,
                data,
              });
            }}
          />
          <RefundModal
            visible={refundOpen}
            onDismiss={async target => {
              openRefund(false);
              openModal(!target);
              await getOrders();
            }}
            data={data}
          />
          <ConfirmOrder
            onDismiss={async () => {
              openConfirm(false);
              openModal(false);
              await getOrders();
            }}
            visible={confirmOpen}
            data={data}
          />
          <FABContainer onClick={() => setScanner(true)}>
            Scan Ticket <FontAwesomeIcon icon={'qrcode'} />
          </FABContainer>
          {scanner && (
            <QrReader
              delay={100}
              style={scannerStyle}
              onError={onError}
              onScan={onScan}
              facingMode={'environment'}
            />
          )}
        </div>
      </Container>
    </>
  );
};

const ConfirmOrder = ({ visible, onDismiss, data }) => {
  const [modalConfirm, confirmModal] = React.useState(false);
  const [isProcessing, setProcessing] = React.useState(false);

  const confirm = async () => {
    setProcessing(true);
    try {
      const d = await orders.updateOrder({
        ref: data?.ref,
        data: { ...data, completed: true },
      });

      if (d?.success) {
        await onDismiss('all');
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal
      id={'completeOrder'}
      visible={visible}
      onDismiss={onDismiss}
      ariaLabel={'complete order'}
    >
      <ModalContainer status={'success'}>
        <div className="fx-modal--header">
          <h4>Complete Order</h4>
        </div>
        <form style={{ marginTop: '12px' }}>
          <div className="fx-modal__balance-container">
            <div>
              <span>Amount to Refund</span>
              <span>{formatAmount(data?.catalog?.amount / 100)}</span>
            </div>
          </div>
          <div className="fx-modal__balance-container">
            <div>
              <span>Quantity</span>
              <span>{data?.quantity}</span>
            </div>
          </div>
          <div className="fx-modal__balance-container">
            <div>
              <span>Sender Name</span>
              <span>{data?.buyer?.name}</span>
            </div>
          </div>
          <div className="fx-modal__balance-container">
            <div>
              <span>Delivery Address</span>
              <span>{data?.address}</span>
            </div>
          </div>
          <div className="fx-modal__balance-container">
            <div>
              <span>Extra Info</span>
              <span>{data?.extra_info}</span>
            </div>
          </div>
          <div style={{ marginTop: '24px' }}>
            <Button
              primary
              wide
              $loading={isProcessing}
              disabled={isProcessing}
              onClick={async event => {
                event.preventDefault();

                if (modalConfirm) {
                  await confirm();
                } else {
                  confirmModal(true);
                }
              }}
            >
              {modalConfirm
                ? 'Yes, I want to complete this order'
                : 'Complete Order'}
            </Button>
          </div>
        </form>
      </ModalContainer>
    </Modal>
  );
};

const RefundModal = ({ visible, onDismiss, data }) => {
  const [modalConfirm, confirmModal] = React.useState(false);
  const [isProcessing, setProcessing] = React.useState(false);

  const refund = async () => {
    setProcessing(true);
    try {
      const d = await orders.refund({ ref: data?.ref });
      if (d?.success) {
        await onDismiss('all');
      }
    } finally {
      setProcessing(false);
    }
  };

  return (
    <Modal
      id={'refundOrder'}
      visible={visible}
      onDismiss={onDismiss}
      ariaLabel={'refund order amount'}
    >
      <ModalContainer status={'success'}>
        <div className="fx-modal--header">
          <h4>Payment Refund</h4>
        </div>
        <div className="fx-modal--description-note">
          <span>
            Refunding this payment will credit the customer and the refund
            amount will be taken from your sales wallet balance. Also understand
            that transaction fees will not be refunded to you.
          </span>
        </div>
        <form style={{ marginTop: '24px' }}>
          <div className="fx-modal__balance-container">
            <div>
              <span>Amount to Refund</span>
              <span>{formatAmount(data?.catalog?.amount / 100)}</span>
            </div>
          </div>
          <div className="fx-modal__balance-container">
            <div>
              <span>Sender Name</span>
              <span>{data?.buyer?.name}</span>
            </div>
          </div>
          <div style={{ marginTop: '24px' }}>
            <Button
              primary
              wide
              $loading={isProcessing}
              disabled={isProcessing}
              onClick={async event => {
                event.preventDefault();

                if (modalConfirm) {
                  await refund();
                } else {
                  confirmModal(true);
                }
              }}
            >
              {modalConfirm
                ? 'Yes, I want to refund this customer'
                : 'Process Refund'}
            </Button>
          </div>
        </form>
      </ModalContainer>
    </Modal>
  );
};

const TransactionDetails = ({
  visible,
  onDismiss,
  data,
  onRefund,
  onConfirm,
}) => {
  return (
    <Modal
      id={'recentTransactions'}
      visible={visible}
      onDismiss={onDismiss}
      ariaLabel={'recent transactions modal'}
      customStyle={{
        maxWidth: '350px',
      }}
    >
      <ModalContainer status={data.status}>
        <div className="fx-modal--header">
          <h4>Ticket Order Details</h4>
          <span>CREDIT</span>
        </div>
        <div className="fx-modal--description">
          <span>Extra Information</span>
          <span>{data?.ticket?.name}</span>
        </div>
        <div className="fx-modal--info-container">
          <div className="row">
            <span>Amount</span>
            <span>{formatAmount(data?.ticket?.amount / 100)}</span>
          </div>
          <div className="row">
            <span>Quantity</span>
            <span>{data?.quantity}</span>
          </div>
          <div className="row">
            <span>Date</span>
            <span>{new Date(data?.created_at).toLocaleString()}</span>
          </div>
          <div className="row">
            <span>Reference No.</span>
            <span>{data?.ref}</span>
          </div>
        </div>
        <div className="fx-modal__balance-container">
          <div>
            <span>Order Status</span>
            <span>{<Tag type={'success'}>Fulfilled</Tag>}</span>
          </div>
        </div>
      </ModalContainer>
    </Modal>
  );
};

const Filter = ({
  visible,
  onDismiss,
  data,
  disabled,
  onFilter,
  onProcessing,
}) => {
  const [type, setType] = React.useState('');
  const [payload, setPayload] = React.useState('');

  const filterByDeliveryInfo = async load => {
    await onProcessing(true);
    try {
      const data = await delivery.filter({
        delivery_info: load,
      });

      if (data?.success) {
        await onDismiss();
        await onFilter([...data?.response]);
      }
    } finally {
      await onProcessing(false);
    }
  };

  const filterByDate = async load => {
    await onProcessing(true);
    try {
      let filterDate;
      let date = new Date();

      switch (load) {
        case 'last week':
          date = formatDate(new Date(date.setDate(date.getDate() - 7)));
          filterDate = date;
          break;
        case 'last month':
          date = formatDate(new Date(date.setMonth(date.getMonth() - 1)));
          filterDate = date;
          break;
        default:
          date = formatDate(new Date(date.setMonth(date.getMonth() - 3)));
          filterDate = date;
          break;
      }

      const data = await orders.byDate({ date: filterDate });

      if (data?.success) {
        await onDismiss();
        await onFilter([...data?.response]);
      }
    } finally {
      await onProcessing(false);
    }
  };

  return (
    <Modal
      id={'ordersFilter'}
      visible={visible}
      onDismiss={onDismiss}
      title={'Filter'}
    >
      <FilterContainer>
        <div>
          <h4>By delivery location</h4>
          {data.locations.map(location => (
            <Option
              active={type === 'location' && payload === location.id}
              disabled={disabled}
              key={location.id}
              onClick={async () => {
                setType(() => 'location');
                setPayload(() => location.id);

                await filterByDeliveryInfo(location.id);
              }}
            >
              {location.area}
            </Option>
          ))}
        </div>
        <Horizon />
        <div>
          <h4>By date</h4>
          <Option
            active={type === 'date' && payload === 'last week'}
            onClick={async () => {
              setType(() => 'date');
              setPayload(() => 'last week');

              await filterByDate('last week');
            }}
          >
            Last 1 Week
          </Option>
          <Option
            active={type === 'date' && payload === 'last month'}
            onClick={async () => {
              setType('date');
              setPayload('last month');

              await filterByDate('last month');
            }}
          >
            Last 1 Month
          </Option>
          <Option
            active={type === 'date' && payload === 'last 3 months'}
            onClick={async () => {
              setType('date');
              setPayload('last 3 months');

              await filterByDate('last 3 months');
            }}
          >
            Last 3 Month
          </Option>
        </div>
        <div className={'fx-filter--reset'}>
          <div
            role={'button'}
            onClick={() => {
              setType('');
              setPayload('');
            }}
          >
            Reset Filter
          </div>
        </div>
      </FilterContainer>
    </Modal>
  );
};

export default Orders;
