import { errorHandler, server } from '../../lib';
import toast from 'react-hot-toast';

const getCatalogs = async ({ id }) => {
  try {
    const { status, data: response } = await server.get(
      `/tickets/list/?business=${id}`,
    );

    if (status === 200) {
      return {
        success: true,
        response: response.results,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const fetchCatalog = async ({ id }) => {
  try {
    const { status, data: response } = await server.get(`/catalogs/v1/${id}/`);

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const deleteCatalog = async ({ ref }) => {
  try {
    const { status } = await server.delete(
      `/catalogs/v1/${ref}/`,
    );

    if (status === 204) {
      toast.success('Catalog deleted successfully');

      return {
        success: true,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const updateCatalog = async ({ ref, ...data }) => {
  try {
    const { status, data: response } = await server.put(`/catalogs/v1/${ref}/`, {
      ...data,
    });

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const createCatalog = async data => {
  try {
    const { status, data: response } = await server.post('/tickets/', {
      ...data,
    });

    if (status === 201) {
      toast.success('Awesome!!! Successfully added catalog to store');

      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const fluxStore = {
  getCatalogs,
  fetchCatalog,
  updateCatalog,
  createCatalog,
  deleteCatalog,
};

export default fluxStore;
