import styled from 'styled-components';
import { setMaxWidth } from '../../helpers';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.primary};
  background: ${({ theme }) => theme.colors.gradient};
  padding: 8px 12px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &::before {
    content: '';
    display: block;
    min-height: 100vh;
    height: 100%;
    width: 100vw;
    background: url('https://flux-frontend-s3.s3-us-west-2.amazonaws.com/red-dawn/Flux+Pattern.svg')
      no-repeat center;
    position: absolute;
    background-size: contain;
    top: 0;
    left: 0;
  }

  main {
    ${setMaxWidth(420)};
    background-color: ${({ theme }) => theme.colors.base};
    border-radius: 8px;
    box-shadow: 0 0 42px #00000029;
    padding: 20px 12px;
    position: relative;

    div.fx-auth-container__image-container {
      display: flex;
      justify-content: center;
      height: 28px;
      position: relative;

      div.chevron-container {
        padding: 4px 8px;
        height: 24px;
        border-radius: 4px;
        background-color: ${({ theme }) => theme.colors['brand-light']};
        position: absolute;
        left: 0;

        * {
          color: ${({ theme }) => theme.colors.primary};
        }
      }

      img {
        height: 80px;
        width: 80px;
        border: 10px solid white;
        border-radius: 50%;
        position: relative;
        top: -48px;
        background-color: ${({ theme }) => theme.colors.base};
      }
    }

    h4,
    span {
      display: block;
      text-align: center;
    }

    h4 {
      font-size: 1.2rem;
      color: ${({ theme }) => theme.colors['primary-text']};
    }

    span.fx-auth-header--details {
      font-size: 1rem;
      font-weight: 300;
      color: ${({ theme }) => theme.colors['primary-text']};

      &:after {
        ${setMaxWidth(80)};
        content: '';
        display: block;
        border: 1px solid #e1e1e1;
        margin-top: 16px;
      }
    }

    form {
      padding: 16px 24px;

      label {
        font-size: 0.8rem;
        color: ${({ theme }) => theme.colors['primary-text']};
      }

      button {
        margin-top: 24px;
      }

      a {
        display: block;
        text-align: center;
        margin-top: 16px;
        color: ${({ theme }) => theme.colors.primary};
        font-size: 0.9rem;
      }
    }
  }

  div.fx-footer--text {
    position: absolute;
    bottom: -52px;
    display: block;
    width: 100%;

    p,
    a {
      color: ${({ theme }) => theme.colors.base};
      font-size: 0.9rem;
      text-align: center;
    }

    a {
      text-decoration: underline;
    }
  }
`;

export default Container;
