import * as React from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container, { TabList } from './styles';
import { Button, DropD, Horizon, TextInput } from '../../../../armoury';
import { FileUpload } from '../../../../components/Dashboard';
import { business } from '../../../../api';
import { businessCategories } from '../../../../config';
import {
  objectWithoutKeyword,
  slugify,
  formValidator,
} from '../../../../helpers';

const Compliance = () => {
  const { colors } = useTheme();
  const history = useHistory();

  const user = useSelector(({ user }) => user);

  const businessID = React.useMemo(() => slugify(user.business?.name), [user]);

  const [step, setStep] = React.useState(1);

  const [isProcessing, setProcessing] = React.useState(false);

  const [documents, setDocuments] = React.useState([]);

  const [businessDets, setBusinessDets] = React.useState({
    address: '',
    addressError: false,
    category: '',
    categoryError: false,
    description: '',
    descriptionError: false,
    name: '',
    nameError: false,
    website: '',
    websiteError: false,
    rc_number: '',
    rcNumberError: false,
    tin: '',
    tinError: false,
    phone: '',
    phoneError: false,
  });

  React.useEffect(() => {
    if (step === 1) {
      (async () => await fetchBusinessDetails())();
    } else {
      (async () => await getDocuments())();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const disabled = React.useMemo(
    () =>
      !businessDets.address ||
      businessDets.addressError ||
      !businessDets.category ||
      businessDets.categoryError ||
      !businessDets.description ||
      businessDets.descriptionError ||
      !businessDets.name ||
      businessDets.nameError,
    [
      businessDets.address,
      businessDets.addressError,
      businessDets.category,
      businessDets.categoryError,
      businessDets.description,
      businessDets.descriptionError,
      businessDets.name,
      businessDets.nameError,
    ],
  );

  const fetchBusinessDetails = React.useCallback(async () => {
    setProcessing(true);
    try {
      const data = await business.get({ id: businessID });

      if (data?.success) {
        setBusinessDets({
          ...businessDets,
          ...data.response,
        });
      }
    } finally {
      setProcessing(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.business?.id]);

  const getDocuments = React.useCallback(async () => {
    setProcessing(true);
    try {
      const data = await business.getDocuments();

      if (data?.success) {
        setDocuments([...data.response]);
      }
    } finally {
      setProcessing(false);
    }
  }, []);

  const updateDocuments = async ({ name, location }) => {
    setProcessing(true);
    try {
      const data = await business.updateDocuments({
        doc_type: name,
        doc: location,
      });

      if (data?.success) {
        await getDocuments();
      }
    } finally {
      setProcessing(false);
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
    if (
      formValidator([
        ...document.forms['compliance--form'].getElementsByTagName('input'),
        ...document.forms['compliance--form'].getElementsByTagName('textarea'),
      ])
    ) {
      setProcessing(true);

      try {
        let body = { ...objectWithoutKeyword(businessDets, 'error') };

        if (!businessDets.tin) {
          body = {
            ...body,
            tin: null,
          };
        }

        if (!businessDets.rc_number) {
          body = {
            ...body,
            rc_number: null,
          };
        }

        const data = await business.update({
          id: businessID,
          body,
        });

        if (data?.success) {
          setStep(2);
        }
      } finally {
        setProcessing(false);
      }
    }
  };

  return (
    <>
      <Container>
        <div className="fx--header-container">
          <div>
            <h3 className="fx-title">Compliance</h3>
            <div role={'button'}>
              <FontAwesomeIcon icon={'cog'} color={colors.primary} />
            </div>
          </div>
          <Horizon />
          <section>
            <div className="form-container">
              <TabList role={'tablist'} aria-label={'compliance form steps'}>
                <div
                  role={'tab'}
                  aria-selected={step === 1}
                  aria-label={'step1'}
                  onClick={() => setStep(1)}
                >
                  <span>1</span>
                </div>
                <div />
                <div
                  role={'tab'}
                  aria-selected={step === 2}
                  aria-label={'step1'}
                  onClick={() => setStep(2)}
                >
                  <span>2</span>
                </div>
              </TabList>
              {step === 1 && (
                <form
                  noValidate
                  name="compliance--form"
                  onSubmit={handleSubmit}
                >
                  <TextInput
                    placeholder={'e.g Trump and Sons enterprises'}
                    label={'Business Name'}
                    name={'Business Name'}
                    value={businessDets.name}
                    error={businessDets.nameError}
                    onChange={({ target: { value } }) => {
                      setBusinessDets({
                        ...businessDets,
                        name: value,
                        nameError: !value,
                      });
                    }}
                    required
                  />
                  <TextInput
                    placeholder={'We sell shoes'}
                    name={'Business Description'}
                    label={'Business Description'}
                    value={businessDets.description}
                    error={businessDets.descriptionError}
                    onChange={({ target: { value } }) => {
                      setBusinessDets({
                        ...businessDets,
                        description: value,
                        descriptionError: !value,
                      });
                    }}
                    textArea
                    required
                  />
                  <DropD
                    label={'Business Category'}
                    placeholder={'Select Business Category'}
                    onChange={({ target: { value } }) => {
                      setBusinessDets({
                        ...businessDets,
                        category: value,
                        categoryError: !value,
                      });
                    }}
                    error={businessDets.categoryError}
                    options={[...businessCategories]}
                    required
                  />
                  <TextInput
                    placeholder={'RC Number'}
                    label={'RC Number (optional)'}
                    value={businessDets.rc_number}
                    onChange={({ target: { value } }) => {
                      setBusinessDets({
                        ...businessDets,
                        rc_number: value,
                        rcNumberError: !value,
                      });
                    }}
                  />
                  <TextInput
                    placeholder={'TIN'}
                    label={'Tax Identification Number (optional)'}
                    value={businessDets.tin}
                    onChange={({ target: { value } }) => {
                      setBusinessDets({
                        ...businessDets,
                        tin: value,
                        tinError: !value,
                      });
                    }}
                  />
                  <TextInput
                    placeholder={'09012356422'}
                    label={'Business phone number '}
                    value={businessDets.phone}
                    error={businessDets.phoneError}
                    onChange={({ target: { value } }) => {
                      setBusinessDets({
                        ...businessDets,
                        phone: value,
                        phoneError: !value || value.length > 11,
                      });
                    }}
                  />
                  <TextInput
                    placeholder={'Ajanlekoko street, iyana ipaja, Lagos'}
                    label={'Address'}
                    name={'Address'}
                    value={businessDets.address}
                    error={businessDets.addressError}
                    onChange={({ target: { value } }) => {
                      setBusinessDets({
                        ...businessDets,
                        address: value,
                        addressError: !value,
                      });
                    }}
                    required
                  />
                  <TextInput
                    placeholder={'https://iflux.store'}
                    label={'Website'}
                    type={'url'}
                    value={businessDets.website}
                    error={businessDets.websiteError}
                    onChange={({ target: { value } }) => {
                      setBusinessDets({
                        ...businessDets,
                        website: value,
                      });
                    }}
                  />
                  <div className={'action-container'}>
                    <Button
                      primary
                      $loading={isProcessing}
                      disabled={isProcessing}
                    >
                      Continue
                    </Button>
                  </div>
                </form>
              )}
              {step === 2 && (
                <>
                  <div className="file-upload__container">
                    {!!documents.length ? (
                      documents.map(document => (
                        <FileUpload
                          key={document.doc}
                          uploaded={true}
                          name={document.doc_type}
                        />
                      ))
                    ) : (
                      <FileUpload
                        name={'CAC'}
                        onComplete={async data =>
                          await updateDocuments({ ...data })
                        }
                      />
                    )}
                  </div>
                  <div
                    className={'action-container'}
                    style={{ marginTop: '1rem' }}
                  >
                    <Button
                      primary
                      $loading={isProcessing}
                      disabled={isProcessing || disabled}
                      onClick={() => history.push('/event')}
                    >
                      Go to store
                    </Button>
                  </div>
                </>
              )}
            </div>
          </section>
        </div>
      </Container>
    </>
  );
};

export default Compliance;
