import handleError from '../../lib/errorHandler';
import { server } from '../../lib';

const events = {
  async fetch() {
    try {
      const { status, data: response } = await server.get('/tickets/events/');

      if (status === 200) {
        return {
          success: true,
          response,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  },
  async create(body) {
    try {
      const { status, data: response } = await server.post('/tickets/events/', {
        ...body,
      });

      if (status === 201) {
        return {
          success: true,
          response,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  },
  async update(id, { body }) {
    try {
      const { status, data: response } = await server.put(`/tickets/events/${id}/`, { body });

      if (status === 200) {
        return {
          success: true,
          response,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  },
  async get({ id }) {
    try {
      const { status, data: response } = await server.get(`/tickets/events/${id}/`);

      if (status === 200) {
        return {
          success: true,
          response,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  },
  async delete(id) {
    try {
      const { status, data: response } = await server.delete(`/tickets/events/${id}`);

      if (status === 204) {
        return {
          success: true,
          response,
        };
      }
    } catch ({ response }) {
      handleError(response);
    }
  },
};

export default events;
