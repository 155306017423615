import { default as styled } from 'styled-components';

const Container = styled.div`
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 9999;

  transition: visibility 200ms, opacity 200ms, background 200ms,
    backdrop-filter 200ms, -webkit-backdrop-filter 200ms;

  div#fxModalContainer {
    z-index: 2000;

    .fx-modal-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2020;
      padding: 1rem;
      background-color: rgba(72, 61, 169, 0.3);

      @supports (backdrop-filter: blur(1px)) or
        (-webkit-backdrop-filter: blur(1px)) {
        background-color: rgba(255, 255, 255, 0.5);
        backdrop-filter: blur(4px);
        -webkit-backdrop-filter: blur(4px);
      }
    }

    div.fx-modal {
      padding: 25px;
      border-radius: 12px;
      z-index: 99999999999;
      background-color: white;
      width: 90%;
      max-width: 400px;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 30px #00000029;
      overflow-y: auto;

      ~ {
        z-index: 2000;
      }
    }

    div.fx-modal--header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > div {
        border-width: 10px;
        border-radius: 50%;
        height: 32px;
        width: 32px;
        background-color: ${({ theme }) => theme.colors['brand-light']};
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          font-size: 18px;
        }
      }
    }
  }
`;

export default Container;
