import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import S3 from 'react-aws-s3';
import { useSelector } from 'react-redux';
import toast from 'react-hot-toast';

import { NProgress } from '../../../../lib';

import Container from './styles';

const FileUpload = ({ name = 'CAC', uploaded = false, onComplete }) => {
  const input = React.useRef(null);
  const user = useSelector(({ user }) => user);
  const { email, pk } = user;

  const handleFileChange = async () => {
    NProgress.start();
    const file = input.current?.files[0];
    const fileName = `${name}-${email}-${pk}`;

    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIR_NAME,
      accessKeyId: process.env.REACT_APP_ACCESS_ID,
      secretAccessKey: process.env.REACT_APP_ACCESS_KEY,
      region: process.env.REACT_APP_AWS_REGION,
    };

    const s3Flux = new S3(config);

    try {
      const s3response = await s3Flux.uploadFile(file, fileName);

      if (s3response?.status === 204) {
        toast.success(
          `Your ${name} document has been submitted to verification`,
        );

        await onComplete({
          name: name.toUpperCase().split(' ').join('-'),
          location: s3response?.location,
        });
      }
    } catch ({ response }) {
      //
    } finally {
      NProgress.done();
    }
  };

  const handleFileSelection = () => input.current?.click();

  return (
    <Container aria-label={`upload ${name} document`} uploaded={uploaded}>
      <input
        type="file"
        ref={input}
        accept={'application/octet-stream, application/pdf, image/jpeg, image/png'}
        className={'fx-file__input'}
        onChange={handleFileChange}
      />
      <div>
        <FontAwesomeIcon icon={'check-circle'} />
        <span>{name}</span>
      </div>
      <span onClick={() => handleFileSelection()}>
        {uploaded ? 'Replace' : 'Upload'}
      </span>
    </Container>
  );
};

export default FileUpload;
