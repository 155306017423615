import * as React from 'react';
import { useHistory } from 'react-router-dom';

import Container from './styles';
import { account } from '../../../../api';
import {
  BreadCrumb,
  Horizon,
  Modal,
  Button,
  Spinner,
} from '../../../../armoury';

const AddCash = () => {
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const [isFetching, setIsFetching] = React.useState(true);
  const [accountDetails, setAccountDetails] = React.useState({
    account_no: '',
    bank_name: '',
    account_name: '',
  });

  React.useEffect(() => {
    (async () => {
      setIsFetching(true);
      try {
        const data = await account.fetchBankAccount();

        if (data?.success) {
          setAccountDetails(() => data.response);
        }
      } finally {
        setIsFetching(false);
      }
    })();
  }, []);

  React.useEffect(() => {
    if (!isModalOpen) {
      setIsModalOpen(true);
    }
  }, [isModalOpen]);

  return (
    <Container>
      <div>
        <h3>Add Cash</h3>
        <BreadCrumb paths={['Flux Wallets', 'Add Cash']} />
        <Horizon />
      </div>
      <Modal
        id={'accountNumber'}
        title={'Payment Account'}
        visible={isModalOpen}
        ariaLabel={'add cash via bank transfer'}
        onDismiss={() => history.goBack()}
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <div className={'fx-account-number-box'}>
          {isFetching ? (
            <Spinner />
          ) : (
            <div>
              <span>Please pay into this bank account</span>
              <p
                aria-label={
                  'account number to pay into to fund your Flux wallet'
                }
              >
                {accountDetails.account_no}
              </p>
              <p aria-label={'bank you have to pay into'}>
                {accountDetails.bank_name}
              </p>
              <p
                aria-label={
                  'account name to the account number to pay into to fund your Flux wallet'
                }
              >
                {accountDetails.account_name}
              </p>
            </div>
          )}
        </div>
        <div className={'fx-modal-footer'}>
          <Horizon />
          <Button primary wide onClick={() => history.push('/')}>
            I've paid
          </Button>
        </div>
      </Modal>
    </Container>
  );
};

export default AddCash;
