import * as React from 'react';

import Container from './styles';
import { Horizon } from '../../../../../armoury';

import Buy from './Buy';
import Sell from './Sell';
import { capitalize } from '../../../../../helpers';

const Card = ({ name }) => {
  const [showBuy, setBuy] = React.useState(false);
  const [showSell, setSell] = React.useState(false);

  return (
    <>
      <Container>
        <div className="row-header">
          <div>
            <span>{capitalize(name)}</span>
            <span>234.56BTC</span>
          </div>
        </div>
        <Horizon color={'rgba(225, 225, 225, 0.5)'} />
        <div className="card-content">
          <div
            className="row"
            role="button"
            aria-label={'buy bitcoin'}
            onClick={() => setBuy(true)}
          >
            <span>Buy</span>
            <span>@₦4,354,955.23/BTC</span>
          </div>
          <Horizon color={'rgba(225, 225, 225, 0.5)'} />
          <div
            className="row"
            role={'button'}
            aria-label={'sell bitcoin'}
            onClick={() => setSell(true)}
          >
            <span>Sell</span>
            <span>@₦4,354,955.23/BTC</span>
          </div>
        </div>
      </Container>
      <Buy visible={showBuy} onDismiss={() => setBuy(false)} />
      <Sell visible={showSell} onDismiss={() => setSell(false)} />
    </>
  );
};

export default Card;
