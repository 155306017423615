import { default as styled } from 'styled-components';

const Container = styled.div`
  cursor: pointer;
  padding: ${({ type }) => (type !== 'simple' ? '1rem 1.2rem' : '0.1rem')};
  background-color: ${({ theme, active, type }) =>
    type !== 'simple'
      ? active
        ? theme.colors['brand-light']
        : theme.colors.base
      : 'transparent'};
  border-radius: 4px;
  border: 1px solid
    ${({ theme, type }) =>
      type !== 'simple' ? theme.colors.primary : 'transparent'};
  display: flex;
  align-items: center;
  transition: 200ms ease-in-out background-color;

  &:focus {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
  }

  div.fx-radio {
    border: 1px solid ${({ theme }) => theme.colors.primary};
    height: 18px;
    width: 18px;
    border-radius: 50%;
    padding: 2px;
    position: relative;
  }

  input[type='radio'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    border-radius: 50%;
    width: 10px;
    height: 10px;
    position: absolute;
    top: 3px;
    left: 3px;

    transition: 200ms all linear;
    margin-right: 5px;

    padding: 2px;

    &:checked {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }

  span {
    margin-left: 12px;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

export default Container;
