import styled, { css } from 'styled-components';

const Container = styled.main`
  padding: 16px;
  min-height: 100vh;

  div.fx--empty-card_container {
    padding: 12px 24px;
    background-color: ${({ theme }) => theme.colors.base};
    border-radius: 10px;
    box-shadow: 0 0 2px ${({ theme }) => theme.colors.shadow};

    h3.fx-title {
      font-weight: 700;
    }

    div.fx--orders_empty {
      h2 {
        color: #855aaf;
        font-style: italic;
        font-size: 1.2rem;

        span {
          color: #fc6190;
          font-size: 40px;
        }
      }

      p {
        color: rgba(174, 174, 174, 1);
        margin-bottom: 24px;
      }

      button {
        font-weight: 300;
        display: flex;
        margin-bottom: 12px;

        svg {
          font-size: 0.5rem;
          margin-right: 4px;
          align-self: center;
        }
      }
    }
  }

  div.fx-filter--container {
    display: flex;
    align-self: flex-end;
    justify-content: space-between;
    align-items: center;
    max-width: 300px;
    width: 100%;

    button {
      svg {
        margin-right: 1rem;
      }

      span {
        font-weight: 400;
      }
    }

    div.vr {
      height: 2.5rem;
      width: 1px;
      background-color: #2222221a;
      position: relative;
      top: -0.3rem;
    }

    input {
      position: relative;
      top: -0.5rem;
      margin-left: 0.2rem;
    }
  }

  div.fx--orders__header-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

export const ModalContainer = styled.div`
  div.fx-modal--header {
    position: absolute;
    top: 20px;
    left: 0;
    padding: 0 20px;
    max-width: 250px;
    width: 100%;

    span {
      padding: 4px 8px;
      ${({ status }) =>
        status === 'success' &&
        css`
          background-color: #47c479;
        `}
      ${({ status }) =>
        status === 'pending' &&
        css`
          background-color: orange;
        `}
      ${({ status }) =>
        status === 'failed' &&
        css`
          background-color: #ff5e5e;
        `}
      border-radius: 3px;
      font-size: 0.7rem;
      color: ${({ theme }) => theme.colors.base};
    }
  }

  div.fx-modal--description {
    padding: 12px 12px;
    background-color: #22222208;
    border: 1px dashed #2222221a;
    border-radius: 4px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;

    span {
      display: block;

      &:first-child {
        font-weight: 300;
        font-size: 0.7rem;
      }

      &:nth-child(2) {
        font-weight: 400;
        font-size: 0.8rem;
        margin-top: 4px;
      }
    }
  }

  div.fx-modal--description-note {
    padding: 12px 12px;
    background-color: ${({ theme }) => theme.colors.danger.light};
    border: 1.5px dashed ${({ theme }) => theme.colors.danger.base};
    border-radius: 4px;
    margin-top: 12px;
    display: flex;
    flex-direction: column;

    span {
      display: block;
      font-weight: 400;
      font-size: 0.8rem;
      margin-top: 4px;
      color: ${({ theme }) => theme.colors.danger.base};
    }
  }

  div.fx-modal--info-container {
    margin-top: 16px;

    div.row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1rem;

      span {
        font-size: 0.8rem;
        font-weight: 300;

        &:first-child {
          color: #909090;
        }

        &:nth-child(2) {
          font-weight: 400;
          color: #222222;
        }
      }
    }
  }

  div.fx-modal__balance-container {
    margin-top: 16px;
    padding: 12px 12px;
    background-color: #22222208;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    > div {
      display: flex;
      flex-direction: column;

      &:first-child {
        span {
          display: block;

          &:first-child {
            font-weight: 300;
            font-size: 0.7rem;
          }

          &:nth-child(2) {
            font-weight: 400;
            font-size: 0.8rem;
            margin-top: 4px;
          }
        }
      }

      &:nth-child(2) {
        align-self: center;
        padding: 6px 8px;
        background-color: #ffe2e2;
        border-radius: 3rem;
        color: #ff5e5e;
        font-size: 0.7rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        svg {
          margin-right: 0.4rem;
        }
      }
    }
  }
`;

export const FilterContainer = styled.div`
  h4 {
    font-size: 0.8rem;
    font-weight: 400;
    color: #909090;
  }

  > *:not(:last-child) {
    margin-bottom: 1.2rem;
  }

  div.fx-filter--reset {
    text-align: center;
    margin-top: 2rem;
    color: ${({ theme }) => theme.colors.primary};
    font-size: 0.8rem;
    font-weight: 400;
  }
`;

export default Container;
