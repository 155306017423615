import * as React from 'react';

import { Modal, Button } from '../../../../armoury';
import { card } from '../../../../api';

const Terminate = ({ visible, onDismiss, reference }) => {
  const [isTerminating, setTerminating] = React.useState(false);

  const terminate = async () => {
    setTerminating(true);
    try {
      const data = await card.terminate({ ref: reference });

      if (data?.success) {
        await onDismiss();
      }
    } finally {
      setTerminating(false);
    }
  };

  return (
    <Modal id={'terminateCard'} title={'Terminate card'} visible={visible} onDismiss={onDismiss}>
      <div style={{ textAlign: 'center' }}>
        <p>Are you sure you want to terminate this card?</p>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'row',
        }}
      >
        <div>
          <Button
            primary
            onClick={() => terminate()}
            $loading={isTerminating}
            disabled={isTerminating}
          >
            Terminate
          </Button>
        </div>
        <div>
          <Button secondary onClick={() => onDismiss()}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default Terminate;
