import { css, default as styled } from 'styled-components';
import { query } from '../../helpers';

const Container = styled.div`
  padding: 20px 16px;
  position: relative;
  background: rgb(120, 168, 193);

  height: 160px;
  min-width: 320px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 12px;

  ${query.maxWidth('medium')`
    min-width: 16rem;
    width: 100%;
  `};

  ${({ active, theme }) =>
    active &&
    css`
      box-shadow: 0 5px 12px ${theme.colors.shadow};
    `};

  ${({ type }) =>
    type === 'payment' &&
    css`
      background: ${({ theme }) => theme.colors['payment-card-gradient']};
    `}
  ${({ type }) =>
    type === 'fiat' &&
    css`
      background: ${({ theme }) => theme.colors['fiat-card-gradient']};
    `}
  ${({ type }) =>
    type === 'crypto' &&
    css`
      background: ${({ theme }) => theme.colors['crypto-card-gradient']};
    `}
  span {
    color: ${({ theme }) => theme.colors.base};
  }

  span.fx-id {
    font-weight: 300;
    text-transform: uppercase;
  }

  div.fx-wallet--balance__container {
    margin-top: 14px;
    flex: 0.9;

    span.fx-wallet--balance {
      font-size: 1.5rem;

      &.secure {
        filter: blur(8px);
        background-color: white;
        user-select: none;
      }
    }

    svg {
      font-size: 18px;
      margin-left: 12px;
      user-select: none;
    }
  }

  div.fx-card--sales-options__container {
    position: relative;
    top: -0.6rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    ${query.maxWidth('medium')`
      top: 0.3rem;
    `}

    > div {
      background-color: rgba(255, 255, 255, 0.3);
      color: ${({ theme }) => theme.colors.base};
      padding: 0.2rem;
      border-radius: 3px;
      font-size: 0.7rem;

      &.active {
        background-color: ${({ theme }) => theme.colors.base};
        color: ${({ theme }) => theme.colors.primary};
      }

      margin-bottom: 0.5rem;
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }

  div.fx-card--label__container {
    text-align: right;
    flex: 0.1;

    span {
      font-weight: 300;
      font-size: 0.8rem;
      text-transform: capitalize;
    }
  }

  div.fx-card--marker {
    height: 10px;
    width: 10px;
    background-color: ${({ theme }) => theme.colors.base};
    position: absolute;
    right: 16px;
    top: 16px;
    border-radius: 50%;
  }
`;

export default Container;
