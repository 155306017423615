import { errorHandler, server } from '../../lib';
import toast from 'react-hot-toast';

const get = async () => {
  try {
    const { status, data: response } = await server.get('/tickets/orders/');

    if (status === 200) {
      return {
        success: true,
        response: response.results,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const refund = async ({ ref }) => {
  try {
    const { status, data: response } = await server.post(
      '/transactions/v2/refund/',
      {
        ref,
      },
    );

    if (status === 200) {
      toast.success('Refund successful');

      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const updateOrder = async ({ ref, data }) => {
  try {
    const { status, data: response } = await server.put(
      `/catalogs/orders/${ref}/`,
      {
        ...data,
      },
    );

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const search = async ({ search }) => {
  try {
    const { status, data: response } = await server.get(
      `/catalogs/orders/?catalog_name_icontains=${search}`,
    );

    if (status === 200) {
      return {
        success: true,
        response: response.results,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const byDate = async ({ date }) => {
  try {
    const { status, data: response } = await server.get(
      `/catalogs/orders/?created_at__date=${date}`,
    );

    if (status === 200) {
      return {
        success: true,
        response: response.results,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const getTransactions = async () => {
  try {
    const { status, data: response } = await server.get(
      '/transactions/v2/list/?catalog__isnull=False&flux_pay=True',
    );

    if (status === 200) {
      return {
        success: true,
        response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const get_one = async ({ ref, business_name }) => {
  try {
    const { status, data: response } = await server.get(`/tickets/${ref}/?business=${business_name}`);

    if (status === 200) {
      return {
        success: true, response,
      };
    }
  } catch ({ response }) {
    errorHandler(response);
  }
};

const orders = {
  get,
  refund,
  updateOrder,
  search,
  byDate,
  getTransactions,
  get_one,
};

export default orders;
