import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;

  > div {
    margin-top: -7px;

    &:first-child {
      flex: 0.4;
      border-right: 1.5px solid #00000029;
    }

    &:nth-child(2) {
      flex: 0.6;
      padding: 32px 36px;

      > div {
        overflow-y: auto;
      }
    }
  }
`;

export const FooterContainer = styled.div`
  border-top: 1.5px solid #00000029;
  padding: 24px 0 8px 0;
  display: flex;
  justify-content: space-between;

  span[role='button'] {
    align-items: center;
    color: ${({ theme }) => theme.colors.primary};
    font-weight: 400;
    font-size: 0.9rem;
    margin-top: 12px;
  }

  > div {
    button {
      &:first-child {
        margin-right: 24px;
      }
    }
  }
`;

export const FileUploadContainer = styled.div`
  height: 150px;
  border: 2px dashed ${({ theme }) => theme.colors.primary};
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  > div {
    text-align: center;

    label {
      color: rgba(34, 34, 34, 0.7);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      svg {
        color: ${({ theme }) => theme.colors.primary};
        font-size: 1.4rem;
        margin-bottom: 12px;
      }
    }
  }
`;

export const UploadedImages = styled.div`
  margin-top: 12px;
  display: flex;

  div {
    position: relative;
    height: 60px;
    width: 60px;
    background-color: ${({ theme }) => theme.colors.primary};
    border-radius: 6px;

    > div {
      height: inherit;
      width: inherit;
      border-radius: 6px;
    }

    &:not(:last-child) {
      margin-right: 12px;
    }

    img {
      height: inherit;
      width: inherit;
    }

    svg {
      position: absolute;
      top: -5px;
      right: -8px;
      background-color: ${({ theme }) => theme.colors.base};
      border: 2px solid ${({ theme }) => theme.colors.base};
      border-radius: 50%;
      font-size: 1.2rem;
      cursor: pointer;
    }
  }

  &:not(:last-child) {
    margin-right: 2.5rem;
  }
`;

export default Container;
