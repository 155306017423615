import styled, { css } from 'styled-components';
import { query, setMaxWidth } from '../../../helpers';
import { BREAKPOINTS } from '../../../constants';

const Container = styled.main`
  padding: 16px;
  min-height: 100vh;

  div.empty-delivery--info {
    text-align: center;
  }

  div.fx--header-container {
    padding: 12px 0 0 0;
    background-color: ${({ theme }) => theme.colors.base};

    h2.fx--header {
      color: #855aaf;
      font-style: italic;
      margin: 0;

      span {
        color: #fc6190;
        font-size: 40px;
      }
    }

    > div:first-child {
      padding: 0 24px;
    }

    border-radius: 10px;
    box-shadow: 0 0 2px ${({ theme }) => theme.colors.shadow};

    h3.fx-title {
      font-weight: 700;
    }

    div.fx--header-tablist {
      ul {
        display: flex;
        justify-content: center;

        li {
          height: 3rem;
          min-width: 3rem;
          padding: 1.6rem 0.8rem;

          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          &.active {
            border-bottom: 3px solid ${({ theme }) => theme.colors.primary};
          }
        }
      }
    }
  }

  div.fx--product-list-container {
    padding: 12px 0 32px 0;
    background-color: ${({ theme }) => theme.colors.base};
    border-radius: 10px;
    box-shadow: 0 0 2px ${({ theme }) => theme.colors.shadow};
    margin-top: 16px;
    height: 80vh;

    div.fx--header-store-options {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-top: 24px;
      margin-bottom: 12px;
      padding: 0 36px;

      ${query.maxWidth('medium')`
          padding: 0 8px;
      `}
      button {
        font-weight: 400;
        font-size: 0.9rem;
        margin-bottom: 1rem;

        svg {
          margin-right: 8px;
          font-size: 0.7rem;
        }
      }

      > div:first-child {
        button:first-child {
          margin-right: 20px;
        }
      }
    }

    div.fx--product-list-box {
      height: calc(100% - 64px);
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-template-rows: repeat(40, 280px);
      overflow-y: auto;

      ${({ isProcessing }) =>
              isProcessing &&
              css`
                display: flex;
                justify-content: center;
                align-items: center;
              `};

      &::-webkit-scrollbar {
        width: 2px;
        border-radius: 3px;
      }

      &::-webkit-scrollbar-track {
        background: #ffffff;
      }

      &::-webkit-scrollbar-thumb {
        background: ${({ theme }) => theme.colors.primary};
        border-radius: 3px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: ${({ theme }) => theme.colors.primary};
      }

      ${query.maxWidth('<large2')`
         grid-template-columns: repeat(3, 1fr);
      `}

      ${query.maxWidth('large1')`
         grid-template-columns: repeat(2, 1fr);
      `}

      ${query.maxWidth('>small')`
         grid-template-columns: 1fr;
      `}
    }
  }

  section.delivery-section {
    hr {
      margin-top: 0;
      margin-bottom: 2rem;
    }

    padding: 2rem 0;
  }

  div.delivery-info__section {
    ${setMaxWidth(BREAKPOINTS.small)};
    padding: 24px;
    border-radius: 4px;
    border: 1px solid #e6e6e6;

    div.delivery-info__form {
      margin-bottom: 1rem;

      form {
        > div {
          button {
            margin-right: 1rem;
          }
        }
      }
    }

    div.delivery-info__container {
      button {
        display: flex;

        * {
          align-self: center;
        }

        svg {
          font-size: 0.8rem;
          margin-right: 6px;
        }

        font-size: 0.8rem;
      }
    }
  }

  div.delivery-info {
    margin-bottom: 1rem;

    div.row {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 1rem;

      > span {
        text-transform: capitalize;
        opacity: 0.4;
        flex: 0.4;
      }

      span.delete-button {
        color: ${({ theme }) => theme.colors.danger.base};
        font-size: 0.8rem;
        align-self: center;
      }
    }
  }

  div.fx--header-container-child {
    display: flex;
    justify-content: space-between;
    align-items: center;

    ${query.maxWidth('medium')`
      flex-direction: column;
      align-items: flex-start;
      
      button {
        align-self: center;
        margin-top: 1rem;
      }
    `};

    button {
      display: flex;

      * {
        align-self: center;
      }

      svg {
        font-size: 0.8rem;
        margin-right: 6px;
      }

      font-size: 0.8rem;
    }
  }
`;

export default Container;
