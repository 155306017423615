import styled from 'styled-components';

const Container = styled.div`
  span.fx-breadcrumb-title {
    font-weight: 300;
    font-size: 0.9rem;
    color: ${({ theme }) => theme.colors['primary-text']};
  }

  svg {
    color: ${({ theme }) => theme.colors['primary-text']};
    opacity: 0.6;
    margin: 0 8px;
    font-size: 0.7rem;
  }
`;

export default Container;
