import React from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';

import App from './App';
import reportWebVitals from './reportWebVitals';
import 'nprogress/nprogress.css';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { default as persistConfig } from './store';

const { store, persistor } = persistConfig();

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <React.StrictMode>
        <App />
        <Toaster />
      </React.StrictMode>
    </PersistGate>
  </Provider>,
  document.getElementById('root'),
);

reportWebVitals();
