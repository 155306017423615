import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Container from './styles';
import { formatAmount } from '../../helpers';

import Terminate from '../../views/dashboard/Cards/Terminate';
import Block from '../../views/dashboard/Cards/Block';
import Withdraw from '../../views/dashboard/Cards/Withdraw';

const VCard = ({ dollarCard }) => {
  const {
    // card_type,
    card_style,
    balance,
    expiration,
    // is_active,
    name_on_card,
    masked_pan,
    ref,
  } = dollarCard;
  const [menuOpen, openMenu] = React.useState(false);

  const [showTerminate, setTerminate] = React.useState(false);
  const [showBlock, setBlock] = React.useState(false);
  const [showWithdraw, setWithdraw] = React.useState(false);
  // const [showTopup, setTopup] = React.useState(false);

  // React.useEffect(() => {
  //   const handleOutsideMenuClick = event => {
  //     const nav = document.querySelector('#navMenu');
  //
  //     if (
  //       !(event.target.contains(nav) || event.target.isSameNode(nav)) &&
  //       menuOpen
  //     ) {
  //       openMenu(false);
  //     }
  //   };
  //
  //   window.addEventListener('click', handleOutsideMenuClick);
  //
  //   return () => window.removeEventListener('click', handleOutsideMenuClick);
  // }, [menuOpen]);

  const backgroundImage = () => {
    switch (card_style) {
      case 1:
        return 'https://flux-frontend-s3.s3-us-west-2.amazonaws.com/red-dawn/card-2.svg';
      case 2:
        return 'https://flux-frontend-s3.s3-us-west-2.amazonaws.com/red-dawn/card-3.svg';
      default:
        return 'https://flux-frontend-s3.s3-us-west-2.amazonaws.com/red-dawn/card-1.svg';
    }
  };

  return (
    <Container style={{ backgroundImage: `url(${backgroundImage()})` }}>
      <div className={'icon-section'}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <img
            src="https://flux-frontend-s3.s3-us-west-2.amazonaws.com/red-dawn/icon%402x.png"
            alt="flux icon"
            style={{ height: 30, width: 30 }}
          />
          <img
            src="https://flux-frontend-s3.s3-us-west-2.amazonaws.com/red-dawn/mastercard-logo.png"
            alt="visa card"
            style={{ height: 20, marginLeft: 8, alignSelf: 'center' }}
          />
        </div>
        <FontAwesomeIcon
          icon={'ellipsis-v'}
          style={{ alignSelf: 'center' }}
          onClick={() => openMenu(c => !c)}
          id={'menuIcon'}
        />
      </div>
      <div className={'card-balance--section'}>
        <span>
          {formatAmount(balance / 100, { locales: 'en-US', default: 'USD' })}
        </span>
      </div>
      <div className="card-number--section">
        <span>{masked_pan}</span>
        <div>
          <span>{name_on_card}</span>
          <span>
            {expiration?.split('-')[1]}/{expiration?.split('-')[0].substring(2)}
          </span>
        </div>
      </div>
      {menuOpen && (
        <nav
          aria-label={'available card options'}
          className={'menu'}
          id={'navMenu'}
        >
          <ul role={'menubar'}>
            <li role={'menuitem'}>View Card Details</li>
            <li role={'menuitem'}>Topup Card</li>
            <li role={'menuitem'} onClick={() => setWithdraw(true)}>
              Withdraw
            </li>
            <li role={'menuitem'} onClick={() => setBlock(true)}>
              Block
            </li>
            <li role={'menuitem'} onClick={() => setTerminate(true)}>
              Terminate
            </li>
          </ul>
        </nav>
      )}
      <Terminate
        visible={showTerminate}
        onDismiss={() => setTerminate(false)}
        reference={ref}
      />
      <Block
        onDismiss={() => setBlock(false)}
        visible={showBlock}
        reference={ref}
      />
      <Withdraw
        onDismiss={() => setWithdraw(false)}
        visible={showWithdraw}
        reference={ref}
      />
    </Container>
  );
};

export default VCard;
