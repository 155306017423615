import { default as React } from 'react';
import { NavLink, Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch, useSelector } from 'react-redux';

import Container, { Nav } from './styles';
import {
  AddCash,
  Cards,
  Compliance,
  FluxStore,
  Orders,
  StoreMembers,
  Wallets,
} from '../index';
import { Button, Drawer, Horizon, Modal, TextInput } from '../../armoury';
import { getFirstName } from '../../helpers';
import { transaction } from '../../api';
import { getBasicDetails } from '../../store/actions';
import { CreateBusiness } from '../../components/Dashboard';
import useOutsideClick from '../../hooks/useOutsideClick';
import { AccountHolder } from './Wallets/styles';
import Tickets from './Events/Tickets';

const Dashboard = () => {
  const user = useSelector(({ user }) => user);
  const history = useHistory();
  const dispatch = useDispatch();

  const { business } = user;

  let isBusiness;

  if (!!business) {
    isBusiness = !!Object.keys(business)?.length;
  }

  const aside = React.useRef(null);
  useOutsideClick(aside, () => closeSideMenu());

  const [isDataModalOpen, setIsDataModalOpen] = React.useState(false);
  const [isSendModalOpen, setIsSendModalOpen] = React.useState(false);
  const [isCreateBusiness, setIsCreateBusiness] = React.useState(false);

  const [pic, setPic] = React.useState('');
  const [name, setName] = React.useState('');

  const [isToppingUp] = React.useState(false);

  const [airtimeAmount, setAirtimeAmount] = React.useState('');
  const [airtimeAmountError, setAirtimeAmountError] = React.useState(false);

  const [recipient, setRecipient] = React.useState('self');

  const [phone, setPhone] = React.useState('');
  const [phoneError, setPhoneError] = React.useState(false);

  const [amountToSend, setAmountToSend] = React.useState('');
  const [amountToSendError, setAmountToSendError] = React.useState(false);

  const [sendDescription, setSendDescription] = React.useState('');
  const [sendDescriptionError, setSendDescriptionError] = React.useState(false);

  const [sendReceiverID, setSendReceiverID] = React.useState('');
  const [sendReceiverIDError, setSendReceiverIDError] = React.useState(false);

  const [sendReceiverName, setSendReceiverName] = React.useState('');
  const [receiverPK, setReceiverPK] = React.useState('');

  const [isProcessing, setIsProcessing] = React.useState(false);

  //UI
  const [menuOpen, openMenu] = React.useState(false);

  React.useEffect(() => {
    setReceiverPK('');
    setSendReceiverName('');
  }, [sendReceiverID]);

  React.useEffect(() => {
    return history.listen(() => {
      if (menuOpen) {
        openMenu(false);
      }
    });
  }, [history, menuOpen]);

  const airtimeDisabled = React.useMemo(
    () =>
      isToppingUp ||
      airtimeAmountError ||
      !airtimeAmount ||
      (recipient === 'other' && (!phone || phoneError)),
    [
      airtimeAmount,
      airtimeAmountError,
      isToppingUp,
      phone,
      phoneError,
      recipient,
    ],
  );

  const sendMoneyDisabled = React.useMemo(
    () =>
      !amountToSend ||
      amountToSendError ||
      !sendDescription ||
      sendDescriptionError ||
      !sendReceiverID ||
      sendReceiverIDError ||
      isProcessing,
    [
      amountToSend,
      amountToSendError,
      sendDescription,
      sendDescriptionError,
      sendReceiverID,
      sendReceiverIDError,
      isProcessing,
    ],
  );

  React.useEffect(() => {
    if (!!Object.keys(user).length) {
      setPic(() => user.pic);
      setName(() => getFirstName(user.full_name));
    } else {
      history.push('/sign-in/');
    }
  }, [history, user]);

  const verify = async () => {
    setIsProcessing(true);
    try {
      const data = await transaction.verifyUser({
        id: sendReceiverID,
      });

      if (data?.success) {
        const { full_name, pk } = data.response;

        setSendReceiverName(() => full_name);
        setReceiverPK(() => pk);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const sendMoney = async () => {
    setIsProcessing(true);
    try {
      const data = await transaction.sendMoney({
        receiver: receiverPK,
        amount: amountToSend,
        description: sendDescription,
      });

      if (data?.success) {
        setIsSendModalOpen(false);
        setAmountToSend('');
        setSendReceiverName('');
        setSendReceiverID('');
        setSendDescription('');
        await dispatch(getBasicDetails());
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const openSideMenu = async () => {
    await openMenu(true);
  };

  const closeSideMenu = async () => {
    await openMenu(false);
  };

  return (
    <>
      <Nav aria-labelledby={'mobile-primary-navigation'}>
        <div
          role={'button'}
          id={'mobile-nav'}
          className={menuOpen ? 'open' : ''}
          onClick={() => (menuOpen ? closeSideMenu() : openSideMenu())}
          aria-label={`${menuOpen ? 'close' : 'open'} mobile nav menu`}
        >
          <span />
          <span />
          <span />
        </div>
      </Nav>
      <Container>
        <aside
          className={`${menuOpen ? 'open' : ''}`}
          id={'side-nav'}
          ref={aside}
        >
          <div className={'fx-nav--header'}>
            <img
              src="https://desert-storm.s3-us-west-2.amazonaws.com/assets/flux-logo.png"
              alt="Flux logo"
              title={'Flux'}
              className={'fx-logo'}
            />
            <div className="fx-vr" />
            <img
              src={
                pic ||
                'https://flux-frontend-s3.s3-us-west-2.amazonaws.com/default-user-image.jpg'
              }
              alt={name}
              title={'Flux'}
              className={'fx-logo'}
            />
            {!!name && (
              <span>
                Hi <span>{name}</span>
              </span>
            )}
          </div>
          <nav className={'fx-nav--container'} aria-label={'Main'}>
            <ul>
              <li>
                <NavLink to={'/wallets/'}>
                  <FontAwesomeIcon icon={'wallet'} />
                  Wallet
                </NavLink>
              </li>
              {isBusiness && (
                <>
                  <li>
                    <NavLink to={'/orders/'}>
                      <FontAwesomeIcon icon={'money-check-alt'} />
                      Orders
                    </NavLink>
                  </li>
                </>
              )}
              <li>
                <NavLink to={'/virtual-cards/'}>
                  <FontAwesomeIcon icon={'credit-card'} />
                  Virtual Cards
                </NavLink>
              </li>
              {isBusiness && (
                <li>
                  <NavLink to={'/event/'}>
                    <FontAwesomeIcon icon={'calendar'} />
                    Events
                  </NavLink>
                </li>
              )}
              <Horizon />
              <li className={'fx--send-money'}>
                <div role={'button'} onClick={() => setIsSendModalOpen(true)}>
                  <FontAwesomeIcon icon={'arrow-up'} />
                  Send Money
                </div>
              </li>
              {isBusiness && (
                <>
                  <Horizon />
                  <li>
                    <NavLink to={'/compliance/'}>
                      <FontAwesomeIcon icon={'user-shield'} />
                      Compliance
                    </NavLink>
                  </li>
                </>
              )}
              {!isBusiness && (
                <>
                  <Horizon />
                  <li>
                    <div
                      role={'button'}
                      onClick={() => setIsCreateBusiness(true)}
                    >
                      <FontAwesomeIcon icon={'funnel-dollar'} />
                      Become a merchant
                      <span className={'badge'}>new</span>
                    </div>
                  </li>
                </>
              )}
            </ul>
          </nav>
        </aside>
        <div className={`fx-user-dashboard__content${menuOpen ? ' open' : ''}`}>
          <Switch>
            <Route
              exact
              path={'/'}
              component={() => <Redirect to={'/wallets/'} />}
            />
            <Route exact path={'/wallets'} component={Wallets} />
            <Route path={'/wallets/add-cash'} component={AddCash} />
            <Route path={'/virtual-cards'} component={Cards} />
            <Route exact path={'/event'} component={FluxStore} />
            <Route path={'/event/tickets/:event_id'} component={Tickets} />
            <Route path={'/flux-store/members'} component={StoreMembers} />
            <Route path={'/compliance'} component={Compliance} />
            <Route path={'/orders'} component={Orders} />
          </Switch>
        </div>
        <Modal
          id={'airtimeTopup'}
          onDismiss={() => setIsDataModalOpen(false)}
          visible={isDataModalOpen}
          title={'Airtime Topup'}
          ariaLabel={'airtime topup'}
        >
          <TextInput
            placeholder={'Amount of Airtime'}
            error={airtimeAmountError}
            required
            value={airtimeAmount}
            type={'text'}
            onChange={({ target: { value } }) => {
              setAirtimeAmount(value);
              setAirtimeAmountError(!value || !Number(value));
            }}
          />
          <div className={'fx-topup-modal-container'}>
            <Drawer.Group title={'Who is this for?'}>
              <Drawer.Item
                type={'simple'}
                label={'Myself'}
                active={recipient === 'self'}
                onClick={() => setRecipient('self')}
              />
              <Drawer.Item
                type={'simple'}
                label={'Someone else'}
                active={recipient === 'other'}
                onClick={() => setRecipient('other')}
              />
            </Drawer.Group>
          </div>
          {recipient !== 'self' && (
            <TextInput
              placeholder={"Recipient's Number"}
              error={phoneError}
              required
              type={'tel'}
              value={phone}
              onChange={({ target: { value } }) => {
                setPhone(value);
                setPhoneError(!value);
              }}
            />
          )}
          <Button primary wide disabled={airtimeDisabled}>
            Top Up
          </Button>
        </Modal>

        <Modal
          id={'sendMoney'}
          onDismiss={() => setIsSendModalOpen(false)}
          visible={isSendModalOpen}
          title={'Send Money'}
          ariaLabel={'send money to a flux user'}
        >
          <TextInput
            placeholder={'Amount to Send'}
            error={amountToSendError}
            required
            value={amountToSend}
            type={'text'}
            onChange={({ target: { value } }) => {
              setAmountToSend(value);
              setAmountToSendError(!value || !Number(value));
            }}
          />
          <TextInput
            placeholder={'Flux ID or Flux Tag (e.g @snapdragon)'}
            error={sendReceiverIDError}
            required
            value={sendReceiverID}
            type={'text'}
            onChange={({ target: { value } }) => {
              setSendReceiverID(value);
              setSendReceiverIDError(!value);
            }}
          />
          {/*<TextInput*/}
          {/*  placeholder={"Receiver's name"}*/}
          {/*  value={sendReceiverName}*/}
          {/*  type={'text'}*/}
          {/*  disabled*/}
          {/*/>*/}
          <TextInput
            placeholder={'Description'}
            error={sendDescriptionError}
            required
            value={sendDescription}
            type={'text'}
            onChange={({ target: { value } }) => {
              setSendDescription(value);
              setSendDescriptionError(!value);
            }}
          />
          {!!sendReceiverName && (
            <AccountHolder>
              <div
                aria-label={'account holder name'}
                className={'fx-withdraw--modal_account-holder'}
              >
                <span>{sendReceiverName}</span>
              </div>
            </AccountHolder>
          )}
          <Button
            primary
            wide
            disabled={sendMoneyDisabled}
            onClick={!receiverPK ? verify : sendMoney}
            $loading={isProcessing}
          >
            {!receiverPK ? 'Verify' : 'Send'}
          </Button>
        </Modal>
        <CreateBusiness
          visible={isCreateBusiness}
          onDismiss={() => setIsCreateBusiness(false)}
        />
      </Container>
    </>
  );
};

export default Dashboard;
