export const maxWidth = '1280'
export const borderThickness = '1.8'
export const BREAKPOINTS = {
  small: 420,
  medium: 700,
  large1: 1024,
  large2: 1440,
  xLarge: 1500,
  portrait: 580,
}
