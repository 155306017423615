import * as React from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button,
  Card,
  DropD,
  Modal,
  Table,
  Tag,
  TextInput,
} from '../../../armoury';
import { getBasicDetails } from '../../../store/actions';
import { banks } from '../../../config';
import { bank, user } from '../../../api';
import Crypto from '../../../components/Dashboard/Wallets/Crypto';
import Container, { AccountHolder } from './styles';
import Settings from '../Settings';
import { capitalize, formatAmount } from '../../../helpers';

const Wallets = () => {
  const userData = useSelector(({ user }) => user);
  const history = useHistory();
  const { colors } = useTheme();
  const account = useSelector(({ account }) => account);

  const { business } = userData;

  const isBusinessAdmin = business?.admin;

  const columns = [
    {
      label: 'Recipient',
      key: 'receiver_name',
    },
    {
      label: 'Sender',
      key: 'sender_name',
    },
    {
      label: 'Amount',
      key: 'amount',
      render: amount => formatAmount(amount / 100),
    },
    {
      label: 'Date',
      key: 'created_at',
      render: date => new Date(date).toLocaleDateString(),
    },
    {
      label: 'Transaction Type',
      key: 'action',
      render: action =>
        [...action.split('_')].map(a => capitalize(a)).join(' '),
    },
    {
      label: 'Status',
      key: 'status',
      render: status => <Tag type={status}>{status}</Tag>,
    },
  ];

  const dispatch = useDispatch();
  const { url } = useRouteMatch();

  React.useEffect(() => {
    (async () => {
      await dispatch(getBasicDetails());
      if (isBusinessAdmin) {
        await getBusinessBalance('TODAY');
      }
    })();
  }, [dispatch, isBusinessAdmin]);

  React.useEffect(() => {
    if (!!account) {
      const {
        data: { user, recent_transactions },
      } = account;

      setBalance(() => user?.balance || '');
      setId(() => user?.flux_id);
      setRecentTransactions(() => recent_transactions);
    }
  }, [account]);

  const [balance, setBalance] = React.useState(0);
  const [id, setId] = React.useState('FLUX');
  const [recentTransactions, setRecentTransactions] = React.useState([]);

  const [activeTab, setActiveTab] = React.useState('fiat');
  const [isWithdrawModal, setIsWithdrawModal] = React.useState(false);

  const [amount, setAmount] = React.useState('');
  const [amountError, setAmountError] = React.useState(false);

  const [accountNumber, setAccountNumber] = React.useState('');
  const [accountNumberError, setAccountNumberError] = React.useState(false);

  const [bankCode, setBankCode] = React.useState('');
  const [bankCodeError, setBankCodeError] = React.useState(false);

  const [salesBalance, setSalesBalance] = React.useState('0');

  const [accountHolder, setAccountHolder] = React.useState('');

  const [isProcessing, setIsProcessing] = React.useState(false);

  const [isSettingsOpen, setIsSettingsOpen] = React.useState(false);

  const disabled = React.useMemo(
    () =>
      !amount ||
      amountError ||
      !accountNumber ||
      accountNumberError ||
      isProcessing,
    [accountNumber, accountNumberError, amount, amountError, isProcessing],
  );

  const verifyAccount = async event => {
    event.preventDefault();

    setIsProcessing(true);
    try {
      const data = await bank.verify({
        bank_code: bankCode,
        account_no: accountNumber,
      });

      if (data?.success) {
        setAccountHolder(() => data.response.data.account_name);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const withdraw = async event => {
    event.preventDefault();

    setIsProcessing(true);
    try {
      const data = await bank.transfer({
        amount,
        bank_code: bankCode,
        acct_name: accountHolder,
        acct_no: accountNumber,
      });

      if (data?.success) {
        setIsWithdrawModal(false);
        await dispatch(getBasicDetails());
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const getBusinessBalance = async period => {
    setIsProcessing(true);
    try {
      const data = await user.getSalesBalance({ period });

      if (data?.success) {
        setSalesBalance(data.response?.total_amount);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <>
      <Container style={{ backgroundColor: 'transparent' }}>
        <div className={'fx-user--wallet__details'}>
          <div className={'fx-user--wallet-title__container'}>
            <h2 className={'fx--header'}>
              Wallet<span>.</span>
            </h2>
            <div role={'button'} onClick={() => setIsSettingsOpen(true)}>
              <FontAwesomeIcon icon={'cog'} color={colors.primary} />
            </div>
          </div>
          <div
            aria-label={'wallet cards'}
            className={'fx-wallet--card__container'}
          >
            <Card
              label={'flux wallet'}
              balance={balance}
              cardTitle={id}
              type={'fiat'}
              active={activeTab === 'fiat'}
              onClick={() => setActiveTab('fiat')}
            />
            {isBusinessAdmin && (
              <Card
                label={'payments wallet'}
                cardTitle={'sales'}
                active={activeTab === 'payment'}
                balance={salesBalance}
                type={'payment'}
                onClick={() => setActiveTab('payment')}
                onFilterChange={async period =>
                  await getBusinessBalance(period)
                }
              />
            )}
          </div>
          {activeTab === 'fiat' && (
            <div className={'fx-wallet--action__container'}>
              <Button primary onClick={() => history.push(`${url}add-cash`)}>
                Add Cash
              </Button>
              <Button primary onClick={() => setIsWithdrawModal(true)}>
                Withdraw
              </Button>
            </div>
          )}
          {activeTab === 'payment' && (
            <div
              className={'fx-wallet--action__container'}
              id={'withdrawMoney'}
            >
              <Button primary onClick={() => setIsWithdrawModal(true)}>
                Withdraw
              </Button>
            </div>
          )}
        </div>
        {activeTab === 'fiat' && (
          <div className={'fx-wallet--transaction-history'}>
            <div>
              <h4 className={'fx-title'}>Recent Transactions</h4>
            </div>
            <Table
              columns={columns}
              data={recentTransactions}
              isFetching={isProcessing}
              title={'Recent Transactions'}
            />
          </div>
        )}
        {activeTab === 'payment' && (
          <div className={'fx-wallet--transaction-history'}>
            <div>
              <h4 className={'fx-title'}>Recent Transactions</h4>
            </div>
            <Table
              columns={columns}
              data={recentTransactions}
              isFetching={isProcessing}
              title={'Recent Transactions'}
            />
          </div>
        )}
        {activeTab === 'crypto' && <Crypto />}
      </Container>
      <Modal
        visible={isWithdrawModal}
        ariaLabelledBy={'withdrawMoney'}
        ariaDescribedBy={'#'}
        id={'withdrawModal'}
        onDismiss={() => setIsWithdrawModal(false)}
        title={'Withdraw'}
      >
        <div className={'fx-withdraw--content'}>
          <span>Enter the amount and account you wish to withdraw into.</span>
          <form action="">
            <TextInput
              placeholder={'₦0.00'}
              error={amountError}
              required={true}
              type={'number'}
              onChange={({ target: { value } }) => {
                setAmount(value);
                setAmountError(value < 10);
              }}
              disabled={isProcessing}
            />
            <DropD
              options={banks}
              onChange={({ target: { value } }) => {
                setBankCode(value);
                setBankCodeError(!value);
                setAccountHolder('');
              }}
              error={bankCodeError}
              placeholder={'No bank selected'}
              disabled={isProcessing}
            />
            <TextInput
              placeholder={'Account Number'}
              error={accountNumberError}
              required={true}
              type={'number'}
              onChange={({ target: { value } }) => {
                setAccountNumber(value);
                setAccountNumberError(value.length < 10);
                setAccountHolder('');
              }}
              disabled={isProcessing}
            />
            {!!accountHolder && (
              <AccountHolder>
                <div
                  aria-label={'account holder name'}
                  className={'fx-withdraw--modal_account-holder'}
                >
                  <span>{accountHolder}</span>
                </div>
              </AccountHolder>
            )}
            <Button
              primary
              wide
              disabled={disabled}
              $loading={isProcessing}
              onClick={!!accountHolder ? withdraw : verifyAccount}
            >
              {!!accountHolder ? 'Withdraw' : 'Verify'}
            </Button>
          </form>
        </div>
      </Modal>
      <Settings
        visible={isSettingsOpen}
        onDismiss={() => setIsSettingsOpen(false)}
      />
    </>
  );
};

export default Wallets;
